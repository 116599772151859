define("extraapps/components/ds-viewer-map/component", ["exports", "extraapps/util/util"], function (exports, _util) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        opacity: 0.2,
        gc: Ember.inject.service(),
        saveTo: { id: 0, text: "neuen Datensatz anlegen" }, // 0 - save as newRecord - else Add to Record with ID x
        test: [0, 0],
        shape: "MultiPoint",
        background: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
        nodes: [], // collects nodes of new objects
        sidebar: {
            open: false,
            didOpen: false
        },
        contextItems: [],
        contextDetails: {
            this: undefined
        },
        search: {},
        importedObjects: [],
        currentRecord: null,
        newShape: Ember.computed('nodes.@each.coordinates', 'newNode', function () {
            var nodes = this.get("nodes");
            var newNode = this.get("newNode");
            var s = this.get("shape");
            var shape = null;
            if (nodes.length !== 0) {
                if (nodes.length === 1 && s === "MultiPolygon") {
                    s = "LineString";
                }
                shape = {
                    type: s,
                    coordinates: nodes.map(function (node) {
                        return [node.coordinates[1], node.coordinates[0]];
                    })
                };
                if (newNode !== undefined) {
                    shape.coordinates.addObject(newNode.coordinates);
                }
                if (s === "MultiPolygon") {
                    shape.coordinates = [[shape.coordinates]];
                }
                return shape;
            } else {
                return null;
            }
        }),
        newShapeChanged: function () {

            var shape = this.get("newShape");
            if (shape !== null && shape !== undefined) {
                if (shape.coordinates.length > 1) {
                    this.set("showNewShape", true);
                } else if (Array.isArray(shape.coordinates)) {
                    if (shape.coordinates[0].length > 2) {
                        this.set("showNewShape", true);
                    }
                } else {
                    this.set("showNewShape", false);
                }
            }
        }.observes("newShape.coordiantes").on("init"),

        currentLayerChanged: function () {
            var hiddenRecords = [];
            var currentRecord = this.get("currentRecord");
            hiddenRecords.addObject({ id: 0, text: "neuen Datensatz anlegen" });
            var activeLayer = this.get("activeLayer");
            if (activeLayer !== undefined && activeLayer !== null) {
                activeLayer.records.forEach(function (record) {
                    if (record.hideOnMap) {
                        record.text = "Einf\xFCgen in ID: " + record.id;
                        hiddenRecords.addObject(record);
                    }
                });
            }
        }.observes("activeLayer").on("init"),
        saveToChanged: function () {
            var saveTo = this.get("saveTo");
            if (saveTo.id !== 0) {
                var fields = this.currentRecord.layer.meta.fields;
                var data = this.currentRecord.data;
                var geoPath = fields.findBy("logical_type", "GEOMETRY").path;

                saveTo.geoJSON = data.geoJSON;
                saveTo.geoJSONInversed = data.geoJSONInversed;
                saveTo[geoPath] = JSON.stringify(data.geoJSON);
                Ember.set(this.get("currentRecord"), "data", saveTo);
            }
        }.observes("saveTo").on("init"),
        init: function init() {
            this._super();
            this.getBounds();
            var context = this.get("contextDetails");
            Ember.set(context, "this", this);
        },

        getBounds: function getBounds(objs) {
            if (objs === undefined) {
                objs = [{ type: "Polygon", coordinates: [[[4.042, 45.828], [4.042, 55.478], [15.823, 55.478], [15.820, 45.828]]] }];
            } else if (objs.length === 0) {
                objs = [{ type: "Polygon", coordinates: [[[4.042, 45.828], [4.042, 55.478], [15.823, 55.478], [15.820, 45.828]]] }];
            }
            var bbox = { "geometries": objs, "type": "GeometryCollection" };
            bbox = L.geoJson(bbox).getBounds();
            this.set("bounds", bbox);
        },
        loadingChanged: function () {
            var loading = this.get("loading");
            if (loading) {
                this.set("spinner", true);
            } else {
                this.set("spinner", false);
            }
        }.observes("loading").on("init"),
        layersChanged: function () {
            var that = this;
            var layers = JSON.parse(JSON.stringify(this.get("dl")));
            var geoData = [];
            if (layers.length > 0) {
                for (var a = 0; a < layers.length; a++) {
                    var layer = layers[a];
                    if (layer.meta.withGeoData) {
                        var records = layers[a].records;

                        for (var b = 0; b < records.length; b++) {
                            var r = records[b];
                            if (r.geoJSON === undefined) {
                                Ember.set(r, "hideOnMap", true);
                            } else {
                                if (r.geoJSON.type === "Point") {
                                    Ember.set(r, "isPoint", true);
                                    var geodata = JSON.parse(JSON.stringify(r.geoJSON));
                                    geoData.addObject(geodata);
                                    r.geoJSON.coordinates = [r.geoJSON.coordinates[1], r.geoJSON.coordinates[0]];
                                } else {
                                    geoData.addObject(r.geoJSON);
                                }
                            }
                        }
                    }
                }
            } else {
                geoData = [{ type: "Polygon", coordinates: [[[4.042, 45.828], [4.042, 55.478], [15.823, 55.478], [15.820, 45.828]]] }];
            }
            this.getBounds(geoData);
            this.set("rerender", true);
            Ember.run.later(function () {
                that.set("rerender", false);
            }, 1000);
        }.observes("dl.length").on("init"),

        closeSidebar: function closeSidebar() {
            var sidebar = this.get("sidebar");
            var currentRecord = this.get("currentRecord");
            if (sidebar.didOpen) {
                Ember.set(sidebar, "open", false);
                Ember.set(sidebar, "didOpen", false);
                if (currentRecord !== undefined) {
                    Ember.set(currentRecord.data, "hideOnMap", false);
                    this.set("currentRecord", null);
                }
            } else {
                Ember.set(sidebar, "didOpen", true);
            }
        },

        dbClick: function dbClick(e) {
            this.set("click", false);
            var draw = this.get("draw");
            var selectHouses = this.get("selectHouses");
            if (draw) {
                var drawMenu = this.get("drawMenu");
                var getHouses = this.get("getHouses");
                if (drawMenu || getHouses) {
                    var that = this;
                    var lat = e.latlng.lat;
                    var lng = e.latlng.lng;
                    var shape = this.get("shape");
                    var nodes = this.get("nodes");
                    if (shape !== "MultiPoint") {
                        this.set("newPoint", false);
                        nodes.addObject({ type: "Point", coordinates: [lat, lng] });
                    }
                    if (drawMenu) {
                        this.importNodes();
                        this.set("drawMenu", false);
                    } else {
                        this.importOSMObjInArea();
                        this.set("getHouses", false);
                        this.set("selectHouses", true);
                    }
                }
                this.set("draw", false);
                this.set("shape", "MultiPoint");
            } else if (selectHouses) {
                this.importObjects();
                this.set("selectHouses", false);
            }
        },

        sClick: function sClick(e) {
            this.set("click", false);
            var draw = this.get("draw"); // check if drawing is enabled
            if (draw) {
                var that = this;
                var lat = e.latlng.lat;
                var lng = e.latlng.lng;
                var shape = this.get("shape");
                var nodes = this.get("nodes");
                if (shape === "MultiPoint") {
                    this.set("nodes", [{ type: "Point", coordinates: [lat, lng] }]);
                    this.set("newPoint", true);
                } else {
                    this.set("newPoint", false);
                    nodes.addObject({ type: "Point", coordinates: [lat, lng] });
                }
            } else {
                this.closeSidebar();
            }
        },
        checkClick: function checkClick(e) {
            var blockMapClick = this.get("blockMapClick");
            if (blockMapClick === true) {
                return;
            }
            var check = this.get("click");
            if (check === true) {
                this.sClick(e);
            }
        },

        importNodes: function importNodes() {
            var newShape = this.get("newShape");
            var activeLayer = JSON.parse(JSON.stringify(this.get("activeLayer")));
            var shape = JSON.parse(JSON.stringify(this.get("shape")));
            var nodes = this.get("nodes");
            var record = this.get("currentRecord");
            if (shape === "MultiPoint") {
                record.geoJSON = { type: "Point", coordinates: [nodes[0].coordinates[1], nodes[0].coordinates[0]] };
                record.geoJSONInversed = { type: "Point", coordinates: nodes[0].coordinates };
                record.isPoint = true;
            } else if (shape === "LineString") {
                if (record !== null) {
                    record = record.data;
                    record.geoJSON.coordinates.addObject(newShape.coordinates);
                } else {
                    record = {};
                    record.geoJSON = { type: "MultiLineString", coordinates: [newShape.coordinates] };
                }
            } else if (shape === "MultiPolygon") {
                if (record !== null) {
                    record = record.data;
                    record.geoJSON.coordinates.addObject(newShape.coordinates[0]);
                } else {
                    record = {};
                    record.geoJSON = { type: "MultiPolygon", coordinates: [newShape.coordinates[0]] };
                }
            }
            var currentRecord = { layer: activeLayer.meta, data: record };
            this.set("showNewShape", false);
            this.set("nodes", []);
            this.set("currentRecord", currentRecord);
            Ember.set(this.get("sidebar"), "open", true);
        },
        importOSMObjInArea: function importOSMObjInArea() {
            var _this = this;

            var newShape = this.get("newShape");
            var activeLayer = JSON.parse(JSON.stringify(this.get("activeLayer")));
            var shape = JSON.parse(JSON.stringify(this.get("shape")));
            var nodes = this.get("nodes");
            var geoJSON = { type: "Polygon", coordinates: newShape.coordinates[0] };
            return this.get("gc").getShapeByPosition(geoJSON).then(function (imported) {
                var importedData = [];
                imported.elements.forEach(function (element) {
                    var record = { geojson: { "type": "Polygon", coordinates: [[]] } };
                    element.geometry.forEach(function (c) {
                        if (c.lon !== undefined && c.lat !== undefined) {
                            record.geojson.coordinates[0].addObject([c.lon, c.lat]);
                            importedData.addObject(record);
                        }
                    });
                });
                _this.set("importedObjects", importedData);
                _this.set("showNewShape", false);
                _this.set("nodes", []);
            }).catch(function (error) {
                console.log(error);
            });
        },

        importObjects: function importObjects() {
            var sidebar = this.get("sidebar");
            var importedObjects = this.get("importedObjects");
            var activeLayer = JSON.parse(JSON.stringify(this.get("activeLayer")));
            var fields = activeLayer.meta.fields;
            var geoPath = fields.findBy("logical_type", "GEOMETRY").path;
            var record = {};
            if (importedObjects.length === 1) {
                if (importedObjects[0].geoJSONInversed !== undefined) {
                    record[geoPath] = importedObjects[0].geoJSON;
                    record.geoJSON = importedObjects[0].geoJSON;
                    record.geoJSONInversed = importedObjects[0].geoJSONInversed;
                    Ember.set(record, "isPoint", true);
                } else if (importedObjects[0].geojson !== undefined) {
                    record[geoPath] = importedObjects[0].geojson;
                    record.geoJSON = importedObjects[0].geojson;
                }
            } else {
                var geometries = [];
                importedObjects.forEach(function (obj) {
                    geometries.addObject(obj.geojson);
                });
                record.geoJSON = { type: "GeometryCollection", geometries: geometries };
                record[geoPath] = record.geoJSON;
            }
            var currentRecord = { layer: activeLayer, data: record };
            this.set("currentRecord", currentRecord);
            this.set("importedObjects", []);
            Ember.set(sidebar, "open", true);
        },
        actions: {
            updateCurrentRecord: function updateCurrentRecord(path, value) {
                var currentRecord = this.get("currentRecord");
                Ember.set(currentRecord.data, path, value);
            },
            closeSidebar: function closeSidebar() {
                this.closeSidebar();
            },
            onMousemove: function onMousemove(e) {
                if (this.get("draw")) {
                    var shape = this.get("shape");
                    if (shape !== "MultiPoint") {
                        var currentPosition = [e.latlng.lng, e.latlng.lat];
                        var newNode = { type: "Point", coordinates: currentPosition };
                        this.set("newNode", newNode);
                    }
                }
            },

            saveRecord: function saveRecord(record) {
                var geoJSON = record.data;
                if (geoJSON.type === "MultiPolygon" && geoJSON.coordinates.length === 1) {
                    geoJSON.type = "Polygon";
                } else if (geoJSON.type === "MultiLineString" && geoJSON.coordinates.length === 1) {
                    geoJSON.tyoe = "LineString";
                }
                var sidebar = this.get("sidebar");
                this.sendAction("insertRecord", record.layer, record.data);
                Ember.set(sidebar, "open", false);
                Ember.set(record.data, "hideOnMap", false);
                this.set("currentRecord", null);
            },
            setShape: function setShape(shape) {
                //recives shape from subcomponent and sets value
                this.set("shape", shape);
            },
            close: function close(menu) {
                // opens Sidemenus
                this.set(menu, false);
            },
            open: function open(menu) {
                // closes Sidemenus
                this.set(menu, true);
            },
            deselect: function deselect(obj) {
                var objs = this.get("importedObjects");
                objs.removeObject(obj);
            },
            import: function _import() {
                this.importObjects();
            },

            selectPlace: function selectPlace(place) {
                if (place !== null) {
                    if (place.geojson.type === "Point") {
                        Ember.set(place, "isPoint", true);
                        Ember.set(place, "geoJSONInversed", { type: "Point", coordinates: [place.geojson.coordinates[1], place.geojson.coordinates[0]] });
                    }
                    this.getBounds([place.geojson]);
                    var importedObjects = this.get("importedObjects");
                    importedObjects.addObject(place);
                } else {
                    this.set("importedObjects", []);
                }
            },
            getBounds: function getBounds(obj) {
                this.getBounds(obj);
            },
            showRecord: function showRecord(layer, record) {
                var _this2 = this;

                this.set("blockMapClick", true);
                Ember.run.later(function () {
                    _this2.set("blockMapClick", false);
                }, 500);
                var sidebar = this.get("sidebar");
                var currentRecord = this.get("currentRecord");
                if (currentRecord !== null) {
                    Ember.set(currentRecord.data, "hideOnMap", false);
                }
                var newRecord = { layer: layer.meta, data: record };
                Ember.set(record, "hideOnMap", true);
                this.set("currentRecord", newRecord);
                Ember.set(sidebar, "open", true);
                Ember.set(sidebar, "didOpen", true);
            },
            mapClick: function mapClick(e) {
                var that = this;
                var click = this.get("click");
                if (click === true) {
                    this.dbClick(e);
                    this.set("click", false);
                } else {
                    this.set("click", true);
                }
                Ember.run.later(function () {
                    that.checkClick(e);
                }, 200);
            },

            centerOn: function centerOn(obj) {
                this.getBounds([obj.geoJSON]);
            },
            deleteRecord: function deleteRecord(record) {

                if (record.data.id !== undefined && record.data.id !== null) {
                    this.set("showDelete", false);
                    this.sendAction("deleteRecord", record.layer.identifier, record.layer.name, record.data);
                } else {}
                this.closeSidebar();
            },
            setLayout: function setLayout(layout) {
                this.sendAction("setLayout", layout);
            }
        }

    });
});