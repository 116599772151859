define("extraapps/services/cr", ["exports", "extraapps/config/environment", "extraapps/util/promiseObject"], function (exports, _environment, _promiseObject) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        ea: Ember.inject.service(),
        ajax: Ember.inject.service(),
        session: Ember.inject.service(),
        createChangeRequest: function createChangeRequest(requestData) {
            var that = this;
            var URL = this.get("ea").getServiceURL('/changerequests/create');
            for (var a = 0; a < requestData.length; a++) {
                var request = requestData[a];
                Ember.set(request, "aid", this.get("session").get("app_id"));
                Ember.set(request, "cid", this.get("session").get("client_id"));
            }
            var resource = { resource: requestData };
            var json = { headers: that.get("ea").getDefaultsHeaders(), data: JSON.stringify(resource) };
            console.log(json);
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, json).then(function (data) {
                    var changes = data.resource;
                    resolve(changes);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, "ea.createChangeRequest()");
        },
        getChangeRequests: function getChangeRequests() {
            var URL = this.get("ea").getServiceURL('/changerequests');
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, {
                    headers: that.get("ea").getDefaultsHeaders()
                }).then(function (data) {
                    var changes = data.resource;
                    resolve(changes);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, "ea.getChangeRequests()");
        },
        acceptChangeRequest: function acceptChangeRequest(id) {
            var URL = this.get("ea").getServiceURL("/changerequests/accept/" + id);
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, {
                    headers: that.get("ea").getDefaultsHeaders()
                }).then(function (data) {
                    data.resource;
                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.acceptChangeRequest()");
        },
        rejectChangeRequest: function rejectChangeRequest(id) {
            var URL = this.get("ea").getServiceURL("/changerequests/reject/" + id);
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, {
                    headers: that.get("ea").getDefaultsHeaders()
                }).then(function (data) {
                    data.resource;
                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "ea.rejectChangeRequest()");
        }
    });
});