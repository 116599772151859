define('extraapps/components/date-feed/component', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        ea: Ember.inject.service(),
        ps: Ember.inject.service(),
        init: function init() {
            this._super();
            var dates = this.get("dates").records;
            var today = (0, _moment.default)().format('YYYYMMDD');
            dates = dates.filter(function (a) {
                return (0, _moment.default)(a.anfang).format('YYYYMMDD') >= today;
            });
            this.set("itmes", dates);
        },

        actions: {
            goTo: function goTo(id) {
                var link = '/atlas/termin/detail?id=' + id;
                this.get("ea").getController("application").transitionToRoute(link);
            }
        }
    });
});