define('extraapps/services/exvalidator', ['exports', 'extraapps/util/util'], function (exports, _util) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        /**
         * @param object input which is to be validated
         * @param testType [required|number|integer|min|max|length|minLength|maxLength|range|string|mail|match|noMatch|equals|custom]
         * @param testArgs comma separated list of args (no spaces!)
         * @param message message to return in case of a validation error
         * @returns {string} Empty if validation was successful, message if not or "No validation", if an invalid testType was passed
         */
        validate: function validate(object, testType, testArgs, message) {
            // Type list according to https://github.com/Dani723/ember-validation
            var result = 'No validation';
            var pattern = void 0;
            switch (testType) {
                case 'required':
                    {
                        if (object !== undefined) {
                            if (object.length > 0) {
                                result = '';
                            } else {
                                result = message;
                            }
                        } else {
                            result = message;
                        }
                        break;
                    }
                case 'number':
                    {
                        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/isNaN
                        if ((0, _util.isNumeric)(object)) {
                            result = '';
                        } else {
                            result = message;
                        }
                        break;
                    }
                case 'integer':
                    {
                        // Possible approach:
                        // http://stackoverflow.com/questions/14636536/how-to-check-if-a-variable-is-an-integer-in-javascript
                        if (object !== undefined) {
                            if (/^\d+$/.test(object)) {
                                result = '';
                            } else {
                                result = message;
                            }
                        } else {
                            result = message;
                        }
                        break;
                    }
                case 'min':
                    {
                        if (object >= parseFloat(testArgs)) {
                            result = '';
                        } else {
                            result = message;
                        }
                        break;
                    }
                case 'max':
                    {
                        if (object <= parseFloat(testArgs)) {
                            result = '';
                        } else {
                            result = message;
                        }
                        break;
                    }
                case 'string':
                    {
                        // http://stackoverflow.com/questions/4059147/check-if-a-variable-is-a-string
                        if (typeof object === 'string') {
                            result = '';
                        } else {
                            result = message;
                        }
                        break;
                    }
                case 'mail':
                    {
                        // Possible approach with regex, see
                        // http://stackoverflow.com/questions/46155/validate-email-address-in-javascript
                        var patternString = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
                        pattern = new RegExp(patternString);
                        if (pattern.test(object)) {
                            result = '';
                        } else {
                            result = message;
                        }
                        break;
                    }
                case 'minLength':
                    {
                        if (object.length >= parseInt(testArgs)) {
                            result = '';
                        } else {
                            result = message;
                        }
                        break;
                    }
                case 'maxLength':
                    {
                        if (object.length <= parseInt(testArgs)) {
                            result = '';
                        } else {
                            result = message;
                        }
                        break;
                    }
                case 'length':
                    {
                        // Quote: length(5, 10) - checks if value has at least 5 characters and at most 10 characters
                        // Is a check type for a length range really necessary?

                        if (object !== undefined) {
                            if (object.length === parseInt(testArgs)) {
                                result = '';
                            } else {
                                result = message;
                            }
                        } else {
                            result = message;
                        }
                        break;
                    }
                case 'equals':
                    {
                        if (object === testArgs) {
                            //@todo check
                            result = '';
                        } else {
                            result = message;
                        }
                        break;
                    }
                case 'match':
                    {
                        pattern = new RegExp(testArgs);
                        if (pattern.test(object)) {
                            result = '';
                        } else {
                            result = message;
                        }
                        break;
                    }
                case 'noMatch':
                    {
                        pattern = new RegExp(testArgs);
                        if (~pattern.test(object)) {
                            result = '';
                        } else {
                            result = message;
                        }
                        break;
                    }
                case 'iban':
                    {
                        /*
                         The IBAN consists of up to 34 alphanumeric characters, as follows:
                          country code using ISO 3166-1 alpha-2 - two letters,
                         check digits - two digits, and
                         Basic Bank Account Number (BBAN) - up to 30 alphanumeric characters that are country-specific.
                          Permitted IBAN characters are the digits 0 to 9 and the 26 upper-case Latin alphabetic characters A to Z.
                         This applies even in countries (e.g., Thailand) where these characters are not used in the national language.
                         */
                        if (object !== undefined) {
                            var trimmed = object.replace(/ /g, '');
                            if ((0, _util.isEmpty)(trimmed)) {
                                return message;
                            }
                            trimmed = trimmed.toUpperCase();
                            if (trimmed.length > 34) {
                                return message;
                            }
                            pattern = new RegExp('[A-Z]{2}[0-9]{2}[A-Z0-9]{11,30}$');
                            if (pattern.test(trimmed)) {
                                result = '';
                            } else {
                                result = message;
                            }
                        } else {
                            result = message;
                        }
                    }
                    break;

                case 'custom':
                    {
                        // How to execute string as function? Possible approach:
                        // http://stackoverflow.com/questions/359788/how-to-execute-a-javascript-function-when-i-have-its-name-as-a-string
                        break;
                    }
            } // switch
            return result;
        }, // validate

        /**
         *
         * @param object
         * @param validationArg
         * @param message
         * @returns {* ture if an error is found}
         */
        validateAll: function validateAll(object, validationArg, message) {
            "use strict";

            var that = this;
            if ((0, _util.isEmpty)(validationArg)) {
                return false;
            }
            var singleValidations = validationArg.split('&');
            var msg = null;
            singleValidations.some(function (singleValidation) {
                var result = '';
                var parts = singleValidation.split(':');
                var args = null;
                if (parts.length > 1) {
                    args = parts[1];
                }
                result = that.validate(object, parts[0], args, message);
                if ((0, _util.isNotEmpty)(result)) {
                    msg = result;
                    return true;
                }
                return false;
            });
            return msg;
        },

        /**
         * Validate all fields of the passed record against validation rules stored in meta data.
         * @param record the record which is to be validated
         * @param fields field meta data as returned from backend service GET /rs/ds/import/{database_name}/{dataset_name} in meta.fields
         *        This methods uses only path, label and validations of fields.
         * @returns [String] Validation errors, if any, or empty array, if none.
         *
         *
         */
        validateRecord: function validateRecord(record, fields) {
            var _this = this;

            var validationErrors = [];

            if (record === undefined || record === null) {
                console.log("Warning: ex-validator.validateRecord received no record.");
                return validationErrors;
            }
            if (!Array.isArray(fields)) {
                console.log("Warning: ex-validator.validateRecord received no fields.");
                return validationErrors;
            }

            fields.forEach(function (field) {
                var fieldErrors = [];
                var validations = field.validations;
                if (Array.isArray(validations)) {
                    var path = field.path;
                    var label = field.label;
                    var value = record[path];

                    validations.forEach(function (validation) {
                        if (!validation.message.startsWith(label)) {
                            validation.message = label + ' ' + validation.message;
                        }
                        var message = _this.validate(value, validation.type, validation.args, validation.message);
                        if ((0, _util.isNotEmpty)(message)) {
                            fieldErrors.addObject(message); // stellt sicher das gleiche Fehlermeldungen nur einmal aufgeführt werden
                        }
                    });
                    fieldErrors.forEach(function (error) {
                        validationErrors.push(error);
                    });
                }
            });

            return validationErrors;
        }
    });
});