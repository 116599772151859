define('extraapps/components/ea-form-previewimage/component', ['exports', 'extraapps/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    notifications: Ember.inject.service('notification-messages'),
    docs: Ember.inject.service(),
    ps: Ember.inject.service(),
    api: Ember.inject.service(),
    gc: Ember.inject.service(),
    ds: Ember.inject.service(),
    ea: Ember.inject.service(),
    baseUrl: _environment.default.backend.baseURL,
    dropzone: null,
    saveFile: function saveFile() {
      var name = this.get("name");
      var dropzone = this.get("dropzone");
      var url = this.get("baseUrl") + '/doc/';
      dropzone.options.url = url;
      dropzone.processQueue();
    },
    addImage: function addImage(image) {
      console.log(image);
      var dropzone = this.get("dropzone");
      this.set("title", null);
      this.set("description", null);
      this.set("showPreviewImage", false);
      this.get('notifications').success("gespeichert", {
        autoClear: true
      });
      dropzone.removeAllFiles();
      var path = this.get("field").path;
      this.sendAction("addPreviewImage", image, path);
    },
    setImage: function setImage() {
      var id = this.get("id");
      var field = this.get("field");
      var path = field.path;
      this.sendAction("setPreview", path, id);
      this.set("showPreviewImage", false);
    },

    actions: {
      toggleProperty: function toggleProperty(property) {
        this.toggleProperty(property);
        if (property === "showPreviewImage") {
          var documents = this.get("documents");
          if (documents.length > 0) {
            this.set("search", true);
            this.set("searchOption", true);
          }
        }
      },
      setId: function setId(id) {
        this.set("id", id);
      },
      setPreview: function setPreview() {
        var imageAdded = this.get("imageAdded");
        if (imageAdded !== undefined) {
          this.saveFile();
        } else {
          this.setImage();
        }
      },
      addedFile: function addedFile() {
        this._super.apply(this, arguments);
        this.set('dropzone', Dropzone.forElement('#assetUpload'));
        this.set("imageAdded", true);
      },

      success: function success(response) {
        var _this = this;

        var api = this.get("api");
        var that = this;
        var docs = this.get("docs");
        var name = this.get("name");
        var description = this.get("description");
        var dropzone = this.get("dropzone");
        var documents = this.get("assets");
        var response = JSON.parse(response.xhr.response);
        if (response !== undefined) {
          var meta = {};
          meta.id = response.resource[0].id;
          meta.title = this.get("title");
          meta.fileName = response.resource[0].fileName;
          meta.description = this.get("description");
          return docs.updateDocMeta(meta).then(function (Data) {
            console.log(Data);
            _this.addImage(Data[0]);
          }).catch(function (error) {});
        }
      }
    }
  });
});