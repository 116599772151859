define('extraapps/services/ds', ['exports', 'extraapps/config/environment', 'extraapps/util/promiseObject'], function (exports, _environment, _promiseObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        ajax: Ember.inject.service(),
        cache: Ember.inject.service(),
        session: Ember.inject.service(),
        ea: Ember.inject.service(),

        urlPart: undefined,

        config: _environment.default.backend,
        init: function init() {
            this._super.apply(this, arguments);
            this._initUrlPart();
        },
        _initUrlPart: function _initUrlPart() {
            if (this.urlPart === undefined) {
                this.urlPart = this.config.protocol + '://' + this.config.host;

                var port = this.config.port || '';
                if (port.length > 0) {
                    this.urlPart = this.urlPart + ':' + port + '/';
                } else {
                    this.urlPart = this.urlPart + '/';
                }

                var urlPrefix = this.config.url_prefix || '';
                if (urlPrefix.length > 0) {
                    this.urlPart = '' + this.urlPart + urlPrefix + '/';
                }
            }
        },


        _buildDatabaseDatasetUrl: function _buildDatabaseDatasetUrl(database, dataset) {
            this._initUrlPart();
            return this.urlPart + 'ps/' + database + '/' + dataset;
        },

        _buildInsertUrl: function _buildInsertUrl(database, dataset) {
            return this._buildDatabaseDatasetUrl(database, dataset);
        },

        _buildInsertDatasetUrl: function _buildInsertDatasetUrl() {
            this._initUrlPart();
            return '' + this.urlPart + _environment.default.backend.dataset_service_url_part;
        },

        /**
         * Inserts a record.
         *
         * The record is assigned the new id (generated by database) and updates the Cache with the new record using {type, id}.
         *
         * The record is sent to the databse as is. So be sure to clear the id if it contained an old one.
         *
         * @method insert
         * @param database
         * @param {string} dataset - technical name of the dataset
         * @param {string} record - the record to be inserted
         * @return {Ember.ObjectProxy} - The new record in the form of an ObjectProxy with a PromiseProxyMixin (if successful) or a rejected Ember.RSVP.Promise with a reason as shown in the example.
         * @example
         *     {
         *         service: 'backend',
         *         method: 'insert',
         *         args: {
         *             dataset: dataset,
         *             record: record
         *         },
         *         error: error
         *     }
         */
        insert: function insert(database, dataset, record) {
            if (dataset === 'dataset') {
                // TODO check that dataset does not yet exist
                return this.replaceDataset(record);
            }
            var that = this;

            var insertData = [];

            record['identifier'] = database;

            insertData.push(record);
            var records = {};
            records.resource = insertData;

            var json = JSON.stringify(records);

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(that._buildInsertUrl(database, dataset), {
                    data: { resource: [json] },
                    headers: that.getDefaultsHeaders()
                }).then(function (data) {
                    var newRecord = data.resource[0];
                    Ember.assign(record, newRecord);
                    that.get('cache').cache(type, record.id, record);
                    resolve(record);
                }).catch(function (error) {
                    var reason = {
                        service: 'ds',
                        method: 'insert',
                        args: {
                            database: database,
                            dataset: dataset,
                            record: record
                        },
                        error: error
                    };
                    reject(reason);
                });
            });

            return (0, _promiseObject.promiseObject)(promise, 'ds.insert(' + dataset + ', ' + record + ')');
        },

        getDefaultsHeaders: function getDefaultsHeaders() {
            var ea = this.get('ea');
            return ea.getDefaultsHeaders();
        },
        replaceDataset: function replaceDataset(record) {
            var that = this;
            var record = record;

            record.cid = this.get("session").get("client_id");
            record.aid = parseInt(this.get("session").get("app_id"));
            var url = that._buildInsertDatasetUrl();
            var json = {};
            json.headers = that.getDefaultsHeaders();
            Ember.set(record, 'identifier', '_external'); // TODO pass parameter
            var nutzlast = { resource: [record] };
            json.data = JSON.stringify(nutzlast);
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(url, json).then(function (data) {
                    var newRecord = data.resource;
                    resolve(newRecord);
                    that.get('cache').cache('dataset', newRecord.id, newRecord);
                }).catch(function (error) {
                    var reason = {
                        service: 'ds',
                        method: 'modifyDataset',
                        args: {
                            record: record
                        },
                        error: error
                    };
                    reject(reason);
                });
            });

            return (0, _promiseObject.promiseArray)(promise, 'ds.replaceDataset(' + record + ')');
        },


        getDataset: function getDataset(datasetName) {
            var that = this;
            var url = that.urlPart + 'ds/_external/_dataset/' + datasetName;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(url, { headers: that.getDefaultsHeaders() }).then(function (dataset) {

                    resolve(dataset);
                }).catch(function (error) {
                    var reason = {
                        service: 'ds',
                        method: 'getDataset',
                        args: {},
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'ds.getDataset(' + datasetName + ')');
        },

        deleteDataset: function deleteDataset(datasetName) {
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(that.urlPart + 'ds/_external/_dataset/' + datasetName, { headers: that.getDefaultsHeaders() }).then(function (data) {
                    var deletedDataset = data.resource[0];
                    resolve(deletedDataset);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'ds.deletedDataset(' + datasetName + ')');
        },

        getDatasets: function getDatasets() {
            var that = this;
            var cache = this.get("cache");
            var datasets = null; //cache.getArray("datasets");
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                if (datasets === null) {
                    that.get('ajax').request(that.urlPart + 'ds/_external/dataset', {
                        headers: that.getDefaultsHeaders()
                    }).then(function (data) {
                        resolve(data.resource);
                        cache.insertArray("datasets", data);
                    }).catch(function (error) {
                        var reason = {
                            service: 'ds',
                            method: 'getDatasets',
                            error: error
                        };
                        reject(reason);
                    });
                } else {
                    resolve(datasets);
                }
            });

            return (0, _promiseObject.promiseArray)(promise, 'ds.getDatasets()');
        }
    });
});