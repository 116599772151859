define('extraapps/r1/r2/r3/r4/controller', ['exports', 'ember-parachute'], function (exports, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ea: Ember.inject.service(),
    queryPrams: ["id", "layout"],
    layout: [null],
    id: [null],
    actions: {
      setView: function setView() {
        this.get("ea").getController("application").setView("test");
      }
    }
  });
});