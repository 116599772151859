define("extraapps/components/ea-form-relateddata/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ps: Ember.inject.service(),
    relation: Ember.inject.service(),
    show: false,
    init: function init() {
      var _this = this;

      this._super();
      var index = this.get("index");
      var relatedDataset = this.get("meta").relatedDatasets[index];
      return this.get("ps").selectRecords('_external', relatedDataset.name, {}).then(function (records) {
        var values = [];
        for (var a = 0; a < records.length; a++) {
          var record = records[a];
          var value = { text: record.name + " - " + record.leistung + " kW", id: record.id };
          values.addObject(value);
        }
        _this.set("values", values);
        _this.set("record", records);
      }).catch(function (error) {
        console.log(error);
      });
    },

    actions: {
      addRelation: function addRelation() {
        var index = this.get("index");
        var toId = this.get("value");
        var fromId = -1;
        var toName = this.get("meta").relatedDatasets[index].name;console.log(toName);
        var relationTypeId = this.get("meta").relationTypes.findBy("toDatasetName", toName).id;
        var qualifier = null;
        var relation = { recordId: toId, relationTypeId: relationTypeId, qualifierKey: "standard", changeType: "INSERT" };
        this.sendAction("addRelation", relation);
      }
    }
  });
});