define("extraapps/controllers/application", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ["id"],
    id: null

  });
});