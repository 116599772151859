define("extraapps/components/ea-map-ex/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        api: Ember.inject.service(),
        background: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
        bgs: "default, esri",
        bounds: [[49.04626762718739, 8.886618379021998], [9.482775498510643, 49.36728895523311]],
        init: function init() {
            var _this = this;

            this._super();
            var data = this.get("ds");
            return this.get("api").getSettings(data).then(function (settings) {
                var json = JSON.parse(settings[0].setting);
                var bbox = L.geoJson(json).getBounds();
                _this.set("JSON", json);
                _this.set("bounds", bbox);

                _this.set("hideSpinner", true);
            }).catch(function (error) {
                alert(error);
            });
        },
        actions: {
            setBg: function setBg(bg) {
                this.set("background", bg);
            }
        }
    });
});