define("extraapps/components/changerequest-map/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super();
      var change = this.get("change");
      var geodata = change.data.geodaten;
      var bounds = null;
      if (geodata.type === "Point") {
        bounds = { type: "Point", coordinates: geodata.coordinates };
        geodata.coordinates = [geodata.coordinates[1], geodata.coordinates[0]];
        this.set("pointData", true);
        this.set("geodata", geodata);
      } else {
        this.set("goeJSON", geodata);
        this.set("pointData", false);
        bounds = { "geometries": [geodata], "type": "GeometryCollection" };
      }

      this.calcBounds(bounds);
    },

    calcBounds: function calcBounds(obj) {

      var bbox = L.geoJson(obj).getBounds();
      this.set("bounds", bbox);
      this.set("showMap", true);
    }
  });
});