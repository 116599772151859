define("extraapps/components/ds-viewer-map-houses/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            open: function open() {
                this.sendAction("open", "getHouses");
                this.sendAction("open", "draw");
                this.sendAction("setShape", "MultiPolygon");
            },
            close: function close() {
                this.sendAction("close", "getHouses");
                this.sendAction("import");
            }
        }
    });
});