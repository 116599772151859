define("extraapps/components/import-button/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            goTo: function goTo(data) {
                this.bubbleAction("goToImporter", data.name);
            }
        }
    });
});