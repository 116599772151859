define('extraapps/admin/style/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    title: 'Admin > Style',
    api: Ember.inject.service(),
    ea: Ember.inject.service(),

    session: Ember.inject.service(),
    model: function model(params, transition) {
      var app = window.location.href.split("app=")[1].toLowerCase();
      return this.get("api").getSettings(app + '.css').then(function (css) {

        return { css: css[0] };
      }).catch(function (error) {});
    }
  });
});