define("extraapps/services/es", ["exports", "extraapps/util/promiseObject", "extraapps/util/util", "extraapps/config/environment"], function (exports, _promiseObject, _util, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        ajax: Ember.inject.service(),
        ea: Ember.inject.service(),
        session: Ember.inject.service(),
        createType: function createType(name) {
            var ea = this.get("ea");
            var session = this.get("session");
            var URL = ea.getBaseURL();
            URL = URL + "/enum/type";
            var json = JSON.stringify({ "resource": [{ "enumType": name, cid: session.client_id, aid: session.app_id }] });
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, { headers: ea.getDefaultsHeaders(), data: json }).then(function (data) {
                    var valueLists = data.resource;
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, "es.createType()");
        },

        deleteType: function deleteType(name) {
            var ea = this.get("ea");
            var URL = ea.getBaseURL();
            URL = URL + "/enum/type/" + name;
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, { headers: ea.getDefaultsHeaders() }).then(function (data) {
                    var valueLists = data.resource;
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "es.deleteType()");
        },

        getTypes: function getTypes() {
            var ea = this.get("ea");
            var URL = ea.getBaseURL();
            URL = URL + "/enum/type";
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, { headers: ea.getDefaultsHeaders() }).then(function (data) {
                    var valueLists = data.resource;
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "es.getTypes()");
        },

        createAttributes: function createAttributes(attrs) {
            var ea = this.get("ea");
            var URL = ea.getBaseURL();
            URL = URL + "/enum/attribute";
            var json = JSON.stringify({ "resource": attrs });
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, { headers: ea.getDefaultsHeaders(), data: json }).then(function (data) {
                    var valueLists = data.resource;
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, "es.createEnumeAttribute()");
        },

        deleteAttributes: function deleteAttributes(attributes) {
            var ea = this.get("ea");
            var URL = ea.getBaseURL();
            URL = URL + "/enum/attribute";
            var json = JSON.stringify({ "resource": [attributes] });
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, { headers: ea.getDefaultsHeaders(), data: json }).then(function (data) {
                    var valueLists = data.resource;
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "es.deleteAttributes()");
        },

        getAttributes: function getAttributes() {
            var ea = this.get("ea");
            var URL = ea.getBaseURL();
            URL = URL + "/enum/attribute";
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, { headers: ea.getDefaultsHeaders() }).then(function (data) {
                    var valueLists = data.resource;
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "es.getAttributes()");
        },

        getAttributesForType: function getAttributesForType(type) {
            var ea = this.get("ea");
            var URL = ea.getBaseURL();
            URL = URL + "/enum/attribute/" + type;
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, { headers: ea.getDefaultsHeaders() }).then(function (data) {
                    var valueLists = data.resource;
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "es.getAttributesForType(" + type + ")");
        }

    });
});