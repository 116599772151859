define("extraapps/components/ea-link/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    init: function init() {
      this._super();
      var layout = this.get("layout");
      if (layout === undefined) {
        this.set("layout", null);
      }
      var target = this.get("target");
      var targets = target.split("/");
      var innerTag = this.get("innerTag");
      var linkText = this.get("linkText");
      var id = this.get("id");
      if (id === undefined) {
        this.set("id", null);
      }
      if (innerTag === undefined) {
        this.set("innerTag", "a");
      }
      if (linkText === undefined) {
        this.set("linkText", targets[targets.length - 1]);
      }
      switch (targets.length) {
        case 1:
          this.set("r1", true);
          this.set("u1", targets[0]);
          ;break;
        case 2:
          this.set("r2", true);
          this.set("u1", targets[0]);this.set("u2", targets[1]);
          ;break;
        case 3:
          this.set("r3", true);
          this.set("u1", targets[0]);this.set("u2", targets[1]);this.set("u3", targets[2]);
          ;break;
        case 4:
          this.set("r4", true);
          this.set("u1", targets[0]);this.set("u2", targets[1]);this.set("u3", targets[2]);this.set("u4", targets[3]);
          ;break;
        case 5:
          level = "r1.r2.r3.r4.r5";break;
        case 6:
          level = "r1.r2.r3.r4.r5.r6";break;
        case 7:
          level = "r1.r2..r3.r4.r5.r6.r7";break;
      }
    },

    pathChanged: function () {
      var target = this.get("target");
      var path = this.get("path").toString();
      var currentPath = path.replace(",", "/");

      if (currentPath === target) {
        this.set("innerClass", "current");
      } else if (currentPath.indexOf(target) !== -1) {
        this.set("innerClass", "contained");
      } else {
        this.set("innerClass", "");
      }
    }.observes("path").on("init")
  });
});