define("extraapps/components/ea-chart/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        api: Ember.inject.service(),
        init: function init() {
            var _this = this;

            this._super();
            var chart = this.get("chart");
            return this.get("api").getSettings(chart).then(function (settings) {
                _this.set("settings", JSON.parse(settings[0].setting));
                console.log(_this.get("settings"));
                _this.set("show", true);
            }).catch(function (error) {
                console.log(error);
            });
        }
    });
});