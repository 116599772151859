define('extraapps/services/cf', ['exports', 'extraapps/config/environment', 'extraapps/util/util'], function (exports, _environment, _util) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        queryFlughafen: function queryFlughafen(query, deferred) {
            var database = this.get('database');
            database.query(_environment.default.database.data_srv, 'cf_airport', '(municipality LIKE %' + query.term + '%) OR (iata_code LIKE ' + query.term + '%)', ['iata_code', 'name', 'municipality', 'id', 'lat', 'lon', 'frequency'].join()).then(function (airports) {
                //'when everything has been loaded, specify more: false'
                airports.forEach(function (airport) {
                    airport.text = airport.iata_code + ', ' + airport.municipality + ', ' + airport.name;
                });
                airports.sort(function (a, b) {
                    return b.frequency - a.frequency;
                });
                deferred.resolve({ data: airports, more: false });
            }, deferred.reject);
        },

        queryAddress: function queryAddress(query, deferred) {
            var database = this.get('database');
            var isPLZ = (0, _util.isNumeric)(query.term);
            var selectFields = ['PLZ', 'Gemeindename', 'id', 'lat', 'lon', 'AGS'].join();
            var that = this;
            if (isPLZ) {
                database.query(_environment.default.database.data_srv, 'cf_fund', 'PLZ LIKE ' + query.term + '%', selectFields).then(function (funds) {
                    that._processFunds(funds);
                    deferred.resolve({ data: funds, more: false });
                }, deferred.reject);
            } else {
                database.query(_environment.default.database.data_srv, 'cf_fund', 'Gemeindename LIKE %' + query.term + '%', selectFields).then(function (funds) {
                    that._processFunds(funds);
                    deferred.resolve({ data: funds, more: false });
                }, deferred.reject);
            }
        },

        _processFunds: function _processFunds(funds) {
            funds.forEach(function (fund) {
                fund.text = fund.PLZ + ' ' + fund.Gemeindename;
            });
            funds.sort(function (a, b) {
                return a.Gemeindename.localeCompare(b.Gemeindename);
            });
        },


        lookup: function lookup(key) {
            return this.container.lookup(key);
        },

        getCurrentRouteName: function getCurrentRouteName() {
            return this.getController('application').get('currentRouteName');
        },
        getCurrentPath: function getCurrentPath() {
            return this.getController('application').get('currentPath');
        },


        getModel: function getModel(routeName) {
            return this.router && this.router.modelFor(routeName);
            // let controller = this.getController(routeName);
            // return controller && controller.get('model');
        },

        hasDetails: function hasDetails(routeName, targetName) {
            if ((0, _util.isEmpty)(routeName) || (0, _util.isEmpty)(targetName)) {
                return undefined;
            }

            if (targetName.endsWith('.index')) {
                targetName = targetName.substring(0, targetName.length - 6);
            }
            var routeSegments = routeName.split('.');
            var targetSegments = targetName.split('.');

            return targetSegments.length > routeSegments.length;
        },

        loadCfData: function loadCfData(newModel) {
            "use strict";

            var database = this.get('database');
            return database.findAll(_environment.default.database.data_srv, 'cf_contracting').then(function (cf_contracting) {
                return database.findAll(_environment.default.database.data_srv, 'cf_ee_anlagen').then(function (cf_ee_anlagen) {
                    return database.findAll(_environment.default.database.data_srv, 'cf_toepfe').then(function (cf_toepfe) {
                        return database.findAll(_environment.default.database.data_srv, 'cf_projekte').then(function (cf_projekte) {
                            newModel.cf_toepfe = cf_toepfe;
                            newModel.cf_ee_anlagen = cf_ee_anlagen;
                            newModel.cf_contracting = cf_contracting;
                            newModel.cf_projekte = cf_projekte;
                            return newModel;
                        });
                    });
                });
            });
        }
    });
});