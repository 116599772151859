define("extraapps/components/termine-filter/component", ["exports", "moment"], function (exports, _moment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super();
            var model = JSON.parse(JSON.stringify(this.get("filterOn")));
            var today = (0, _moment.default)().format("YYYY-MM-DD");

            var _loop = function _loop() {
                var ds = model.data[i];
                var records = ds.records;
                var filtered = [];
                records.forEach(function (r) {
                    if (r.anfang > today) {
                        filtered.addObject(r);
                    }
                });
                Ember.set(ds, "records", filtered);
            };

            for (var i = 0; i < model.data.length; i++) {
                _loop();
            }
            Ember.set(this, "filtered", model);
            this.set("show", true);
        }
    });
});