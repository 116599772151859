define('extraapps/components/dataset-view-cal/component', ['exports', 'ember-context-menu'], function (exports, _emberContextMenu) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function newRecord(detail) {
        var layer = detail.that.get("layers")[0];
        detail.that.sendAction("newRecord", layer.meta.name, 0);
    }
    exports.default = Ember.Component.extend( /*contextMenuMixin,*/{
        contextDetails: {},
        contextItems: [{
            label: 'Daten hinzufügen',
            action: function action(selection, details, event) {
                newRecord(details);
            }
        }],
        init: function init() {
            this._super();
            //let contextDetails = this.get("contextDetails");
            var events = this.get("events");
            // Ember.set(contextDetails, "that", this);

        },

        layersChanged: function () {
            var _this = this;

            var layers = this.get("layers");
            Ember.set(this, "selected", layers[0]);
            if (layers !== undefined && layers.length > 0) {
                var events = this.get("events");
                var cleanedEvents = [];
                layers.forEach(function (layer) {
                    var fields = layer.meta.fields;
                    var dateField = fields.findBy("logical_type", "DATE");
                    var startField = fields.findBy("logical_type", "BEGIN");
                    var endField = fields.findBy("logical_type", "END");
                    if (dateField !== undefined) {
                        var datePath = dateField.path;
                        layer.records.forEach(function (record) {
                            if (record[datePath] !== undefined) {
                                var event = {};
                                event.allDay = true;
                                event.layerId = layer.meta.id;
                                event.color = layer.colors.fillColor;
                                event.start = record[datePath];
                                cleanedEvents.addObject(event);
                            }
                        });
                    } else if (startField !== undefined && endField !== undefined) {
                        var startPath = startField.path;
                        var endPath = endField.path;
                        layer.records.forEach(function (record) {
                            if (record[startPath] !== undefined) {
                                var event = {};
                                event.allDay = false;
                                event.title = moment(record[startPath]).format("HH:mm") + ' - ' + moment(record[endPath]).format("HH:mm");
                                event.layerName = layer.meta.name;
                                event.id = record.id;
                                event.color = layer.colors.fillColor;
                                event.start = record[startPath];
                                event.end = record[endPath];
                                cleanedEvents.addObject(event);
                            }
                        });
                    }
                    Ember.set(_this, "cleanedEvents", cleanedEvents);
                });
            }
        }.observes("layers").on("init"),

        actions: {
            setLayout: function setLayout(layout) {
                this.sendAction("setLayout", layout);
            },

            dayClick: function dayClick(date, jsEvent, view) {},
            eventClick: function eventClick(event) {

                this.sendAction("goToRecord", event.layerName, event.id);
            }
        }
    });
});