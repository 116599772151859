define('extraapps/admin/structure/controller', ['exports', 'extraapps/util/util'], function (exports, _util) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = Ember.Controller.extend({

        singleField: false,
        notifications: Ember.inject.service('notification-messages'),
        ds: Ember.inject.service(),
        session: Ember.inject.service(),
        types: (0, _util.fieldTypes)(),
        uoms: (0, _util.uoms)(),
        validationTypes: [{ text: "notwendig", id: "requierd" }, { text: "Zahl", id: "number" }, { text: "Ganzzahl", id: "integer" }, { text: "mindestens", id: "min" }, { text: "maxmimal", id: "max" }, { text: "Länge", id: "length" }, { text: "min. Länge", id: "minLength" }, { text: "max. Länge", id: "maxLength" }, { text: "Wertebereich", id: "range" }, { text: "Text", id: "string" }, { text: "Mail", id: "mail" }, { text: "gleich", id: "match" }, { text: "ungleich", id: "noMatch" }],

        validations: null,
        validationsChanged: function () {

            var validations = this.get('validations');
            if (typeof validations === 'string' || validations instanceof String) {
                validations = JSON.parse(validations);
                this.set('model.field.validations', validations);
            }
        }.observes('validations').on('init'),
        saveDataset: function saveDataset() {
            var dataset = this.get('model.meta');
            var ds = this.get('ds');
            var that = this;

            if (Array.isArray(dataset.fields)) {
                // TODO DRY, see route.js
                if (dataset.fields.length > 0) {
                    dataset.fields.sort(function (field1, field2) {
                        that.initSequenceNumber(field1);
                        that.initSequenceNumber(field2);
                        return field1.sequenceNumber - field2.sequenceNumber;
                    });
                    // TODO how to get fields redrawn in new sort order (sequenceNumber)?
                }
                ds.replaceDataset(dataset).then(function (data) {
                    that.get('notifications').success('Änderungen gespeichert', { // TODO: use i18n text...
                        autoClear: true
                    });
                    that.set("singleField", false);
                    that.set("newField", false);
                    that.set("field", {});
                }).catch(function (error) {
                    var message = 'Beim Speichern ist ein Fehler aufgetreten\n\n' + JSON.stringify(error);
                    that.get('notifications').error(message, {
                        autoClear: true
                    });
                });
            }
        },
        getMaxSequenceNumber: function getMaxSequenceNumber(fields) {
            var _this = this;

            var maxSequenceNumber = 0;
            if (Array.isArray(fields)) {
                var length = fields.length;
                if (length > 0) {
                    fields.sort(function (field1, field2) {
                        _this.initSequenceNumber(field1);
                        _this.initSequenceNumber(field2);
                        return field1.sequenceNumber - field2.sequenceNumber;
                    });
                    maxSequenceNumber = fields[length - 1].sequenceNumber;
                }
            }
            return maxSequenceNumber;
        },
        initSequenceNumber: function initSequenceNumber(field) {
            // TODO DRY, see route.js
            if (field.sequenceNumber === null || field.sequenceNumber === undefined) {
                field.sequenceNumber = 0;
            }
        },
        actions: _defineProperty({
            removeVal: function removeVal(val) {
                var vals = this.get("field").validations;
                vals.removeObject(val);
            },
            addVal: function addVal() {
                var type = this.get("valType");
                var args = this.get("args");
                var message = this.get("message");
                var field = this.get("field");
                var val = { type: type, args: args, message: message };
                if (message === null || message === undefined) {
                    this.get('notifications').error("Bitte geben sie eine Fehlermeldung an", {
                        autoClear: true
                    });
                    return;
                } else if (type === "min" || type === "max" || type === "minLength" || type === "maxLength") {
                    if (args === undefined || args === null) {
                        this.get('notifications').error("Bitte geben sie einen Wert an", {
                            autoClear: true
                        });
                        return;
                    }
                }

                field.validations.addObject(val);
                this.set("args", null);
                this.set("message", null);
                this.set("valType", null);
            },
            selectField: function selectField(field) {
                this.set("field", field);
                this.set("singleField", true);
            },
            updateField: function updateField(field, property) {
                this.saveDataset();
            },
            reorderItems: function reorderItems(itemModels, draggedModel) {
                for (var a = 0; a < itemModels.length; a++) {
                    var item = itemModels[a];
                    Ember.set(item, "sequenceNumber", a);
                }
                var dataset = this.get("model");
                Ember.set(dataset, "fields", itemModels);
                this.saveDataset();
            },

            newField: function newField() {
                var field = {};
                this.set("field", field);
                this.set("singleField", true);
                this.set("newField", true);
            },
            addField: function addField(field) {
                field.cid = this.get("session").get("client_id");
                field.aid = this.get("session").get("app_id");
                var fields = this.get("model.meta.fields");
                var maxSeqNum = this.getMaxSequenceNumber(fields);
                field.sequenceNumber = maxSeqNum + 1;
                fields.addObject(field);
                this.saveDataset();
            },
            initSequenceNumber: function initSequenceNumber(field) {
                // TODO DRY, see route.js
                if (field.sequenceNumber === null || field.sequenceNumber === undefined) {
                    field.sequenceNumber = 0;
                }
            },
            delete: function _delete(field) {
                var fields = this.get('model.meta.fields');
                fields.removeObject(field);
                this.saveDataset();
            }
        }, 'reorderItems', function reorderItems(itemModels, draggedModel) {
            for (var a = 0; a < itemModels.length; a++) {
                var item = itemModels[a];
                Ember.set(item, 'sequenceNumber', a);
            }
            var dataset = this.get('model');
            Ember.set(dataset, 'fields', itemModels);
            this.saveDataset();
        })
    });
});