define("extraapps/components/goto-structure-button/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      goTo: function goTo(id) {
        this.bubbleAction("goToStructure", id);
      }
    }
  });
});