define('extraapps/services/gc', ['exports', 'extraapps/util/promiseObject'], function (exports, _promiseObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        ajax: Ember.inject.service(),
        ds: Ember.inject.service(),
        ea: Ember.inject.service(),
        ps: Ember.inject.service(),

        notifications: Ember.inject.service('notification-messages'),
        addCondition: function addCondition(conditions, condition, address, property) {
            var addressProperty = address[property];
            this.addConditionProperty(conditions, condition, addressProperty);
        },

        addConditionProperty: function addConditionProperty(conditions, condition, property) {
            if (property !== undefined && property !== null && property.length > 1) {
                conditions.push([condition, encodeURIComponent(property)].join('='));
            }
        },
        /*
            someUsingFunction: function() {
                let that = this;
                [...]
                this.get('ea').forEachCps(array,
                    function (element, index, next) {       // visitor
                        // do something with element = array[index], then call next()
                    },
                    that.myDoneFunction,
                    that
                );
            },
             myDoneFunction: function() {
                [...]
            }
        */
        geocodedRecords: null,

        /*
        Our visitor function for cps geocoding
         */

        geoCodeDS: function geoCodeDS(dsName) {
            var that = this;

            that.get("ds").getDataset(dsName).then(function (meta) {
                var fields = meta.fields;
                var streetField = fields.findBy("logical_type", "STREET");
                var streetPath = streetField.path;
                var numberField = fields.findBy("logical_type", "HOUSE_NUMBER");
                var numberPath = numberField.path;
                var plzField = fields.findBy("logical_type", "POSTCODE");
                var plzPath = plzField.path;
                var cityField = fields.findBy("logical_type", "CITY");
                var cityPath = cityField.path;
                that.get("ps").selectRecords('_external', dsName, {}, { aid: meta.aid }).then(function (records) {
                    that.get("ea").forEachCps(records, function (record, index, next) {
                        var street = record[streetPath];
                        var number = record[numberPath];
                        var plz = record[plzPath];
                        var city = record[cityPath];
                        // Perform our async action(s)...
                        return that.geoCodeOneAddress(city, street, number, plz).then(function (geoInfos) {
                            var geoInfo = geoInfos[0].geometry.location;

                            if (geoInfo !== [] && geoInfo !== undefined) {
                                record.geodaten = '{"coordinates":["' + geoInfo.lng + '","' + geoInfo.lat + '"],"type":"Point"}';
                                return that.get("ps").updateRecord("_external", dsName, record).then(function (data) {
                                    // And as soon as everything is finished, call next()...
                                    that.get("notifications").success('Geocoded and updated record: ID - ' + data.resource[0].id, {
                                        autoClear: true
                                    });
                                    // could call next() directly (see next line), but give nominatim a break of 1000 ms...
                                    // next();
                                    var timeoutID = setTimeout(next, 500);
                                }).catch(function (error) {
                                    that.get("notifications").error('Error ' + JSON.stringify(error) + ' updating record: ID - ' + record.id);
                                    var timeoutID = setTimeout(next, 500);
                                });
                            } else {
                                that.get("notifications").error('Address for record not found: ID - ' + record.id);
                                var timeoutID = setTimeout(next, 500);
                            }
                        }).catch(function (error) {
                            that.get("notifications").error('Error ' + JSON.stringify(error) + ' geocoding record: ID -  ' + record.id);
                            var timeoutID = setTimeout(next, 500);
                        });
                    }, that.myDoneFunction, that);
                }).catch(function (error) {
                    console.log(error);
                });
            }).catch(function (error) {
                console.log(error);
            });
        },

        myDoneFunction: function myDoneFunction(that) {
            console.log("Could do something with " + JSON.stringify(that)); // this is undefined!
            console.log("All records geocoded.");
        },

        geoCodeOneAddress: function geoCodeOneAddress(ort, strasse, nr, plz) {
            // @todo enhance this tool to use field mappings...
            //strasse  = strasse.replace(/\u00df/g, "ss");
            var servicePrefix = 'https://maps.googleapis.com/maps/api/geocode/json?sensor=false&address=';

            var conditions = [];
            this.addConditionProperty(conditions, 'city', ort);
            this.addConditionProperty(conditions, 'postalcode', plz);
            if (nr === null || nr === undefined) {
                this.addConditionProperty(conditions, 'street', strasse);
            } else {
                this.addConditionProperty(conditions, 'street', strasse + ',' + nr);
            }

            this.addConditionProperty(conditions, 'format', 'json');
            var escapedConditions = conditions.join(',');
            //escapedConditions = "city=Berlin&street=Weisestrasse+29&postalcode=12049&format=json";
            var URL = '' + servicePrefix + ort + ',' + strasse + ' ' + nr + ',' + plz + '&key=AIzaSyBD4XVWBd1WlNXyq6MDSEC7NHEUwTs3E88';

            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL).then(function (data) {
                    resolve(data.results);
                }).catch(function (error) {
                    var reason = {
                        service: 'admin/tools',
                        method: 'geoCodeOneAddress',
                        args: {
                            address: ort + ' ' + strasse + ' ' + nr + ' ' + plz + ' '
                        },
                        error: error
                    };
                    Object.assign(reason, error);
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, 'gc.geoCodeOneAddress(' + ort + ', ' + strasse + ', ' + nr + ', ' + plz + ')');
        },

        getShapeByPosition: function getShapeByPosition(polygon) {
            var box = "";
            polygon.coordinates[0].forEach(function (c) {
                var node = c[1] + ' ' + c[0];
                if (box === "") {
                    box = box + node;
                } else {
                    box = box + ' ' + node;
                }
                box = box.substr(0, box.length - 5);
            });

            //let url = "http://overpass-api.de/api/interpreter?data=[out:json];(node(52.49616,13.447266,52.509535,13.469238)[amenity=post_box]);out=geom;";
            var url = 'https://overpass-api.de/api/interpreter?data=[out:json];(way(poly:"' + box + '")[building]);out geom;';
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(url).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        service: 'admin/tools',
                        method: 'getShapeByPosition',
                        args: {
                            location: location
                        },
                        error: error
                    };
                    Object.assign(reason, error);
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'gc.getShapeByPosition(' + location + ')');
        },


        getByName: function getByName(name) {
            //name = name.split(" ");
            //var escapedName = name.join('%20');
            var servicePrefix = 'http://nominatim.openstreetmap.org/search?q=';
            var url = '' + servicePrefix + name + '&polygon_geojson=1&format=json';
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(url).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        service: 'admin/tools',
                        method: 'getByName',
                        args: {
                            name: name
                        },
                        error: error
                    };
                    Object.assign(reason, error);
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, 'gc.getByName(' + name + ')');
        },

        reverseSearch: function reverseSearch(lat, lng) {
            var that = this;
            var servicePrefix = 'http://nominatim.openstreetmap.org/reverse?';

            var escapedConditions = 'lat=' + lat + '&lon=' + lng + '&format=json';
            var URL = '' + servicePrefix + escapedConditions;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        service: 'gc',
                        method: 'reverseSearch',
                        args: {
                            address: location
                        },
                        error: error
                    };
                    Object.assign(reason, error);
                    reject(reason);
                });
            });

            return (0, _promiseObject.promiseObject)(promise, 'gc.reverseSearch(' + location + ')');
        }
    });
});