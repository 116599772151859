define("extraapps/components/goto-page/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ea: Ember.inject.service(),

    actions: {
      goTo: function goTo(id, layout) {
        var appCont = this.get("ea").getController("application");

        var link = this.get("page");
        var record = this.get("record");
        if (record !== undefined) {
          link = link + "?id=" + record;
        } else {
          link = "" + link;
        }
        appCont.transitionToRoute(link);
      }
    }
  });
});