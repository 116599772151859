define("extraapps/components/ksrnk-technicaldata/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        enev: {
            dach: 0.24,
            außenwand: 0.24,
            ogeschossdecke: 0.30,
            kellerwaende: 0.30
        },
        init: function init() {
            this._super();
            var type = this.get("data.gebaeudetyp_2");
            if (type === "Neubau") {
                this.set("isNew", true);
            }
            var data = this.get("data");
            var columns = [];
            if (data.massnahmen_dach !== null) {
                columns.addObject({ "propertyName": "massnahmen_dach", "title": "Dach", "disableFiltering": true });
                columns.addObject({ "propertyName": "u_wert_dach", "title": "U-Wert", "disableFiltering": true });
                columns.addObject({ "propertyName": "u_wert_dach_alt", "title": "U-Wert voher", "disableFiltering": true });
            }
            this.set("columns", columns);
        }
    });
});