define('extraapps/services/auth', ['exports', 'extraapps/config/environment', 'extraapps/util/promiseObject'], function (exports, _environment, _promiseObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        ea: Ember.inject.service(),
        ajax: Ember.inject.service(),
        cache: Ember.inject.service(),
        session: Ember.inject.service(),

        getUsers: function getUsers() {
            var that = this;
            var ea = this.get("ea");
            var cache = this.get("cache");
            var users = cache.getArray("users");

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                if (users === null) {
                    that.get('ajax').request(_environment.default.backend.baseURL + '/authusers', {
                        headers: ea.getDefaultsHeaders()
                    }).then(function (data) {
                        resolve(data.resource);
                        cache.insertArray("users", data.resource);
                    }).catch(function (error) {
                        var reason = {
                            service: 'auth',
                            method: 'getUsers',
                            error: error
                        };
                        reject(reason);
                    });
                } else {
                    resolve(users);
                }
            });
            return (0, _promiseObject.promiseArray)(promise, 'auth.getUsers()');
        },
        getUser: function getUser(id) {
            var that = this;
            var ea = this.get("ea");
            var cache = this.get("cache");
            var user = cache.getObject("users", id);
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                if (user === null) {

                    that.get('ajax').request(_environment.default.backend.baseURL + '/authusers/' + id, {
                        headers: ea.getDefaultsHeaders()
                    }).then(function (data) {

                        resolve(data);
                    }).catch(function (error) {
                        var reason = {
                            service: 'auth',
                            method: 'getUser',
                            error: error
                        };
                        reject(reason);
                    });
                } else {
                    resolve(user);
                }
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.getUser(' + id + ')');
        },
        getResolvedUser: function getResolvedUser(id) {
            var that = this;
            var ea = this.get("ea");
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(_environment.default.backend.baseURL + '/authusers/resolved/' + id, {
                    headers: ea.getDefaultsHeaders()
                }).then(function (data) {

                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        service: 'auth',
                        method: 'getUser',
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.getResolvedUser(' + id + ')');
        },
        insertUser: function insertUser(user) {
            var cache = this.get("cache");
            var URL = _environment.default.backend.baseURL + '/authusers';
            var cid = this.get("session").get("client_id");
            if (cid === undefined) {
                alert("CID WIRD IM AUTH Service NOCH MANUEL AUF 1 GESETZT");
                cid = 1;
            }
            user.cid = cid;
            var json = JSON.stringify(user);
            var that = this;
            var params = {
                data: json,
                headers: that.get("ea").getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, params).then(function (data) {
                    resolve(data);
                    cache.insertObject("users", data);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'insertUser',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.insertUser(' + user + ')');
        },
        updateUser: function updateUser(user) {
            var cache = this.get("cache");
            var URL = _environment.default.backend.baseURL + '/authusers/' + user.id;
            var nutzlast = {};
            nutzlast = user;
            var json = JSON.stringify(nutzlast);
            var that = this;
            var params = {
                data: json,
                headers: that.get("ea").getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, params).then(function (data) {
                    resolve(data);
                    cache.update("users", data);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'updateUser',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.updateUser(' + user + ')');
        },
        deleteUser: function deleteUser(id) {
            var cache = this.get("cache");
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {

                that.get('ajax').delete(_environment.default.backend.baseURL + '/authusers/' + id, {
                    headers: that.get("ea").getDefaultsHeaders()
                }).then(function (data) {
                    resolve(data);
                    cache.deleteObject("users", id);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'deleteUser',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.deleteUser(' + id + ')');
        },

        // GROUPS

        getGroups: function getGroups() {
            var that = this;
            var cache = this.get("cache");
            var ea = this.get("ea");
            var groups = null; //cache.getArray("groups");
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                if (groups === null) {
                    that.get('ajax').request(_environment.default.backend.baseURL + '/authgroups', {
                        headers: ea.getDefaultsHeaders()
                    }).then(function (data) {
                        resolve(data.resource);
                        cache.insertArray("groups", data.resource);
                    }).catch(function (error) {
                        var reason = {
                            service: 'auth',
                            method: 'getGroups',
                            error: error
                        };
                        reject(reason);
                    });
                } else {
                    resolve(groups);
                }
            });
            return (0, _promiseObject.promiseArray)(promise, 'auth.getGroups()');
        },
        getGroup: function getGroup(id) {
            var cache = this.get("cache");
            var that = this;
            var ea = this.get("ea");
            var group = this.get("groups", id);
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                if (group === null) {
                    that.get('ajax').request(_environment.default.backend.baseURL + '/authgroups/' + id, {
                        headers: ea.getDefaultsHeaders()
                    }).then(function (data) {
                        resolve(data);
                        cache.addObject("groups", data);
                    }).catch(function (error) {
                        var reason = {
                            service: 'auth',
                            method: 'getGroup',
                            error: error
                        };
                        reject(reason);
                    });
                } else {
                    resolve(group);
                }
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.getGroup(' + id + ')');
        },
        getGroupResolved: function getGroupResolved(id) {
            var that = this;
            var cache = this.get("cache");
            var ea = this.get("ea");
            var resolvedGroup = null;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                if (resolvedGroup === null) {

                    that.get('ajax').request(_environment.default.backend.baseURL + '/authgroups/resolved/' + id, {
                        headers: ea.getDefaultsHeaders()
                    }).then(function (data) {

                        resolve(data);
                    }).catch(function (error) {
                        var reason = {
                            service: 'auth',
                            method: 'getGroupResolved',
                            error: error
                        };
                        reject(reason);
                    });
                } else {
                    resolve(resolvedGroup);
                }
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.getGroupResolved(' + id + ')');
        },
        getRoles: function getRoles() {
            var that = this;
            var ea = this.get("ea");
            var cache = this.get("cache");
            var roles = cache.getArray("roles");
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                if (roles === null) {
                    that.get('ajax').request(_environment.default.backend.baseURL + '/authroles', {
                        headers: ea.getDefaultsHeaders()
                    }).then(function (data) {
                        resolve(data.resource);
                        cache.addArray("roles", data.ressource);
                    }).catch(function (error) {
                        var reason = {
                            service: 'auth',
                            method: 'getUsers',
                            error: error
                        };
                        reject(reason);
                    });
                } else {
                    resolve(roles);
                }
            });
            return (0, _promiseObject.promiseArray)(promise, 'auth.getRoles()');
        },
        getRole: function getRole(id) {
            var that = this;
            var ea = this.get("ea");
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(_environment.default.backend.baseURL + '/authroles/' + id, {
                    headers: ea.getDefaultsHeaders()
                }).then(function (data) {

                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        service: 'auth',
                        method: 'getRole',
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.getRole(' + id + ')');
        },
        getResolvedRole: function getResolvedRole(id) {
            var that = this;
            var ea = this.get("ea");
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(_environment.default.backend.baseURL + '/authroles/resolved/' + id, {
                    headers: ea.getDefaultsHeaders()
                }).then(function (data) {

                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        service: 'auth',
                        method: 'getRole',
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.getResolvedRole(' + id + ')');
        },
        insertRole: function insertRole(role) {
            var cache = this.get("cache");
            var URL = _environment.default.backend.baseURL + '/authroles';
            var cid = this.get("session").get("client_id");
            var aid = this.get("session").get("app_id");

            if (aid === undefined) {
                aid = 0;
            }
            if (cid === undefined) {
                alert("CID WIRD IM AUTH Service NOCH MANUEL AUF 1 GESETZT");
                cid = 1;
            }
            role.cid = cid;
            var json = JSON.stringify(role);
            var that = this;
            var params = {
                data: json,
                headers: that.get("ea").getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, params).then(function (data) {
                    resolve(data);
                    cache.insertObject("roles", data);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'insertRole',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.insertRole(' + role + ')');
        },
        updateRole: function updateRole(role) {
            var cache = this.get("cache");
            var URL = _environment.default.backend.baseURL + '/authroles/' + role.id;
            var json = JSON.stringify(role);
            var that = this;
            var params = {
                data: json,
                headers: that.get("ea").getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, params).then(function (data) {
                    resolve(data);
                    cache.updateObject("roles", role);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'updateRole',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.updateRole(' + role + ')');
        },
        deleteRole: function deleteRole(id) {
            var that = this;
            var cache = this.get("cache");
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(_environment.default.backend.baseURL + '/authroles/' + id, {
                    headers: that.get("ea").getDefaultsHeaders()
                }).then(function (data) {
                    resolve(data);
                    var role = cache.roles.findBy("id", data.id);
                    cache.removeObject("roles", id);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'deleteGroup',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.deleteRole(' + id + ')');
        },
        addRoleToEntity: function addRoleToEntity(role, entity, type) {

            var tempEntity = JSON.parse(JSON.stringify(entity));
            delete tempEntity["users"];
            delete tempEntity["aid"];
            delete tempEntity["permissions"];
            delete tempEntity["filteredPermissions"];
            delete tempEntity["roles"];
            delete tempEntity["subgroups"];
            delete tempEntity["linkId"];
            delete role["isDraggingObject"];

            var URL = "";
            var cid = this.get("session").get("client_id");
            var aid = this.get("session").get("app_id");
            if (cid === undefined) {
                alert("CID WIRD IM AUTH Service NOCH MANUEL AUF 1 GESETZT");
                cid = 1;
            }
            if (aid === undefined) {
                aid = 0;
            }
            var nutzlast = {
                cid: cid,
                aid: aid,
                authRole: role
            };
            switch (type) {
                case "group":
                    URL = _environment.default.backend.baseURL + '/authgroupgrantroles';
                    nutzlast.authGroup = tempEntity;
                    break;
                case "user":
                    URL = _environment.default.backend.baseURL + '/authusergrantroles';
                    nutzlast.authUser = tempEntity;
                    break;
                case "role":
                    URL = _environment.default.backend.baseURL + '/authroleaddroles';
                    nutzlast.authRole = tempEntity;
                    break;
            }

            var json = JSON.stringify(nutzlast);
            var that = this;
            var params = {
                data: json,
                headers: that.get("ea").getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, params).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'insertUser',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.addRoleToEntity(' + role + ',' + entity + ', ' + aid + ', ' + type + ')');
        },
        removeRoleFromEntity: function removeRoleFromEntity(id, type) {

            var that = this;
            var URL = null;
            switch (type) {
                case "group":
                    URL = _environment.default.backend.baseURL + '/authgroupgrantroles/' + id;
                    break;
                case "user":
                    URL = _environment.default.backend.baseURL + '/authusergrantroles/' + id;
                    break;
                case "role":
                    URL = _environment.default.backend.baseURL + '/authroleaddroles/' + id;
                    break;
            };

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, {
                    headers: that.get("ea").getDefaultsHeaders()
                }).then(function (data) {

                    resolve(data);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'insertUser',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.removeRolerFromEntity()');
        },
        insertGroup: function insertGroup(group) {
            var cache = this.get("cache");
            var URL = _environment.default.backend.baseURL + '/authgroups';
            var cid = this.get("session").get("client_id");
            if (cid === undefined) {
                alert("CID WIRD IM AUTH Service NOCH MANUEL AUF 1 GESETZT");
                cid = 1;
            }
            group.cid = cid;
            var json = JSON.stringify(group);
            var that = this;
            var params = {
                data: json,
                headers: that.get("ea").getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, params).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'insertGroup',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.insertGroup(' + group + ')');
        },
        updateGroup: function updateGroup(group) {
            var cache = this.get("cache");
            var tempGroup = JSON.parse(JSON.stringify(group));
            delete tempGroup["permissions"];
            delete tempGroup["filteredPermissions"];
            delete tempGroup["roles"];
            delete tempGroup["subgroups"];
            delete tempGroup["users"];
            var URL = _environment.default.backend.baseURL + '/authgroups/' + group.id;
            var json = JSON.stringify(tempGroup);
            var that = this;
            var params = {
                data: json,
                headers: that.get("ea").getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, params).then(function (data) {
                    resolve(data);
                    cache.updateObject("groups", group);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'updateGroup',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.updateGroup(' + group + ')');
        },
        deleteGroup: function deleteGroup(id) {
            var that = this;
            var cache = this.get("cache");
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(_environment.default.backend.baseURL + '/authgroups/' + id, {
                    headers: that.get("ea").getDefaultsHeaders()
                }).then(function (data) {
                    resolve(data);
                    cache.deleteObject("groups", id);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'deleteGroup',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.deleteGroup(' + id + ')');
        },

        // GROUPS
        getGroupUsers: function getGroupUsers() {
            var that = this;
            var ea = this.get("ea");
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(_environment.default.backend.baseURL + '/authgroupaddusers', {
                    headers: ea.getDefaultsHeaders()
                }).then(function (data) {
                    resolve(data.resource);
                }).catch(function (error) {
                    var reason = {
                        service: 'auth',
                        method: 'getGroupUsers',
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, 'auth.getGroupUsers()');
        },

        addUserToGroup: function addUserToGroup(user, group) {
            var tempGroup = JSON.parse(JSON.stringify(group));
            delete user["isDraggingObject"];
            delete tempGroup["users"];
            delete tempGroup["permissions"];
            delete tempGroup["roles"];
            delete tempGroup["subgroups"];
            delete tempGroup["filteredPermissions"];
            delete tempGroup["linkId"];

            delete group["roles"];
            var cid = this.get("session").get("client_id");
            if (cid === undefined) {
                alert("CID WIRD IM AUTH Service NOCH MANUEL AUF 1 GESETZT");
                cid = 1;
            }
            var URL = _environment.default.backend.baseURL + '/authgroupaddusers';
            var nutzlast = {
                cid: cid,
                authUser: user,
                authGroup: tempGroup
            };
            var json = JSON.stringify(nutzlast);
            var that = this;
            var params = {
                data: json,
                headers: that.get("ea").getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, params).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'addUserToGroup',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.addUserToGroup(' + user + ',' + group + ')');
        },
        removeUserFromGroup: function removeUserFromGroup(id) {
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(_environment.default.backend.baseURL + '/authgroupaddusers/' + id, {
                    headers: that.get("ea").getDefaultsHeaders()
                }).then(function (data) {
                    resolve([data]);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'deleteGroup',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, 'auth.removeUserFromGroup(' + id + ')');
        },
        addGroupToGroup: function addGroupToGroup(subgroup, group) {
            var tempGroup = JSON.parse(JSON.stringify(group));
            delete subgroup["isDraggingObject"];
            delete tempGroup["users"];
            delete tempGroup["linkId"];
            delete tempGroup["permissions"];
            delete tempGroup["roles"];
            delete tempGroup["subgroups"];
            delete tempGroup["filteredPermissions"];
            delete tempGroup["roles"];
            var cid = this.get("session").get("client_id");
            var aid = this.get("session").get("app_id");
            if (aid === undefined) {
                aid = 1;
            }
            if (cid === undefined) {
                alert("CID WIRD IM AUTH Service NOCH MANUEL AUF 1 GESETZT");
                cid = 1;
            }
            var URL = _environment.default.backend.baseURL + '/authgroupaddgroups';
            var nutzlast = {
                cid: cid,
                aid: aid,
                subGroup: subgroup,
                authGroup: tempGroup
            };
            var json = JSON.stringify(nutzlast);
            var that = this;
            var params = {
                data: json,
                headers: that.get("ea").getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, params).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'insertRole',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.addGroupToGroup(' + group + ',' + subgroup + ')');
        },
        removeGroupFromGroup: function removeGroupFromGroup(id) {
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(_environment.default.backend.baseURL + '/authgroupaddgroups/' + id, {
                    headers: that.get("ea").getDefaultsHeaders()
                }).then(function (data) {
                    resolve([data]);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'deleteGroup',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, 'auth.removeGroupFromGroup(' + id + ')');
        },
        addPermissionToEntity: function addPermissionToEntity(permission, entity, aid, type) {
            var tempEntity = JSON.parse(JSON.stringify(entity));
            delete tempEntity["users"];
            delete tempEntity["permissions"];
            delete tempEntity["filteredPermissions"];
            delete tempEntity["roles"];
            delete tempEntity["subgroups"];
            delete tempEntity["linkId"];

            var URL = "";
            var cid = this.get("session").get("client_id");
            if (cid === undefined) {
                alert("CID WIRD IM AUTH Service NOCH MANUEL AUF 1 GESETZT");
                cid = 1;
            }
            if (aid === undefined) {
                aid = 0;
            }
            var nutzlast = {
                aid: aid,
                cid: cid,
                authPermission: permission
            };
            switch (type) {
                case "group":
                    URL = _environment.default.backend.baseURL + '/authgroupgrantpermissions';
                    nutzlast.authGroup = tempEntity;
                    break;
                case "user":
                    URL = _environment.default.backend.baseURL + '/authusergrantpermissions';
                    nutzlast.authUser = tempEntity;
                    break;
                case "role":
                    URL = _environment.default.backend.baseURL + '/authroleaddpermissions';
                    nutzlast.authRole = tempEntity;
                    break;
            }

            var json = JSON.stringify(nutzlast);
            var that = this;
            var params = {
                data: json,
                headers: that.get("ea").getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, params).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'insertUser',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.addPermissionToEntity(' + permission + ',' + entity + ', ' + aid + ', ' + type + ')');
        },
        removePermissionFromEntity: function removePermissionFromEntity(id, type) {
            var that = this;
            var URL = null;
            switch (type) {
                case "group":
                    URL = _environment.default.backend.baseURL + '/authgroupgrantpermissions/' + id;
                    break;
                case "user":
                    URL = _environment.default.backend.baseURL + '/authusergrantpermissions/' + id;
                case "role":
                    URL = _environment.default.backend.baseURL + '/authroleaddpermissions/' + id;
            };

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, {
                    headers: that.get("ea").getDefaultsHeaders()
                }).then(function (data) {

                    resolve(data);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'insertUser',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.removePermissionFromEntity()');
        },
        insertPermission: function insertPermission(resourceId, resourceClass, aid, flags) {
            var URL = _environment.default.backend.baseURL + '/authpermissions';
            var cid = this.get("session").get("client_id");
            if (cid === undefined) {
                alert("CID WIRD IM AUTH Service NOCH MANUEL AUF 1 GESETZT");
                cid = 1;
            }
            if (flags === undefined) {
                flags = 3;
            }
            var nutzlast = {
                aid: aid,
                cid: cid,
                flags: flags,
                resourceId: resourceId,
                resourceClass: resourceClass
            };
            var json = JSON.stringify(nutzlast);
            var that = this;
            var params = {
                data: json,
                headers: that.get("ea").getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, params).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'insertUser',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.insertPersmission(' + resourceId + ',' + resourceClass + ',' + aid + ')');
        },
        updatePermission: function updatePermission(permission) {
            delete permission["groupGrantPermissionId"];
            delete permission["resourceName"];
            delete permission["access"];
            delete permission["test"];

            var URL = _environment.default.backend.baseURL + '/authpermissions/' + permission.id;

            var json = JSON.stringify(permission);
            var that = this;
            var params = {
                data: json,
                headers: that.get("ea").getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, params).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'insertUser',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.updatePermission(' + permission + ')');
        },
        deletePermission: function deletePermission(id) {
            var URL = _environment.default.backend.baseURL + '/authpermissions/' + id;
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, {
                    headers: that.get("ea").getDefaultsHeaders()
                }).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = [{
                        service: 'auth',
                        method: 'insertUser',
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'auth.deletePermission(' + id + ')');
        }
    });
});