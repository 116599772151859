define("extraapps/sandbox/controller", ["exports", "extraapps/util/promiseObject", "extraapps/config/environment"], function (exports, _promiseObject, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        ea: Ember.inject.service(),

        baseUrl: _environment.default.backend.baseURL + "/doc/",
        options: {
            uploadMultiple: true
        },

        points: [{
            coordinates: [0, 0]
        }, {
            coordinates: [2, 2]
        }],

        init: function init() {
            this._super();
            var header = this.get("ea").getDefaultHeaderFiles();
            this.set("headers", header);
        },


        actions: {
            saveFiles: function saveFiles() {
                var dropzone = this.get("dropzone");
                dropzone.processQueue();
            },
            addedFile: function addedFile() {
                this._super.apply(this, arguments);
                this.set('dropzone', Dropzone.forElement('#assetUpload'));
            },
            successmultiple: function successmultiple(response) {
                console.log(response);
                var type = this.get("type");
                var cid = this.get("session").get("client_id");
                var aid = this.get("session").get("app_id");
                var docs = this.get("model").images;
                var icons = this.get("model").icons;
                response = JSON.parse(response.xhr.response);
                response = response.resource[0];
                var that = this;
                var meta = {};
                var record = this.get("record");
                if (record.title === undefined || record.title === null) {
                    record.title = "";
                }
                meta.title = record.title.replace(/\s/g, "_");
                meta.id = response.id;
                meta.cid = cid;
                meta.aid = aid;
                meta.description = record.description;
                if (type === "icons") {
                    meta.fileName = cid + "_" + aid + "_mapIcon_" + meta.title;
                }

                return this.get("docs").updateDocMeta(meta).then(function (newMeta) {
                    that.dropzone.removeAllFiles();
                    that.set("record", {});
                    that.set("new", false);
                    that.get('notifications').success("Datei gespeichert", {
                        autoClear: true
                    });
                    that.set("new", null);
                    if (type === "icons") {
                        icons.addObject(newMeta[0]);
                    } else {
                        docs.addObject(newMeta[0]);
                    }
                });
            }
            /*
            
            
             ajax:Ember.inject.service(),
             exvalidator: Ember.inject.service(),
            gc: Ember.inject.service(),
            notifications: Ember.inject.service('notification-messages'),
            
               geoJSON: {type: "Point", coordinates:[0,0]},
            test:function (ms) {
                this.get("notifications").error(ms);
            },
            nodes:[
                {opacity:0.2, coordinates:[5,5]},
                {opacity:0.2, coordinates:[0,0]}
            ],
            currentPosition: [1,1],
            hideDrag: true,
            dragShape: null,
            dragShapeChanged:function () {
                let dragShape = this.get("dragShape");
                if(dragShape !== null) {this.set("hideDrag", false);}
            }.observes("dragShape").on("init"),
            actions:{
                saveSetting:function () {
                  let name = this.get("name");
                  let setting = this.get("setting");
                },
                onMousemove(e){
                    let currentPosition = [e.latlng.lng, e.latlng.lat];
                    let nodes = this.get("nodes");
                    let last = nodes[nodes.length -1 ];
                    let dragShape = {type: "LineString", coordinates:[last.coordinates, currentPosition]};
                    this.set("dragShape", dragShape);
                 },
                snap(node, e){
                    Ember.set(node, "opacity", 1);
                },
                unSnap(node,e){
                   // Ember.set(node, "opacity", 0.2);
                },
                geoCode:function (dsName) {
                    this.get("gc").geoCodeDS(dsName)
                },
                check: function (record, fields) {
                    let errors = this.get("exvalidator").validateRecord(record, fields);
                    console.log(errors);
                    if (errors.length > 0) {
                        this.set("errors", errors);
                        this.set("showErrors", true);
                    } else {
                        this.set("showErrors", false);
                    }
                }
            }*/
        } });
});