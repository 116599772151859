define('extraapps/admin/style/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        api: Ember.inject.service(),
        notifications: Ember.inject.service('notification-messages'),
        actions: {
            save: function save(css) {
                var _this = this;

                var api = this.get("api");
                api.storeSettings(css).then(function (setting) {
                    _this.get('notifications').success('Änderungen gespeichert', { // TODO: use i18n text...
                        autoClear: true
                    });
                }).catch(function (error) {});
            }
        }
    });
});