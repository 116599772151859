define("extraapps/components/ea-datamap-background/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super();
            var bgs = this.get("bgs");
            if (bgs.indexOf("esri") !== -1) {
                this.set("esri", { show: true, bg: "https://mt.google.com/vt/lyrs=y&x={x}&y={y}&z={z}" });
            }
            if (bgs.indexOf("default") !== -1) {
                this.set("map", { show: true, bg: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png" });
            }
        },

        actions: {
            setBg: function setBg(bg) {
                this.sendAction("setBg", bg);
                console.log("Background: " + bg);
            }
        }
    });
});