define("extraapps/components/route-data/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        api: Ember.inject.service(),
        showChanged: function () {
            var _this = this;

            var show = this.get("show");
            var path = this.get("path");
            path = path.replace(/\//g, "");
            if (show === true) {
                this.get("api").getSettings("setting." + path + "." + this.get("ds").name).then(function (settings) {
                    var setting = settings[0];
                    setting.setting = JSON.parse(setting.setting);
                    _this.set("setting", setting);
                });
            } else if (show === false) {
                var setting = this.get("setting");
                Ember.set(setting, "setting", JSON.stringify(setting.setting));
                this.get("api").storeSettings(setting).then(function (setting) {});
            }
        }.observes("show").on("init"),
        actions: {
            delete: function _delete(db, ds) {
                this.sendAction("delete", db, ds);
            }
        }
    });
});