define('extraapps/admin/pages/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    title: 'Admin > Seiten',
    ea: Ember.inject.service(),
    model: function model() {
      return this.get("ea").getRoutes().then(function (routes) {
        return { pages: routes };
      }).catch(function (error) {
        alert('NO Routes');
      });
    }
  });
});