define('extraapps/admin/icons/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    api: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    queryParams: ["dataset"],
    dataset: null,
    show: "marker",
    setChanged: function () {
      var _this = this;

      var dataset = this.get("dataset");
      return this.get("api").getSettings('map.layout.' + dataset).then(function (style) {
        return _this.get("api").getSettings('map.popup.' + dataset).then(function (popup) {
          if (popup.length > 0) {
            popup = popup[0];
          } else {
            popup = { name: 'map.popup.' + dataset, setting: "" };
          }
          _this.set("popup", popup);
          style = style[0];
          if (style !== undefined) {
            style.setting = JSON.parse(style.setting);
            _this.set("style", style);
          } else {
            return _this.get("api").getSettings('map.layout.default').then(function (newStyle) {
              if (newStyle !== undefined) {
                newStyle = newStyle[0];
                newStyle.setting = JSON.parse(newStyle.setting);
                _this.set("style", newStyle);
              }
            }).catch(function (error) {
              console.log(error);
              _this.get('notifications').error("Fehler beim Laden der Daten", {
                autoClear: true
              });
            });
          }
        }).catch(function (error) {
          console.log(error);
          _this.get('notifications').error("Fehler beim Laden der Daten", {
            autoClear: true
          });
        });
      }).catch(function (error) {
        console.log(error);
        _this.get('notifications').error("Fehler beim Laden der Daten", {
          autoClear: true
        });
      });
    }.observes("dataset").on("init"),
    colorChanged: function () {
      var style = this.get("style");

      if (style !== undefined && style !== null) {
        var setting = style.setting;
        var color = setting.color;
        var fillColor = setting.fillColor;
        var Ccolor = '#' + color;
        var CfillColor = '#' + fillColor;
        Ember.set(setting, "colorComplete", Ccolor);
        Ember.set(setting, "fillColorComplete", CfillColor);
      }
    }.observes("style.setting.color", "style.setting.fillColor").on("init"),
    colorViewChanged: function () {
      var setColor = this.get("setColor");
      switch (setColor) {
        case "color":
          this.set("showColor", true);
          this.set("showFillColor", false);
          this.set("showTrans", false);
          break;
        case "fillColor":
          this.set("showColor", false);
          this.set("showFillColor", true);
          this.set("showTrans", false);
          break;
        case "opacity":
          this.set("showTrans", true);
          this.set("showColor", false);
          this.set("showFillColor", false);
          break;
        case null:
          this.set("showTrans", false);
          this.set("showColor", false);
          this.set("showFillColor", false);
          break;
      }
    }.observes("setColor").on("init"),
    actions: {
      setIcon: function setIcon(icon) {
        var setting = this.get("style").setting;
        Ember.set(setting, "iconName", icon.fileName);
        Ember.set(setting, "iconId", icon.id);
      },
      setColor: function setColor(color) {
        if (color !== undefined) {
          var setting = this.get("style").setting;
          Ember.set(setting, "color", color);
        }
        this.set("showColor", false);
        this.set("setColor", null);
      },
      setFillColor: function setFillColor(fillColor) {
        if (fillColor !== undefined) {
          var setting = this.get("style").setting;
          Ember.set(setting, "fillColor", fillColor);
        }
        this.set("setColor", null);
        this.set("showFillColor", false);
      },
      selectedDataset: function selectedDataset(dataset) {
        this.set("dataset", dataset.name);
      },
      save: function save(style) {
        var _this2 = this;

        var show = this.get("show");
        if (show === "popup") {
          var popup = this.get("popup");
          this.get("api").storeSettings(popup).then(function (saved) {
            _this2.get('notifications').success("Änderungen übernommen", {
              autoClear: true
            });
          }).catch(function (error) {
            console.log(error);
            _this2.get('notifications').error("Fehler beim Laden der Daten", {
              autoClear: true
            });
          });
        } else {
          var dataset = this.get("dataset");
          style = JSON.parse(JSON.stringify(style));
          var name = style.name;
          if (name === "map.layout.default") {
            delete style["id"];
            Ember.set(style, "name", 'map.layout.' + dataset);
          }
          style.setting = JSON.stringify(style.setting);
          this.get("api").storeSettings(style).then(function (saved) {
            _this2.get('notifications').success("Änderungen übernommen", {
              autoClear: true
            });
          }).catch(function (error) {
            console.log(error);
            _this2.get('notifications').error("Fehler beim Laden der Daten", {
              autoClear: true
            });
          });
        }
      }
    }
  });
});