define('extraapps/components/ea-datamap-legende/component', ['exports', 'extraapps/util/util', 'extraapps/config/environment'], function (exports, _util, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super();
            var datasets = this.get("datasets");
            var width = 100 / datasets.length;

            this.set("width", width);
        },

        actions: {
            toggleLayer: function toggleLayer(ds) {
                this.sendAction("toggleLayer", ds);
            }
        }
    });
});