define("extraapps/components/set-icon/component", ["exports", "extraapps/util/util"], function (exports, _util) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    docs: Ember.inject.service(),
    settingChanged: function () {
      var setting = this.get("setting");
      if (setting !== undefined) {

        if ("iconId" in setting) {
          if (setting.iconId !== undefined) {
            this.set("showIcon", true);
          }
        } else {
          this.set("showIcon", false);
        }
      }
    }.observes("setting").on("init"),
    showImageChanged: function () {
      var _this = this;

      var showIcon = this.get("showIcon");
      if (showIcon === false) {
        return this.get("docs").getDocsMeta().then(function (docs) {
          docs = docs.filter(_util.filterIcons);
          _this.set("icons", docs);
        }).catch(function (error) {
          console.log(error);
        });
      }
    }.observes("showIcon").on("init"),
    actions: {
      setIcon: function setIcon(icon) {
        var setting = this.get("setting");
        Ember.set(setting, "iconId", icon.id);
        this.sendAction("setIcon", icon);
        this.set("showIcon", true);
      }
    }
  });
});