define("extraapps/admin/display/controller", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ["url"],
        url: null,
        show: true,
        displays: [{ id: 0, text: "Laptop", width: 1440, height: 900 }, { id: 1, text: "Iphone 5", width: 320, height: 568 }, { id: 2, text: "Iphone 6", width: 375, height: 667 }, { id: 3, text: "IPad ", width: 1024, height: 768 }],
        display: { id: 0, text: "Laptop", width: 1440, height: 900 },

        displayChanged: function () {
            var display = this.get("display");
            if (display !== undefined && display !== null) {
                this.set("innerWidth", display.width);
                this.set("innerHeight", display.height);

                this.set("outerHeight", display.height + 40);
                this.set("outerWidth", display.width + 40);
            }
        }.observes("display").on("init")
    });
});