define('extraapps/services/ksrnk', ['exports', 'extraapps/config/environment', 'extraapps/util/promiseObject'], function (exports, _environment, _promiseObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        ea: Ember.inject.service(),
        ajax: Ember.inject.service(),

        getGeoJSON: function getGeoJSON() {
            var ea = this.get("ea");
            var URL = _environment.default.backend.kombiviBaseURL + '/rs/api/GemeindenGeoJSON/08226';
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {

                that.get('ajax').request(URL).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'ksrnk.getGeoJSON()');
        },

        getGemeindeDaten: function getGemeindeDaten() {
            var ea = this.get("ea");
            var URL = _environment.default.backend.kombiviBaseURL + '/rs/api/Gemeinden/08226';
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {

                that.get('ajax').request(URL).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'ksrnk.getGemeindeDaten()');
        },

        getGemeinde: function getGemeinde(id) {
            var ea = this.get("ea");
            var URL = _environment.default.backend.kombiviBaseURL + '/rs/api/Gemeinde/' + id;
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {

                that.get('ajax').request(URL).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'ksrnk.getGemeinde(' + id + ')');
        },
        deleteData: function deleteData(year, theme) {
            var ea = this.get("ea");
            var URL = _environment.default.backend.kombiviBaseURL + '/rs/api/delete/regionalValues/' + year + '/' + theme;
            var that = this;
            var params = { headers: that.get('ea').getDefaultsHeaders() };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {

                that.get('ajax').request(URL).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'ksrnk.deleteData(' + year + ',' + theme + ')');
        }
    });
});