define("extraapps/components/changerequest-type/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super();
      var type = this.get("dataRow").status;
      switch (type) {
        case "REQUESTED":
          this.set("insert", true);
          break;
        case "UPDATE":
          this.set("update", true);
          break;
        case "DELETE":
          this.set("delete", true);
          break;
      }
    }
  });
});