define('extraapps/admin/pages/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        ea: Ember.inject.service(),
        notifications: Ember.inject.service('notification-messages'),
        queryParams: ["newPage"],
        newPage: false,
        columns: [{ key: "name", header: "Name", sortable: true, filter: { type: 'search' } }, { key: "pathSegment", header: "Pfad", sortable: true, filter: { type: 'search' } }, { key: "id", header: "ID", sortable: true, filter: { type: 'search' } }, { key: " ", header: " ", component: "delete-button", width: "60px" }, { key: " ", header: " ", component: "goto-button", width: "30px" }],
        sortKey: 'pathSegment',
        ascending: true,
        status: [{ id: "DRAFT", text: "Entwurf" }, { id: "REVIEW", text: "Review ausstehend" }, { id: "PUBLISHED", text: "veröffentlicht" }], //TODO: LOAD FROM SETTING;
        visibility: [{ id: "PUBLIC", text: "öffentlich" }, { id: "PRIVATE", text: "privat" }],

        paths: Ember.computed('model.pages.@each', function () {
            var pages = this.get("model.pages");
            var paths = pages.map(function (page) {
                return { id: page.id, text: page.pathSegment };
            });
            return paths;
        }),
        page: { status: "DRAFT", visibility: "PRIVATE" },
        pathChanged: function () {

            var path = this.get("path");
            if (path !== undefined) {
                var prefix = this.get("prefix").text;
                var page = this.get("page");
                if (!path.startsWith("/")) {
                    path = '/' + path;
                }
                if (prefix !== undefined) {
                    path = '' + prefix + path;
                } else {
                    console.log(path);
                }
                Ember.set(page, "pathSegment", path);
            }
        }.observes("path", "prefix").on("init"),
        actions: {
            goToPage: function goToPage(id) {
                var pages = this.get("model").pages;
                var page = pages.findBy("id", id);
                this.transitionToRoute("admin.page", page.pathSegment);
            },
            delete: function _delete(id) {
                var _this = this;

                var pages = this.get("model.pages");
                var page = pages.findBy("id", id);
                return this.get("ea").deleteRoute(id).then(function (newRoute) {
                    pages.removeObject(page);
                    _this.get('notifications').success("Seite gelöscht", {
                        autoClear: true
                    });
                }).catch(function (error) {
                    console.log(error);
                    _this.get('notifications').error("Beim löschen der Seite trat ein Fehler auf.", {
                        autoClear: true
                    });
                });
            },
            insertPage: function insertPage() {
                var _this2 = this;

                var page = this.get("page");
                page.cid = this.get("session").get("client_id");
                page.aid = this.get("session").get("app_id");
                var pages = this.get("model.pages");
                return this.get("ea").updateRoutes(page).then(function (newRoute) {
                    var template = { hbs: "", identifier: newRoute.cid + '_' + newRoute.aid + '_RouteTemplate_' + newRoute.name, cid: newRoute.cid, aid: newRoute.aid, handlebarsTemplateType: "PLAIN" };
                    return _this2.get("ea").storeTemplate(template).then(function (newTemplate) {
                        return _this2.get("ea").addTemplateToRoute(newTemplate.id, newRoute.pathSegment).then(function (newRouteTemplate) {
                            pages.addObject(newRoute);
                            _this2.set("newPage", false);
                            _this2.get('notifications').success("Seite angelegt", {
                                autoClear: true
                            });
                        }).catch(function (error) {
                            console.log(error);
                            _this2.get('notifications').error("Beim speichern der Seite trat ein Fehler auf.", {
                                autoClear: true
                            });
                        });
                    }).catch(function (error) {
                        console.log(error);
                        _this2.get('notifications').error("Beim speichern der Seite trat ein Fehler auf.", {
                            autoClear: true
                        });
                    });
                }).catch(function (error) {
                    console.log(error);
                    _this2.get('notifications').error("Beim speichern der Seite trat ein Fehler auf.", {
                        autoClear: true
                    });
                });
            }
        }

    });
});