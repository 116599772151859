define('extraapps/helpers/get-select-options', ['exports', 'fixtable-ember/helpers/get-select-options'], function (exports, _getSelectOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _getSelectOptions.default;
    }
  });
  Object.defineProperty(exports, 'getSelectOptions', {
    enumerable: true,
    get: function () {
      return _getSelectOptions.getSelectOptions;
    }
  });
});