define("extraapps/components/ds-viewer-map-draw/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            open: function open() {
                this.sendAction("open", "drawMenu");
                this.sendAction("open", "draw");
            },
            close: function close() {
                var that = this;
                this.sendAction("close", "drawMenu");
                this.sendAction("import");
                Ember.run.later(function () {
                    that.sendAction("setShape", "MultiPoint");
                }, 500);
            },
            setShape: function setShape(shape) {
                this.sendAction("setShape", shape);
            }
        },
        showChanged: function () {
            var show = this.get("show");
            var hide = !show;
            this.set("hide", hide);
            console.log(hide);
        }.observes("show").on("init")
    });
});