define("extraapps/services/relation", ["exports", "extraapps/util/promiseObject"], function (exports, _promiseObject) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        ajax: Ember.inject.service(),
        ea: Ember.inject.service(),

        createRelation: function createRelation(relation) {
            var ea = this.get("ea");
            var URL = ea.getBaseURL();
            URL = URL + "/relation/type";
            var that = this;
            var json = JSON.stringify({ "resource": [relation] });
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, { headers: ea.getDefaultsHeaders(), data: json }).then(function (data) {
                    var valueLists = data.resource[0];
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "relation.createRelation()");
        },

        removeRelation: function removeRelation(removedRelation) {
            var relation = { id: removedRelation.id };
            var ea = this.get("ea");
            var URL = ea.getBaseURL();
            URL = URL + "/relation/type";
            var json = JSON.stringify({ resource: [relation] });
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, { headers: ea.getDefaultsHeaders(), data: json }).then(function (data) {
                    var valueLists = data.resource[0];
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "relation.removeRelation()");
        },

        createRelationEntry: function createRelationEntry(relationTypId, fromId, toId, qualifier) {
            var ea = this.get("ea");
            var URL = ea.getBaseURL();
            URL = URL + "/relation";
            var data = { relationTypeId: relationTypId, fromId: fromId, toId: toId, qualifierCode: qualifier };
            var json = JSON.stringify({ resource: [data] });
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, { headers: ea.getDefaultsHeaders(), data: json }).then(function (data) {
                    var valueLists = data.resource[0];
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "relation.createRelationEntry(" + relationTypId + ", " + fromId + ", " + toId + ", " + qualifier + ")");
        },

        removeRelationEntry: function removeRelationEntry(relationTypId, fromId, toId, qualifier) {
            var ea = this.get("ea");
            var URL = ea.getBaseURL();
            URL = URL + "/relation";
            var data = { relationTypeId: relationTypId, fromId: fromId, toId: toId, qualifierCode: qualifier };
            var json = JSON.stringify({ resource: [data] });
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, { headers: ea.getDefaultsHeaders(), data: json }).then(function (data) {
                    var valueLists = data.resource[0];
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "relation.removeRelationEntry(" + relationTypId + ", " + fromId + ", " + toId + ", " + qualifier + ")");
        },

        getRelations: function getRelations() {
            var ea = this.get("ea");
            var URL = ea.getBaseURL();
            URL = URL + "/relation/type";
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, { headers: ea.getDefaultsHeaders() }).then(function (data) {
                    var valueLists = data.resource;
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "relation.getRelations()");
        },

        getRelationsFromDataset: function getRelationsFromDataset(dataset) {
            var ea = this.get("ea");
            var URL = ea.getBaseURL();
            URL = URL + "/relation/from/dataset";
            var that = this;
            var json = JSON.stringify({ resource: [dataset] });
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, { headers: ea.getDefaultsHeaders(), data: json }).then(function (data) {
                    var valueLists = data.resource;
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "relation.getRelationsFromDataset()");
        },

        getRelationsToDataset: function getRelationsToDataset(dataset) {
            var ea = this.get("ea");
            var URL = ea.getBaseURL();
            URL = URL + "/relation/to/dataset";
            var that = this;
            var json = JSON.stringify({ resource: [dataset] });
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, { headers: ea.getDefaultsHeaders(), data: json }).then(function (data) {
                    var valueLists = data.resource;
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "relation.getRelationsToDataset()");
        },

        getRelatedData: function getRelatedData(recordId, relationTypeId) {
            var ea = this.get("ea");
            var URL = ea.getBaseURL();
            URL = URL + "/relation/from/" + recordId + "/" + relationTypeId;
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, { headers: ea.getDefaultsHeaders() }).then(function (data) {
                    var valueLists = data.resource;
                    resolve(valueLists);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, "relation.getRelatedData(" + recordId + ", " + relationTypeId + ")");
        }
    });
});