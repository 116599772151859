define('extraapps/components/delete-inline-buttons/component', ['exports', 'extraapps/components/delete-inline-buttons/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    remove: false,
    actions: {
      delete: function _delete() {
        this.sendAction("delete");
        this.set("remove", false);
      }
    }
  });
});