define('extraapps/components/ds-viewer-map-by-address/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notifications: Ember.inject.service('notification-messages'),
        gc: Ember.inject.service(),
        showOuter: false,
        showInner: false,
        a: {},
        showChanged: function () {
            var that = this;
            var show = this.get("show");
            var showOuter = this.get("showOuter");
            /*if(show && showOuter){
                this.set("showInner", false);
                Ember.run.later(function () {
                    that.set("showOuter", false)
                }, 1);
            }else if(show && !(showOuter)){
                this.set("showOuter", true);
                Ember.run.later(function () {
                    that.set("showInner", true)
                }, 1);
            }*/
        }.observes("show").on("init"),
        actions: {
            close: function close() {
                this.sendAction("close", "showByAddress");
            },
            open: function open() {
                this.sendAction("open", "showByAddress");
            },
            import: function _import() {
                this.sendAction("import");
                this.set("a", {});
                this.set("showImport", false);
                this.set("show", false);
            },
            find: function find(a) {
                var _this = this;

                var that = this;
                this.get("gc").geoCodeOneAddress(a.city, a.street, a.number, a.postcode).then(function (geodata) {
                    var geoJSON = { geojson: { type: "Point", coordinates: [geodata[0].geometry.location.lng, geodata[0].geometry.location.lat] } };
                    _this.sendAction("selectPlace", geoJSON);
                    _this.set("showImport", true);
                }).catch(function (error) {
                    that.get("notifications").error("Adresse konnte nicht gedfunden werden", {
                        autoClear: true
                    });
                });
            }
        }
    });
});