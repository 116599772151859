define('extraapps/admin/record/route', ['exports', 'extraapps/util/util', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _util, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend( /*AuthenticatedRouteMixin,*/{
        ds: Ember.inject.service(),
        ea: Ember.inject.service(),
        ps: Ember.inject.service(),
        api: Ember.inject.service(),
        relation: Ember.inject.service(),
        model: function model(transition) {
            var table = transition.dataset;
            var id = transition.id;
            var ds = this.get('ds');
            var ps = this.get('ps');
            var api = this.get("api");
            if (id !== "0") {
                var relation = this.get("relation");
                return ds.getDataset(table).then(function (data) {
                    //return relation.getRelationsToDataset(data.name).then(relations => {
                    return ps.selectRecord('_external', table, id).then(function (newRecord) {
                        return api.getSettings("map.%25").then(function (styles) {
                            if (newRecord.geodaten !== undefined) {
                                var geodata = JSON.parse(newRecord.geodaten);
                                newRecord.geodata = geodata;
                            }
                            (0, _util.updateFieldMetaData)(data);
                            /*if(relations.length !== 0) {
                                Ember.set(data, "withRelation", true);
                                  //return ds.getDataset(relations[0].toDatasetName).then((relation)=> { //TODO: GET MULTIPLE
                                     var record = [{"dataset": table, "id": id, "records": [newRecord]}];
                                     var fields = data.fields;
                                    fields.sort(function (a, b) {
                                        return a.sequenceNumber - b.sequenceNumber;
                                    });
                                    let meta = {databases: [
                                        {datasets:[
                                            data
                                        ]}
                                     ]};
                                    return {meta: meta, data: record, styles: styles, id: id}
                                }).catch(error => {
                                    alert("oo0");
                                })
                            }else{ */
                            var record = [{ "name": table, "id": id, "records": [newRecord] }];

                            var fields = data.fields;
                            fields.sort(function (a, b) {
                                return a.sequenceNumber - b.sequenceNumber;
                            });
                            var meta = { databases: [{ datasets: [data] }] };
                            return { meta: meta, data: record, styles: styles /*, relations: relations*/
                                /*}
                                }).catch(error => {
                                console.log(error);
                                });*/
                            };
                        }).catch(function (error) {
                            console.log(error);
                        });
                    }).catch(function (error) {
                        console.log(error);
                    });
                }).catch(function (error) {
                    console.log(error);
                });
            } else {
                return ds.getDataset(table).then(function (data) {
                    return api.getSettings("map.%25").then(function (styles) {

                        var fields = data.fields;
                        fields.sort(function (a, b) {
                            return a.sequenceNumber - b.sequenceNumber;
                        });
                        var meta = { databases: [{ datasets: [data] }] };
                        var record = [{ "name": table, "id": id, "records": [{}] }];

                        return { meta: meta, data: record, styles: styles, isNew: true };
                    }).catch(function (error) {
                        console.log(error);
                    });
                }).catch(function (error) {
                    console.log(error);
                });
            }
        },
        actions: {
            onUpdateRecord: function onUpdateRecord(dataset) {
                this.transitionTo("admin.dataset", dataset);
            }
        }
    });
});