define('extraapps/helpers/cond', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.cond = cond;


    /**
     * {{cond leftValue rightValue trueValue falseValue}}
     * ==>
     * trueValue, if leftValue === rightValue or
     * falseValue, if leftValue !== rightValue.
     *
     * Note:
     * Wrong use: <div {{cond ...}}>
     * Correct use: <div style={{cond ...}}>
     * Thus you cannot use it in place of attributes but only in place of attribute values.
     * @param params
     * @returns {*}
     */
    function cond(params /*, hash*/) {
        var left = params[0];
        var right = params[1];
        if (left === right) {
            return Ember.String.htmlSafe(params[2]);
        }
        return Ember.String.htmlSafe(params[3]);
    }

    exports.default = Ember.Helper.helper(cond);
});