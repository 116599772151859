define('extraapps/services/fs', ['exports', 'npm:searchjs'], function (exports, _npmSearchjs) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        globalFilter: function globalFilter(data, meta, filterInput) {

            for (var a = 0; a < data.length; a++) {
                if (filterInput === undefined) {
                    var records = data[a].unfiltered.slice();
                    Ember.set(data[a], "records", records);
                } else {
                    var records = data[a].unfiltered.slice();
                    var fields = meta.databases[0].datasets[a].fields;

                    var filterTerms = [];
                    for (var i = 0; i < fields.length; i++) {
                        var field = fields[i];
                        var obj = {};
                        obj[field.path] = filterInput;
                        filterTerms.addObject(obj);
                    }

                    var filter = { _join: "OR", terms: filterTerms };
                    _npmSearchjs.default.setDefaults({ text: true });
                    var result = _npmSearchjs.default.matchArray(records, filter, true);
                    Ember.set(data[a], "records", result);
                }
            }
            return data;
        }
    });
});