define('extraapps/services/ps', ['exports', 'extraapps/util/promiseObject', 'extraapps/config/environment'], function (exports, _promiseObject, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        ajax: Ember.inject.service(),
        ea: Ember.inject.service(),
        config: null,
        psURL: null,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('config', _environment.default.backend);
            this.psURL = this.get('ea').getServiceURL('' + this.config.persistence_service_url_part);
        },


        datasetURL: function datasetURL(database, dataset) {
            return this.psURL + '/' + database + '/' + dataset;
        },

        /**
         *
         * @param database
         * @param dataset
         * @param record *single* record (JSON object not wrapped into an array)
         * @returns {*}
         */
        insertRecord: function insertRecord(database, dataset, record) {
            var recordArray = [record];
            return this.insertRecords(database, dataset, recordArray);
        },


        /**
         * Insert records
         * @param database currently only _external
         * @param dataset dataset name
         * @param records *array* of records fitting into dataset
         * @returns {*}
         */
        insertRecords: function insertRecords(database, dataset, records) {
            var URL = this.datasetURL(database, dataset) + '?fields=*';
            var nutzlast = {};
            nutzlast.resource = [records];
            var json = JSON.stringify(nutzlast);
            var that = this;
            var params = {
                data: json,
                headers: this.get('ea').getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, params).then(function (data) {
                    var newRecords = data.resource;
                    resolve(newRecords);
                }).catch(function (error) {
                    var reason = [{
                        service: 'ps',
                        method: 'insertRecords',
                        args: {
                            database: database,
                            dataset: dataset,
                            records: json
                        },
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, 'ps.insertRecords(' + database + ', ' + dataset + ', ' + json + ')');
        },

        /**
         *
         * @param database
         * @param dataset
         * @param record the *single* record to be stored
         * @returns {*}
         */
        updateRecord: function updateRecord(database, dataset, record) {
            delete record["geodata"];
            var URL = this.datasetURL(database, dataset);
            var nutzlast = {};
            nutzlast.resource = [record];
            var json = JSON.stringify(nutzlast);
            var that = this;
            var params = {
                data: json,
                headers: that.get('ea').getDefaultsHeaders()
            };

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, params).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'ps.updateRecord()');
        },

        deleteRecord: function deleteRecord(database, dataset, recordID) {
            var baseURl = this.datasetURL(database, dataset);
            var URL = baseURl + '/' + recordID;
            var that = this;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, { headers: that.get('ea').getDefaultsHeaders() }).then(function (data) {
                    resolve(data.resource);
                }).catch(function (error) {
                    var reason = {
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, 'ea.deleteRecord(' + recordID + ')');
        },

        /**
         * Select records.
         * @param database currently only _external
         * @param dataset dataset name
         * @param parameters hash with any of these: ids, filter, fields, limit, offset, order
         * @returns {*}
         */
        selectRecords: function selectRecords(database, dataset, parameters, headerParameters) {
            var URL = this.datasetURL(database, dataset);
            URL = this.get('ea').appendQueryParameters(URL, parameters);
            var that = this;
            var params = {
                headers: this.get('ea').getDefaultsHeaders()
            };
            Ember.assign(params.headers, headerParameters);
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, params).then(function (data) {
                    var newRecords = data.resource;

                    resolve(newRecords);
                }).catch(function (error) {
                    var reason = [{
                        service: 'ps',
                        method: 'selectRecords',
                        args: {
                            database: database,
                            dataset: dataset,
                            parameters: JSON.stringify(parameters)
                        },
                        error: error
                    }];
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, 'ps.selectRecords(' + database + ', ' + dataset + ', ' + JSON.stringify(parameters) + ')');
        },

        selectRecord: function selectRecord(database, dataset, record) {
            var URL = _environment.default.backend.baseURL + '/ps/' + database + '/' + dataset + '/' + record;
            var that = this;
            var params = {
                headers: this.get('ea').getDefaultsHeaders()
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, params).then(function (record) {

                    resolve(record);
                }).catch(function (error) {
                    /* let reason = [{
                     service: 'ps',
                     method: 'selectRecord',
                     args: {
                     database: database,
                     dataset: dataset,
                     record: record
                     },
                     error: error
                     }];*/
                    reject(error);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'ps.selectRecord(' + database + ', ' + dataset + ', ' + record + ')');
        }
    });
});