define('extraapps/admin/page/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        ea: Ember.inject.service(),
        api: Ember.inject.service(),

        ds: Ember.inject.service(),
        notifications: Ember.inject.service('notification-messages'),
        deletedDatasets: [],
        insertedDatasets: [],
        tempsChanged: function () {
            var _this = this;

            var temps = this.get("temps");
            if (temps) {
                var templates = this.get("templates");
                if (templates === undefined) {
                    return this.get("ea").getTemplates().then(function (templates) {
                        var temps = [];
                        templates.forEach(function (template) {
                            if (template.handlebarsTemplateType === "LIST") {
                                Ember.set(template, "text", template.identifier);
                                temps.addObject(template);
                            }
                        });
                        _this.set("templates", temps);

                        _this.set("showTemplates", true);
                    }).catch(function (error) {
                        var message = 'Fehler beim Laden der Daten: ' + JSON.stringify(error);
                        _this.get('notifications').error(message, {
                            autoClear: true
                        });
                    });
                } else {
                    this.set("showTemplates", true);
                }
            } else {
                this.set("showTemplates", false);
            }
        }.observes("temps").on("init"),
        showDatasetsChanged: function () {
            var _this2 = this;

            var showDatasets = this.get("showDatasets");
            if (showDatasets) {

                var currentDs = this.get("model.meta.databases")[0].datasets;
                var datasets = this.get("datasets");
                if (datasets === undefined) {
                    return this.get("ds").getDatasets().then(function (datasets) {
                        var filtered = [];
                        datasets.forEach(function (ds) {
                            var name = ds.name;
                            var current = currentDs.findBy("name", name);
                            if (current === undefined) {
                                Ember.set(ds, "text", ds.name);
                                filtered.addObject(ds);
                            }
                            filtered.sort(function (a, b) {
                                if (a.text < b.text) {
                                    return -1;
                                }
                                if (a.text > b.text) {
                                    return 1;
                                }
                                return 0;
                            });
                        });
                        _this2.set("datasets", filtered);
                    }).catch(function (error) {
                        var message = 'Fehler beim Laden der Daten';
                        that.get('notifications').error(message, {
                            autoClear: true
                        });
                    });
                }
            }
        }.observes("showDatasets").on("init"),
        actions: {
            updateRouteData: function updateRouteData() {},
            deleteRouteData: function deleteRouteData(db, ds) {
                var deletedDatasets = this.get("deletedDatasets");
                var datasets = db.datasets;
                datasets.removeObject(ds);
                deletedDatasets.addObject({ name: ds.name });
            },
            addDataset: function addDataset(ds, filter, link) {
                var insertedDatasets = this.get("insertedDatasets");
                var db = this.get("model.meta.databases")[0];
                var datasets = db.datasets;
                datasets.addObject(ds);
                insertedDatasets.addObject({ name: ds.name, filter: filter, link: link });
            },
            saveDatasets: function saveDatasets() {
                var _this3 = this;

                var deletedDatasets = this.get("deletedDatasets");
                var insertedDatasets = this.get("insertedDatasets");
                var path = this.get("model.meta.pathSegment");
                deletedDatasets.forEach(function (ds) {
                    _this3.get("ea").removeDatasetFromRoute(ds.name, path).then(function (removed) {
                        path = path.replace(/\//g, "");
                        _this3.get("api").getSettings('setting.' + path + '.' + ds.name).then(function (settingForRemoved) {
                            console.log(settingForRemoved[0]);
                            _this3.get("api").removeSettings(settingForRemoved[0]).then(function (removedSetting) {
                                deletedDatasets.removeObject(ds);
                            }).catch(function (error) {
                                console.log(error);
                            });
                        }).catch(function (error) {
                            console.log(error);
                        });
                    }).catch(function (error) {
                        console.log(error);
                    });
                });
                insertedDatasets.forEach(function (ds) {
                    var path = _this3.get("model.meta.pathSegment");
                    _this3.get("ea").addDatasetToRoute(ds.name, path, ds.filter).then(function (inserted) {
                        path = path.replace(/\//g, "");
                        var setting = {};
                        setting.name = 'setting.' + path + '.' + ds.name;
                        setting.setting = { link: ds.link };
                        setting.setting = JSON.stringify(setting.setting);
                        _this3.get("api").storeSettings(setting).then(function (setting) {
                            insertedDatasets.removeObject(ds);
                        }).catch(function (error) {
                            console.log(error);
                        });
                    }).catch(function (error) {
                        console.log(error);
                    });
                });
                this.get('notifications').success("Datasets gespeichert", {
                    autoClear: true
                });
            },
            showPage: function showPage() {
                var path = this.get("model.meta.pathSegment");
                var url = window.location.href.split("/admin");
                var newURL = '' + url[0] + path;
                var display = url[0] + '/admin/display?url=' + newURL;
                window.open(display, '_blank');
            },
            useTemplate: function useTemplate() {
                var newTemp = JSON.parse(JSON.stringify(this.get("template.hbs")));
                var template = this.get("model.meta.handlebarsTemplates")[0];
                Ember.set(template, "hbs", newTemp);
                this.set("showTemplates", false);
            }
        }
    });
});