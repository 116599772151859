define("extraapps/components/delete-button/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {

      delete: function _delete(id) {
        this.bubbleAction("delete", id);
      }
    }
  });
});