define("extraapps/components/button-geocode/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            geoCode: function geoCode(data) {
                this.bubbleAction("geoCode", data.name);
            }
        }
    });
});