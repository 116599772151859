define("extraapps/services/session", ["exports", "ember-simple-auth/services/session", "extraapps/config/environment"], function (exports, _session, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _session.default.extend({
        ea: Ember.inject.service(),
        /*init(){
            this._super();
            var session = this.get('session');
            var client_id = this.getClientID();
            var app_id = this.getAppID();
            if( app_id === undefined){
                alert("app unknown"); //TODO: sprachabhägige Fehlermeldung
            }else if (client_id === undefined){
                alert("client unknown"); //TODO: sprachabhägige Fehlermeldung
            }else{
                Ember.set(session, "client_id", client_id);
                Ember.set(session, "app_id", app_id);
            }
            alert(JSON.stringify(session));
        },*/

        getClientID: function getClientID() {
            var URL = this.getURL();

            var URL = _environment.default.backend.baseURL + "/ea/client/" + URL[0];
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL).then(function (clientID) {
                    resolve(clientID);
                }).catch(function (error) {
                    var reason = {
                        service: 'ea',
                        method: 'getClientID',
                        error: error
                    };
                    reject(reason);
                });
            });
            return promiseObject(promise, "session.getClientID()");
        },

        getAppID: function getAppID() {
            var URL = this.getURL();

            var URL = _environment.default.backend.baseURL + "/ea/app/" + URL[1];
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL).then(function (appID) {
                    resolve(clientID);
                }).catch(function (error) {
                    var reason = {
                        service: 'ea',
                        method: 'getClientID',
                        error: error
                    };
                    reject(reason);
                });
            });
            return promiseObject(promise, "session.getAppID()");
        },

        getURL: function getURL() {
            var ea = this.get('ea');
            ea.getShortUrl().then(function (url) {
                return url;
            });
        }

    });
});