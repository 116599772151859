define("extraapps/components/ksrnk-energiebedarf/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super();
            var neu = this.get("neu");
            var alt = this.get("alt");
            if (alt !== null && alt !== undefined) {
                if (alt === "0") {
                    this.set("isOld", false);
                } else {
                    this.set("isOld", true);
                }
            }
            var left_new = neu / 5;
            var left_old = alt / 5 - left_new;
            this.set("left_new", left_new);
            this.set("left_old", left_old);
        }
    });
});