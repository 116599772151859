define("extraapps/components/b2b-form/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        api: Ember.inject.service(),
        anreden: [{ id: "Frau", text: "Frau" }, { id: "Herr", text: "Herr" }],
        r: {},
        show: true,
        init: function init() {
            this._super();
            var hideForm = this.get("hideForm");
            if (hideForm === true) {
                this.set("show", false);
            } else {
                this.set("hideBtn", true);
            }
        },

        actions: {
            send: function send() {
                var id = this.get("record");
                var ds = this.get("ds");
                var record = this.get("r");
                if (!("anrede" in record)) {
                    this.set("errorMessage", "Bitte wählen Sie eine Anrede aus");
                    this.set("error", true);
                } else if (!("vorname" in record)) {
                    this.set("errorMessage", "Bitte geben Sie Ihren Vornamen an");
                    this.set("error", true);
                } else if (!("nachname" in record)) {
                    this.set("errorMessage", "Bitte geben Sie Ihren Nachnamen an");
                    this.set("error", true);
                } else if (!("mail" in record)) {
                    this.set("errorMessage", "Bitte geben Sie Ihren Mailadresse an");

                    this.set("error", true);
                } else {
                    if (!this.validateEmail(record.mail)) {
                        this.set("errorMessage", "Bitte geben Sie eine gültige Mailadresse an");

                        this.set("error", true);
                    } else {
                        this.get("api").event("bzbi", record).then(function (data) {
                            alert("YES");
                        }).catch(function (error) {
                            console.log(error);
                            alert("NO");
                        });
                    }
                }
            }
        },
        validateEmail: function validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }

    });
});