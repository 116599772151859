define("extraapps/components/structure-field/component", ["exports", "extraapps/util/util"], function (exports, _util) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        types: (0, _util.fieldTypes)(),
        uoms: (0, _util.uoms)(),
        init: function init() {
            this._super();
            var field = this.get("field");
            this.set("lazyRef", field.lazyLoad);
            this.set("confRef", field.confidential);
            this.set("labelRef", field.label);
            this.set("placeholderRef", field.placeholder);
            this.set("help_textRef", field.help_text);
            this.set("uomRef", field.uom);
            this.set("enumRef", field.enumType);
        },


        uomChanged: function () {
            var value = this.get("field.uom");
            var uomRef = this.get("uomRef");
            if (value !== uomRef) {
                this.set("uomRef", value);
                this.updateField();
            }
        }.observes("field.uom").on("didRender"),

        enumTypeChanged: function () {
            var value = this.get("field.enumType");
            var enumRef = this.get("enumRef");
            if (value !== enumRef) {
                this.set("enumRef", value);
                this.updateField();
            }
        }.observes("field.enumType").on("didRender"),

        lazyLoadChanged: function () {
            var value = this.get("field.lazyLoad");
            var lazyRef = this.get("lazyRef");
            if (value !== lazyRef) {
                this.set("lazyRef", value);
                this.updateField();
            }
        }.observes("field.lazyLoad").on("didRender"),

        placeholderChanged: function () {
            var value = this.get("field.placeholder");
            var placeholderRef = this.get("placeholderRef");
            if (value !== placeholderRef) {
                this.set("placeholderRef", value);
                this.updateField();
            }
        }.observes("field.placeholder").on("init"),

        help_textChanged: function () {
            var value = this.get("field.help_text");
            var help_textRef = this.get("help_textRef");
            if (value !== help_textRef) {
                this.set("help_textRef", value);
                this.updateField();
            }
        }.observes("field.help_text").on("init"),

        confidantialChanged: function () {
            var value = this.get("field.confidential");
            var confRef = this.get("confRef");
            if (value !== confRef) {
                this.set("confRef", value);
                this.updateField();
            }
        }.observes("field.confidential").on("didRender"),

        updateField: function updateField() {
            var field = this.get("field");
            this.sendAction("updateField", field);
        },

        actions: {
            selectField: function selectField(field) {
                this.sendAction("selectField", field);
            },
            checkLabel: function checkLabel(label) {

                var ref = this.get("labelRef");
                if (label !== ref) {
                    this.set("labelRef", label);
                    this.updateField();
                }
            },
            deleteField: function deleteField(field) {
                this.sendAction("deleteField", field);
            }
        }
    });
});