define("extraapps/components/record-delete/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            remove: function remove() {
                this.sendAction("remove");
                this.set("delete", false);
            }
        }
    });
});