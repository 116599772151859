define("extraapps/login/controller", ["exports", "extraapps/util/util", "extraapps/config/environment"], function (exports, _util, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        cid: null,
        init: function init() {
            if ((0, _util.isNotEmpty)(_environment.default.singleCid)) {
                this.set("cid", _environment.default.singleCid);
            } else {
                //TODO: GET LIST OF CLIENTS CREATE SELECT ELEMENT
            }
        },

        actions: {
            authenticate: function authenticate() {
                var _this = this;

                var _getProperties = this.getProperties('identification', 'password'),
                    identification = _getProperties.identification,
                    password = _getProperties.password;

                var session = this.get('session');
                session.authenticate('authenticator:ea', identification, password).then(function () {
                    _this.set("successMessage", true);
                    _this.transitionToRoute("admin");
                }).catch(function (reason) {
                    session.token = null;
                    _this.set('errorMessage', true);
                });
            },
            setTest: function setTest() {
                var session = this.get("session");
                console.log(session.session);
            }
        }
    });
});