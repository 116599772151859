define("extraapps/components/ds-viewer-map-record/component", ["exports", "extraapps/util/util"], function (exports, _util) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        fieldsChanged: function () {
            var data = this.get("data");
            var fields = this.get("fields");

            if (fields !== null && fields !== undefined) {
                data = (0, _util.clone)(data);
                fields = (0, _util.clone)(fields);
                fields.forEach(function (field) {
                    if (field.path === "owner") {
                        fields.removeObject(field);
                    } else if (field.logical_type === "GEOMETRY") {
                        fields.removeObject(field);
                    } else if (field.logical_type === "IMAGE") {
                        fields.removeObject(field);
                    }
                });
                this.set("cleanFields", fields);
                this.set("record", data);
            }
        }.observes("fields").on("init"),
        actions: {
            update: function update(path, e) {
                this.sendAction("update", path, e.target.value);
                console.log(e.target.value);
            }
        }
    });
});