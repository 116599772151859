define('extraapps/admin/templates/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        ea: Ember.inject.service(),
        session: Ember.inject.service(),
        notifications: Ember.inject.service('notification-messages'),

        model: function model() {
            var _this = this;

            return this.get("ea").getTemplates().then(function (templates) {
                var temps = [];
                templates.forEach(function (template) {
                    if (template.handlebarsTemplateType === "LIST") {
                        Ember.set(template, "text", template.identifier);
                        temps.addObject(template);
                    }
                });
                return {
                    templates: temps
                };
            }).catch(function (error) {
                var message = 'Fehler beim Laden der Daten: ' + JSON.stringify(error);
                _this.get('notifications').error(message, {
                    autoClear: true
                });
            });
        }

    });
});