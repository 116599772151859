define("extraapps/components/app-select/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    appChanged: function () {
      var app = this.get("app");
      var apps = this.get("apps");
      if (app !== undefined && app !== null) {
        app = apps.findBy("title", app);
        this.get("session").set("app_id", app.id);
        this.get("session").set("client_id", app.cid);
      }
    }.observes("app").on("init"),
    actions: {
      setApp: function setApp(app) {
        this.sendAction("updated", app.title);
      }
    }
  });
});