define('extraapps/r1/r2/r3/r4/r5/controller', ['exports', 'ember-parachute'], function (exports, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.myQueryParams = undefined;
  var myQueryParams = exports.myQueryParams = new _emberParachute.default({
    view: {
      as: 'v5',
      defaultValue: null
    }
  });

  exports.default = Ember.Controller.extend(myQueryParams.Mixin, {
    queryParamsChanged: Ember.computed.or('queryParamsState.{r1view}.changed'),
    setup: function setup(_ref) {
      var queryParams = _ref.queryParams;

      this.fetchData(queryParams);
    },
    reset: function reset(_ref2, isExiting) {
      var queryParams = _ref2.queryParams;

      if (isExiting) {
        this.resetQueryParams();
      }
    }
  });
});