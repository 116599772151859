define("extraapps/components/goto-ds-button/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            goTo: function goTo(id, layout) {
                this.bubbleAction("goTo", id, layout);
            }
        }
    });
});