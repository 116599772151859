define('extraapps/r1/controller', ['exports', 'ember-parachute'], function (exports, _emberParachute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryPrams: ["id", "layout"],
    layout: [null],
    id: [null]
  });
});