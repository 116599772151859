define("extraapps/components/ea-datalist/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            var _this = this;

            this._super();
            var model = this.get("model");
            var datasets = model.meta.databases[0].datasets;

            var lists = [];
            var path = model.meta.pathSegment.replace(/\//g, "");
            datasets.forEach(function (dataset) {
                console.log(dataset.title);
                var data = model.data.findBy("dataset", dataset.name);
                if (data !== undefined) {
                    data = data.records;
                }
                var list = {};
                var fields = dataset.fields;
                fields.forEach(function (field) {
                    if (field.confidential || field.lazyLoad) {
                        fields.removeObject(field);
                    }
                });
                list.name = dataset.name;
                list.path = path;
                list.title = dataset.title;
                list.fields = fields;
                list.records = data;
                lists.addObject(list);
                _this.set("lists", lists);
            });
            if (lists.length < 2) {
                this.set("hideTabs", true);
            }
        },

        actions: {
            goTo: function goTo(dsName, record) {}
        }
    });
});