define("extraapps/util/util", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.clone = clone;
    exports.filterAssets = filterAssets;
    exports.filterIcons = filterIcons;
    exports.flipJSON = flipJSON;
    exports.isEmpty = isEmpty;
    exports.isNotEmpty = isNotEmpty;
    exports.isNumeric = isNumeric;
    exports.isNonZero = isNonZero;
    exports.getURLWithoutQueryParameters = getURLWithoutQueryParameters;
    exports.cleanRouteMeta = cleanRouteMeta;
    exports.setFieldTypes = setFieldTypes;
    exports.setText = setText;
    exports.fields = fields;
    exports.sortPermissions = sortPermissions;
    exports.filterAppId = filterAppId;
    exports.filterAppIdandZero = filterAppIdandZero;
    exports.filterByVisibilty = filterByVisibilty;
    exports.fieldTypes = fieldTypes;
    exports.textFieldTypes = textFieldTypes;
    exports.getBounds = getBounds;
    exports.uoms = uoms;
    exports.updateGeometry = updateGeometry;
    exports.getInt = getInt;
    exports.updateFieldMetaData = updateFieldMetaData;

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    /* global Ember */
    function clone(src) {
        function mixin(dest, source, copyFunc) {
            var name = void 0,
                s = void 0,
                i = void 0,
                empty = {};
            for (name in source) {
                // the (!(name in empty) || empty[name] !== s) condition avoids copying properties in "source"
                // inherited from Object.prototype.	 For example, if dest has a custom toString() method,
                // don't overwrite it with the toString() method that source inherited from Object.prototype
                s = source[name];
                if (!(name in dest) || dest[name] !== s && (!(name in empty) || empty[name] !== s)) {
                    dest[name] = copyFunc ? copyFunc(s) : s;
                }
            }
            return dest;
        }

        if (!src || (typeof src === "undefined" ? "undefined" : _typeof(src)) !== "object" || Object.prototype.toString.call(src) === "[object Function]") {
            // null, undefined, any non-object, or function
            return src; // anything
        }
        if (src.nodeType && "cloneNode" in src) {
            // DOM Node
            return src.cloneNode(true); // Node
        }
        if (src instanceof Date) {
            // Date
            return new Date(src.getTime()); // Date
        }
        if (src instanceof RegExp) {
            // RegExp
            return new RegExp(src); // RegExp
        }
        var r = void 0,
            i = void 0,
            l = void 0;
        if (src instanceof Array) {
            // array
            r = [];
            for (i = 0, l = src.length; i < l; ++i) {
                if (i in src) {
                    r.push(clone(src[i]));
                }
            }
            // we don't clone functions for performance reasons
            //		}else if(d.isFunction(src)){
            //			// function
            //			r = function(){ return src.apply(this, arguments); };
        } else {
            // generic objects
            r = src.constructor ? new src.constructor() : {};
        }
        return mixin(r, src, clone);
    }
    function filterAssets(element, index, array) {
        if (element !== null) {
            if (element.title !== null) {
                if (element.title.indexOf("mapIcon") === -1 && element.title.indexOf("mapBackground") === -1) {
                    return element;
                }
            } else {
                return element;
            }
        }
    }

    function filterIcons(element, index, array) {
        if (element !== null) {
            if (element.fileName !== null) {
                if (element.fileName.indexOf("mapIcon") !== -1) {
                    return element;
                }
            }
        }
    }

    function flipJSON(geojson) {
        geojson = clone(geojson);
        if (geojson.type === "Point") {
            geojson.coordinates = [geojson.coordinates[1], geojson.coordinates[0]];
            return geojson;
        } else {
            return geojson;
        }
    }

    function isEmpty(str) {
        return !str || 0 === str.length;
    }

    function isNotEmpty(str) {
        return !isEmpty(str);
    }

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    function isNonZero(n) {
        return isNumeric(n) && n !== 0;
    }

    function getURLWithoutQueryParameters(url) {
        if (isEmpty(url)) {
            return '';
        }
        var parts = url.split('?'); // @todo check escape required?
        return parts[0];
    }

    function cleanRouteMeta(data) {
        for (var a = 0; a < data.databases.length; a++) {
            var database = data.databases[a];
            if (database.datasets.length !== 0) {
                for (var b = 0; b < database.datasets.length; b++) {
                    var dataset = database.datasets[b];
                    dataset.fields.sort(function (field1, field2) {
                        return field1.sequenceNumber - field2.sequenceNumber;
                    });
                    for (var c = 0; c < dataset.fields.length; c++) {
                        var field = dataset.fields[c];
                        var logical_type = field.logical_type;
                        switch (logical_type) {
                            case "END":
                                Ember.set(field, "isDateTime", true);
                                break;
                            case "BEGIN":
                                Ember.set(field, "isDateTime", true);
                                break;
                            case "ID":
                                Ember.set(field, "isID", true);
                                break;
                        }
                        switch (field.path) {
                            case "owner":
                                Ember.set(field, "isOwner", true);
                                break;
                        }
                    }
                }
            }
        }
        for (var _a = 0; _a < data.relatedDatasets.length; _a++) {
            var related = data.relatedDatasets[_a];
            var _fields = related.fields;
            _fields.sort(function (field1, field2) {
                return field1.sequenceNumber - field2.sequenceNumber;
            });
            for (var _b = 0; _b < _fields.length; _b++) {
                var _field = _fields[_b];
                var _logical_type = _field.logical_type;
                switch (_logical_type) {
                    case "END":
                        Ember.set(_field, "isDateTime", true);
                        break;
                    case "BEGIN":
                        Ember.set(_field, "isDateTime", true);
                        break;
                    case "ID":
                        Ember.set(_field, "isID", true);
                        break;
                }
            }
        }
        return data;
    }

    function setFieldTypes(fields) {
        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];
            var logical_type = field.logical_type;
            switch (logical_type) {
                case "END":
                    Ember.set(field, "isDateTime", true);
                    break;
                case "BEGIN":
                    Ember.set(field, "isDateTime", true);
                    break;
            }
        }
        return fields;
    }

    function setText(array, key) {
        for (var i = 0; i < array.length; i++) {
            var obj = array[i];
            Ember.set(obj, "text", obj[key]);
        }
        return array;
    }

    function fields() {
        return [// TODO i18n
        { id: "IMAGE", text: "Bild", label: "Bild" }, { id: "DATE", text: "Datum", label: "Datum" }, { id: "BEGIN", text: "Anfangsdatum", label: "Anfang" }, { id: "END", text: "Enddatum", label: "Ende" }, { id: "GEOMETRY", text: "Geodaten", label: "Geodaten" }, { id: "LONGTEXT", text: "Langtext", label: "Beschrbeibung" }, { id: "TIME", text: "Zeit", label: "Uhrzeit" }, { id: "STREET", text: "Straße", label: "Straße" }, { id: "HOUSE_NUMBER", text: "Hausnummer", label: "Hausnummer" }, { id: "POSTCODE", text: "PLZ", label: "PLZ" }, { id: "CITY", text: "Ort", label: "Ort" }, { id: "COUNTRY", text: "Land", label: "Land" }];
    }

    function sortPermissions(permissions) {
        var sortedPermissions = {
            routes: [],
            datasets: []
        };
        if (permissions === null) {
            permissions = [];
        }
        for (var a = 0; a < permissions.length; a++) {
            var permission = permissions[a];
            var resourceClass = permission.resourceClass;
            switch (resourceClass) {
                case "Route":
                    sortedPermissions.routes.addObject(permission);
                    break;
                case "Dataset":
                    sortedPermissions.datasets.addObject(permission);
                    break;
            }
        }
        return sortedPermissions;
    }

    function filterAppId(element, index, array) {
        if (element !== undefined) {
            if (element.aid === this) {
                return element;
            }
        }
    }

    function filterAppIdandZero(element, index, array) {
        if (element !== undefined) {
            if (element.aid === this || element.aid === 0) {
                return element;
            }
        }
    }

    function filterByVisibilty(element) {
        if (element !== undefined) {
            if (element.visibility === "PRIVATE" || element.visibility === "INTERNAL") {
                return element;
            }
        }
    }

    function fieldTypes() {
        return [// TODO i18n
        { id: "IMAGE", text: "Bild" }, { id: "LONGBLOB", text: "Binärdaten" }, { id: "BOOLEAN", text: "Boolean (Ja/Nein)" }, { id: "DATE", text: "Datum" }, { id: "BEGIN", text: "Anfang" }, { id: "END", text: "Ende" }, { id: "INT", text: "Ganzzahl" }, { id: "GEOMETRY", text: "Geodaten" }, { id: "DOUBLE", text: "Kommazahl" }, { id: "STRING", text: "Text" }, { id: "LONGTEXT", text: "Langtext" }, { id: "CURRENCY", text: "Währung" }, { id: "TIME", text: "Zeit" }, { id: "DATETIME", text: "Zeitstempel" }, { id: "STREET", text: "Straße" }, { id: "HOUSE_NUMBER", text: "Hausnummer" }, { id: "POSTCODE", text: "PLZ" }, { id: "CITY", text: "Ort" }, { id: "COUNTRY", text: "Land" }];
    }

    function textFieldTypes() {
        return [// TODO i18n
        { id: "DOUBLE", text: "Kommazahl" }, { id: "STRING", text: "Text" }, { id: "CURRENCY", text: "Währung" }, { id: "STREET", text: "Straße" }, { id: "HOUSE_NUMBER", text: "Hausnummer" }, { id: "POSTCODE", text: "PLZ" }, { id: "CITY", text: "Ort" }];
    }

    function getBounds(values) {
        for (var a = 0; a < values.length; a++) {
            var obj = values[a];
            if (obj.type === "Point") {
                var co = obj.coordinates;
                co = [co[1], co[0]];
                Ember.set(obj, "coordinates", co);
            }
        }
        if (values.length === 0) {
            values = [{
                type: "Polygon",
                coordinates: [[[4.042, 45.828], [4.042, 55.478], [15.823, 55.478], [15.820, 45.828]]]
            }];
        }
        var bbox = { "geometries": values, "type": "GeometryCollection" };
        bbox = L.geoJson(bbox).getBounds();
        return bbox;
    }

    function uoms() {
        return [{ id: 0, text: "" }, { text: "Kosten", children: [{ id: 16, text: "€/kWh" }] }, { text: "Energie", children: [{ id: 1, text: "kWh" }, { id: 2, text: "kW" }] }, {
            text: "Distanz",
            children: [{ id: 3, text: "m" }, { id: 4, text: "km" }, { id: 5, text: "cm" }, { id: 6, text: "mm" }]
        }, { text: "Währung", children: [{ id: 7, text: "€" }, { id: 8, text: "$" }, { id: 9, text: "¥" }, { id: 10, text: "£" }] }, {
            text: "Zeit",
            children: [{ id: 11, text: "s" }, { id: 12, text: "min" }, { id: 13, text: "h" }, { id: 14, text: "d" }, {
                id: 15,
                text: "a"
            }]
        }];
    }

    function updateGeometry(record, pathname) {
        if (record !== null && record !== undefined) {
            if (isNotEmpty(record[pathname])) {
                try {
                    return JSON.parse(record[pathname]);
                } catch (error) {
                    return null;
                }
            }
        }
        return null;
    }

    function getInt(value) {
        if (value === null || value === undefined || Number.isNaN(value)) {
            return 0;
        }

        if (isEmpty(value)) {
            return 0;
        }

        return parseInt(value, 10);
    }

    function updateFieldMetaData(data) {
        var fields = data.fields;
        for (var a = 0; a < fields.length; a++) {
            var field = fields[a];
            var logicalType = field.logical_type;
            switch (logicalType) {
                case "DATE":
                    Ember.set(field, "isDate", true);
                    break;
                case "DATETIME":
                    Ember.set(field, "isDateTime", true);
                    break;
                case "TIME":
                    Ember.set(field, "isTime", true);
                    break;
                case "BEGIN":
                    Ember.set(field, "isBegin", true);
                    break;
                case "END":
                    Ember.set(field, "isEnd", true);
                    break;
            }
        }
        return data;
    }
});