define('extraapps/authenticators/ea', ['exports', 'extraapps/config/environment', 'ember-simple-auth/authenticators/base', 'extraapps/util/promiseObject'], function (exports, _environment, _base, _promiseObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        ajax: Ember.inject.service(),
        session: Ember.inject.service(),

        urlPart: undefined,

        restore: function restore(data) {
            var session = this.get('session');
            return new Ember.RSVP.Promise(function (resolve, reject) {
                console.log('restore');
                console.dir(data);
                try {
                    session.set('cache', {});
                    session.set('token', data.token);
                    session.set('locale', 'de');
                    session.set('client_id', 0); // @todo extract from user meta data or session
                    session.set('app_id', 0); // @todo extract from user meta data or session
                    resolve(data);
                } catch (e) {
                    reject();
                }
            });
        },

        authenticate: function authenticate(loginId, password) {
            this._initUrlPart();
            var URL = _environment.default.backend.baseURL + '/security/login';

            var session = this.get('session');

            var authData = {
                loginId: loginId,
                password: password
            };
            var json = JSON.stringify(authData);
            var headers = { 'Content-Type': 'application/json', cid: 1 };
            var that = this;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, { data: json, headers: headers }).then(function (data) {
                    session.set('client_id', 1);
                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        service: 'user',
                        method: 'session',
                        args: {
                            loginId: loginId,
                            password: 'omitted for security reaons'
                        },
                        error: error
                    };
                    Object.assign(reason, error);
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'ea.authenticate(' + loginId + ', password omitted for security reaons)');
        },

        /*  is optional:
            invalidate(data) {
            },
        */
        _initUrlPart: function _initUrlPart() {
            if (this.urlPart === undefined) {
                this.urlPart = _environment.default.server.protocol + '://' + _environment.default.server.host;

                var port = _environment.default.server.port || '';
                if (port.length > 0) {
                    this.urlPart = this.urlPart + ':' + port + '/';
                } else {
                    this.urlPart = this.urlPart + '/';
                }

                var urlPrefix = _environment.default.server.url_prefix || '';
                if (urlPrefix.length > 0) {
                    this.urlPart = '' + this.urlPart + urlPrefix + '/' + _environment.default.auth.auth_srv + '/';
                } else {
                    this.urlPart = '' + this.urlPart + _environment.default.auth.auth_srv + '/';
                }
            }
        }
    });
});