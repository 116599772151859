define('extraapps/admin/template/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        ea: Ember.inject.service(),
        session: Ember.inject.service(),
        notifications: Ember.inject.service('notification-messages'),

        model: function model(transition) {
            var _this = this;

            var id = transition.id;
            if (id !== "0") {
                return this.get("ea").getTemplate(id).then(function (template) {

                    return { template: template };
                }).catch(function (error) {
                    console.log(error);
                    _this.get('notifications').error("Das Template konnte nicht geladen werden", {
                        autoClear: true
                    });
                });
            } else {
                return {
                    template: {
                        id: null
                    }
                };
            }
        },
        actions: {
            save: function save(template) {
                var _this2 = this;

                if (template.id === null) {
                    delete template["id"];
                    Ember.set(template, "handlebarsTemplateType", "LIST");
                }

                return this.get("ea").storeTemplate(template).then(function (template) {
                    _this2.get('notifications').success("Template gespeichert", {
                        autoClear: true
                    });
                }).catch(function (error) {
                    console.log(error);
                    _this2.get('notifications').error("Das Template konnte nicht gespeichert werden", {
                        autoClear: true
                    });
                });
            }
        }

    });
});