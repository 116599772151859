define('extraapps/services/cache', ['exports', 'extraapps/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        session: Ember.inject.service(),

        resetAppData: function resetAppData() {
            this.deleteArray("route");
            this.deleteArray("dataset");
        },

        insertObject: function insertObject(type, element) {
            var cache = this.get("session").cache;
            if (cache === undefined) {
                return;
            }

            var typeArray = cache[type];
            if (typeArray === undefined) {
                Ember.set(cache, type, [element]);
            } else {
                var oldElement = typeArray.findBy("id", element.id);
                if (oldElement === undefined) {
                    typeArray.addObject(element);
                } else {
                    typeArray.removeObject(oldElement);
                    typeArray.addObject(element);
                }
            }
        },

        insertArray: function insertArray(type, elements) {
            var cache = this.get("session").cache;
            if (cache === undefined) {
                return;
            }

            var typeArray = cache[type];
            if (typeArray === undefined) {
                Ember.set(cache, type, elements);
            } else {
                for (var a = 0; a < elements.length; a++) {
                    var element = elements[a];
                    typeArray.addObject(element);
                }
            }
        },

        update: function update(type, element) {
            var cache = this.get("session").cache;
            if (cache === undefined) {
                return;
            }

            var typeArray = cache[type];
            var oldVersion = typeArray.findBy("id", element.id);
            oldVersion = element;
        },

        deleteObject: function deleteObject(element, type, arg) {
            var cache = this.get("session").cache;
            if (cache === undefined) {
                return;
            }

            var typeArray = cache[type];
            if (arg === byId) {
                var _element = typeArray.findBy("element");
            }
            typeArray.removeObject(element);
        },

        deleteArray: function deleteArray(type) {
            var cache = this.get("session").cache;
            if (cache === undefined) {
                return;
            }

            delete cache[type];
        },

        getArray: function getArray(type) {
            var cache = this.get("session").cache;
            if (cache === undefined) {
                return null;
            }

            var typeArray = cache[type];
            if (typeArray === undefined) {
                return null;
            } else {
                return typeArray;
            }
        },

        getObject: function getObject(type, id, arg) {
            var cache = this.get("session").cache;
            if (cache === undefined) {
                return null;
            }

            var typeArray = cache[type];
            if (typeArray === undefined) {
                return null;
            } else {
                if (arg === undefined) {
                    arg = "id";
                }
                var result = typeArray.findBy(arg, parseInt(id));

                if (result === undefined || result === null) {
                    return null;
                } else {
                    return result;
                }
            }
        }
    });
});