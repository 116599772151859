define('extraapps/r1/r2/r3/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        notifications: Ember.inject.service('notification-messages'),
        ea: Ember.inject.service(),
        ajax: Ember.inject.service(),
        api: Ember.inject.service(),
        ps: Ember.inject.service(),
        cache: Ember.inject.service(),
        session: Ember.inject.service(),

        model: function model(params, transition) {
            var _this = this;

            var path = this.modelFor("r1.r2").path;
            path = path + '/' + params.u3;
            var styles = this.modelFor("r1").styles; // TODO: currently needed for ea-datamap - remove after component rebuild
            var routeNames = this.modelFor("r1.r2").routeNames;
            return this.get("ea").getRouteMeta(path).then(function (meta) {
                _this.get("session").set("app_id", meta.aid);
                _this.get("session").set("client_id", meta.cid);
                if (transition.queryParams.id !== undefined) {
                    var ds = meta.databases[0].datasets[0].name;

                    return _this.get("ps").selectRecord("_external", ds, transition.queryParams.id).then(function (data) {
                        console.log(data);
                        return {
                            path: path,
                            meta: meta,
                            record: data,
                            routeNames: routeNames + ',' + meta.name,
                            styles: styles // TODO: currently needed for ea-datamap - remove after component rebuild
                        };
                    }).catch(function (error) {
                        console.log(error);
                    });
                } else {
                    return _this.get("ea").getRouteData(path).then(function (data) {
                        return {
                            path: path,
                            meta: meta,
                            data: data,
                            routeNames: routeNames + ',' + meta.name,
                            styles: styles // TODO: currently needed for ea-datamap - remove after component rebuild
                        };
                    }).catch(function (error) {
                        console.log(error);
                        _this.transitionTo("r1", "404");
                    });
                }
            }).catch(function (error) {
                console.log(error);
                _this.transitionTo("r1", "404");
            });
        },
        rerender: function rerender() {
            this.renderTemplate();
        },
        renderTemplate: function renderTemplate(controller, queryParams) {
            var model = this.modelFor("r1.r2.r3");
            var template = model.meta.handlebarsTemplates[0];
            var templateName = 'extraapps/templates/r3/' + template.id; // Template ID is necessary! Otherwise ember wont render  different templates after transitioning into a new route
            var templateRaw = '<style type="text/css">{{{model.css}}}</style>{{#unless subpage}}' + template.hbs + '{{else}}{{outlet}}\n{{/unless}}';var templateCompiled = Ember.HTMLBars.compile(templateRaw);
            Ember.TEMPLATES[templateName] = templateCompiled;

            this.render(templateName, {// the template name associated with 'theme' Route
                //into: 'application', // the parent route to 'theme' Route
                //outlet: 'main' // {{outlet}} and {{outlet 'main' are synonymous}}, here: {{outlet 'posts'}}
                //view: 'theme',        // the view associated with the 'post' Route
                //controller: 'theme',  // the controller associated with the 'post' Route
            });
        }
    });
});