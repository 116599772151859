define('extraapps/components/ea-form-docs/component', ['exports', 'extraapps/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notifications: Ember.inject.service('notification-messages'),
        docs: Ember.inject.service(),
        ps: Ember.inject.service(),
        api: Ember.inject.service(),
        gc: Ember.inject.service(),
        ds: Ember.inject.service(),
        ea: Ember.inject.service(),
        baseUrl: _environment.default.backend.baseURL,
        dropzone: null,
        init: function init() {
            this._super();
            var url = this.get("baseUrl") + '/doc/';
            this.set("url", url); //fall back
        },

        saveFile: function saveFile() {
            var dropzone = this.get("dropzone");
            var url = this.get("baseUrl") + '/doc/';
            dropzone.options.url = url;
            dropzone.processQueue();
        },
        addImage: function addImage(image) {
            var dropzone = this.get("dropzone");
            this.get('notifications').success("Document hinzugefügt", {
                autoClear: true
            });
            dropzone.removeAllFiles();
            this.sendAction("addDocument", image);
        },
        actions: {

            addedFile: function addedFile() {
                this._super.apply(this, arguments);
                var that = this;
                this.set('dropzone', Dropzone.forElement('#assetUpload'));
                this.set("imageAdded", true);
                Ember.run.later(function () {
                    that.saveFile();
                }, 1000);
            },

            success: function success(response) {
                var _this = this;

                var api = this.get("api");
                var that = this;
                var docs = this.get("docs");
                var dropzone = this.get("dropzone");
                var documents = this.get("assets");
                var meta = {};
                response = JSON.parse(response.xhr.response);
                meta.id = response.resource[0].id;
                meta.cid = 1;
                meta.title = 'image.id_' + meta.id;
                meta.fileName = response.resource[0].fileName;
                meta.description = this.get("description");
                return docs.updateDocMeta(meta).then(function (Data) {
                    _this.addImage(Data[0]);
                }).catch(function (error) {});
            }
        }
    });
});