define('extraapps/admin/page/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend( /*AuthenticatedRouteMixin,*/{
        ea: Ember.inject.service(),
        ds: Ember.inject.service(),
        cache: Ember.inject.service(),
        session: Ember.inject.service(),
        notifications: Ember.inject.service('notification-messages'),
        title: 'Admin > Seite',
        model: function model(params) {
            var ea = this.get('ea');
            var ds = this.get('ds');
            var cache = this.get('cache');
            var pathSegment = params.pathSegment;

            return ea.getRouteData(pathSegment).then(function (data) {
                return ea.getRouteMeta(pathSegment, pathSegment).then(function (meta) {
                    /*return ea.getTemplates().then(templates => {
                        return ds.getDatasets().then(datasets => {*/

                    console.log(meta);

                    return {
                        status: [{ id: "DRAFT", text: "Entwurf" }, { id: "REVIEW", text: "Review ausstehend" }, { id: "PUBLISHED", text: "veröffentlicht" }], //TODO: LOAD FROM SETTING;
                        visibility: [{ id: "PUBLIC", text: "öffentlich" }, { id: "PRIVATE", text: "privat" }],
                        meta: meta,
                        data: data
                    };
                }).catch(function (error) {

                    console.log(error);
                    alert('No datasets');
                });
            }).catch(function (error) {

                console.log(error);
                alert('No datasets');
            });
            /*}).catch(error => {
                 console.log(error)
                alert('No datasets');
            });
            }).catch(error => {
            console.log(error)
              alert('No datasets');
            });*/
        },
        actions: {
            saveTemplate: function saveTemplate(meta) {
                var _this = this;

                console.log(meta.handlebarsTemplates[0]);
                var template = JSON.parse(JSON.stringify(meta.handlebarsTemplates[0]));
                return this.get("ea").storeTemplate(template).then(function (template) {
                    Ember.set(meta, "handlebarsTemplates", [template]);
                    _this.get('notifications').success("Änderungen gespeichert", {
                        autoClear: true
                    });
                }).catch(function (error) {
                    console.log(error);
                    _this.get('notifications').error("Fehler beim speichern des Templates", {
                        autoClear: true
                    });
                });
            },
            save: function save(meta) {
                var _this2 = this;

                return this.get("ea").updateRoutes(meta).then(function (addedRoute) {
                    _this2.get('notifications').success("Änderungen gespeichert", {
                        autoClear: true
                    });
                }).catch(function (error) {
                    console.log(error);
                    _this2.get('notifications').success("Fehler beim speichern des Templates", {
                        autoClear: true
                    });
                });
            },
            loading: function loading(transition, originRoute) {
                var controller = this.controllerFor('admin');
                controller.set('currentlyLoading', true);
            },
            willTransition: function willTransition(transition) {
                if (transition.intent.name == "admin.pages") {
                    this.get("ea").getController("admin.pages").set("subpage", false);
                }
            }
        }
    });
});