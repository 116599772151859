define("extraapps/components/ea-input/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        exvalidator: Ember.inject.service(),
        actions: {
            validate: function validate() {
                var record = this.get("record");

                var field = this.get("field");
                var r = {};
                r[field.path] = record[field.path];
                var errors = this.get("exvalidator").validateRecord(r, [field]);
                console.log(errors);
                if (errors.length > 0) {
                    this.set("invalid", true);
                } else {
                    this.set("invalid", false);
                }
            }
        }
    });
});