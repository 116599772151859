define('extraapps/components/record-date/component', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        contextDetails: {},
        init: function init() {
            this._super();
            var record = this.get("record");
            var dates = this.get("dates");
            if (dates.dateField !== undefined) {
                var path = dates.dateField.path;
                var label = dates.dateField.label;
                var date = record[path];
                this.set("currentDate", date);
                this.set("dateLabel", label);
                Ember.set(this, "date", true);
            } else {
                var _path = this.get("dates.start.path");
                var startDate = record[_path];
                var startDay = (0, _moment.default)(startDate).format("YYYY-MM-DD");
                var startTime = (0, _moment.default)(startDate).format("HH:mm");
                this.set("startDay", startDay);
                this.set("startTime", startTime);

                var end = this.get("dates.end");
                if (end !== undefined) {
                    var _path2 = end.path;
                    var endDate = record[_path2];

                    var endTime = (0, _moment.default)(endDate).format("HH:mm");
                    var endDay = (0, _moment.default)(endDate).format("YYYY-MM-DD");
                    this.set("endDay", endDay);
                    this.set("endTime", endTime);
                }
            }
        },

        dateChanged: function () {
            var date = this.get("currentDate");
            var record = this.get("record");
            var dates = this.get("dates");
            if (date !== undefined) {
                var path = dates.dateField.path;
                Ember.set(record, path, date);
            }
        }.observes("currentDate").on("init"),

        startDate: Ember.computed('startDay', 'startTime', function () {
            return this.get('startDay') + ' ' + this.get('startTime');
        }),
        startDateChanged: function () {
            var startDate = this.get("startDate");
            if (startDate !== undefined && startDate !== null && startDate !== "undefined undefined") {
                var record = this.get("record");
                var dates = this.get("dates");
                var path = dates.start.path;
                Ember.set(record, path, startDate);
            }
        }.observes("startDate").on("init"),

        endDate: Ember.computed('endDay', 'endTime', function () {
            return this.get('endDay') + ' ' + this.get('endTime');
        }),
        endDateChanged: function () {
            var endDate = this.get("endDate");
            if (endDate !== undefined && endDate !== null && endDate !== "undefined undefined") {
                var record = this.get("record");
                var dates = this.get("dates");
                var path = dates.end.path;
                Ember.set(record, path, endDate);
            }
        }.observes("endDate").on("init")
    });
});