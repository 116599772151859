define('extraapps/components/dataset-view/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        ds: Ember.inject.service(),
        ps: Ember.inject.service(),
        notifications: Ember.inject.service('notification-messages'),
        oldDatasets: [],
        loading: false,
        map: {
            background: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
            colors: [{ id: 0, color: "#00FF00", fillColor: "#00FF00" }, { id: 1, color: "#FF00FF", fillColor: "#FF00FF" }]
        },
        record: {},
        recordView: {
            sidebar: false
        },
        layers: [],
        layoutChanged: function () {
            var layout = this.get("layout");
            switch (layout) {
                case "map":
                    this.set("showMap", true);
                    break;
                case "list":
                    this.set("showList", true);
                    break;
                case "cal":
                    this.set("showCal", true);
                    break;
            }
        }.observes("layout").on("init"),
        init: function init() {
            var _this = this;

            this._super();
            var datasets = this.get("datasets");
            this.set("oldDatasets", JSON.parse(JSON.stringify(datasets)));
            if (datasets !== null && datasets !== undefined) {
                var _loop = function _loop() {
                    var dataset = datasets[a];
                    var layers = _this.get("layers");
                    var map = _this.get("map");
                    var layout = _this.get("layout");
                    Ember.set(map, "layers", datasets);

                    var that = _this;
                    var colors = map.colors[a];
                    _this.get("ds").getDataset(dataset).then(function (meta) {
                        _this.get("ps").selectRecords('_external', dataset, {}).then(function (records) {
                            var layer = { meta: meta, records: records, colors: colors, text: meta.name, id: meta.id };
                            layers.addObject(layer);
                            if (meta.withGeoData) {
                                if (a === datasets.length) {}
                                if (layout === undefined || layout === null) {
                                    _this.sendAction("setLayout", "map");
                                }
                            }if (meta.withTemporalData) {
                                if (a === datasets.length) {}
                                if (layout === undefined || layout === null) {
                                    _this.sendAction("setLayout", "cal");
                                }
                            } else {
                                _this.sendAction("setLayout", "map");
                            }

                            _this.set("loading", false);
                        }).catch(function (error) {
                            console.log(error);
                        });
                    }).catch(function (error) {
                        console.log(error);
                    });
                };

                for (var a = 0; a < datasets.length; a++) {
                    _loop();
                }
            }
        },

        datasetsChanged: function () {
            var _this2 = this;

            var oldDatasets = this.get("oldDatasets");
            var datasets = this.get("datasets");
            var newDatasets = datasets.filter(function (el) {
                return oldDatasets.indexOf(el) !== -1;
            });
            if (newDatasets.length > 0) {
                var _loop2 = function _loop2() {
                    var dataset = newDatasets[a];
                    var layers = _this2.get("layers");
                    var geoLayers = layers.map;
                    var that = _this2;
                    var map = _this2.get("map");
                    var colors = map.colors[datasets.length - 1];
                    _this2.get("ds").getDataset(dataset).then(function (meta) {
                        _this2.get("ps").selectRecords('_external', dataset, {}).then(function (records) {
                            var layer = { meta: meta, records: records, colors: colors, text: meta.name, id: meta.id };
                            if (meta.withGeoData) {
                                geoLayers.addObject(layer);
                            }
                        }).catch(function (error) {
                            console.log(error);
                        });
                    }).catch(function (error) {
                        console.log(error);
                    });
                };

                for (var a = 0; a < newDatasets.length; a++) {
                    _loop2();
                }
            }
        }.observes("datasets").on("init"),
        refreshChanged: function () {
            var datasets = this.get("datasets");
        }.observes("refresh").on("init"),
        willDestroyElement: function willDestroyElement() {
            this.set("layers", { map: [] });
        },

        actions: {
            newRecord: function newRecord(ds) {
                this.sendAction("newRecord", ds);
            },
            goToRecord: function goToRecord(ds, id) {
                this.sendAction("goTo", ds, id);
            },

            updateRecord: function updateRecord(ds, record) {
                var datasetName = ds.meta.name;
                var layers = this.get("layers");
                var layer = layers.findBy("meta.name", datasetName);
                var records = layer.records;
                var r = records.findBy("id", record.id);
                Ember.set(r, "geodaten", record.geodaten);
            },
            insertRecord: function insertRecord(ds, record, recordView, isNew) {
                var _this3 = this;

                var ps = this.get('ps');
                var datasetName = ds.meta.name;

                if (isNew) {
                    var _geoLayers = this.get("layers.map");
                    return ps.insertRecords('_external', datasetName, record).then(function (data) {
                        if (ds.meta.withGeoData) {

                            var newR = data[0];
                            newR.geodata = JSON.parse(newR.geodaten);

                            Ember.set(recordView, "sidebar", false);
                            ds.records.addObject(newR);
                            _this3.set("record", {});
                        }

                        _this3.get('notifications').success("Daten gespeichert", { // TODO: use i18n text...
                            autoClear: true
                        });
                    }).catch(function (error) {
                        error.error.errors.forEach(function (e) {
                            console.log(e.status);
                            console.log(e.title);
                        });
                        _this3.get('notifications').error('Fehler beim Laden der Metadaten f\xFCr: ' + dataset, { // TODO: use i18n text...
                            autoClear: true
                        });
                    });
                } else {
                    return ps.updateRecord('_external', datasetName, record).then(function (data) {

                        _this3.get('notifications').success("Daten gespeichert", { // TODO: use i18n text...
                            autoClear: true
                        });
                    }).catch(function (error) {
                        error.error.errors.forEach(function (e) {
                            console.log(e.status);
                            console.log(e.title);
                        });
                        _this3.get('notifications').error("Speichern gescheitert.", { // TODO: use i18n text...
                            autoClear: true
                        });
                    });
                }
            },
            deleteRecord: function deleteRecord(ds, record) {
                var _this4 = this;

                this.get("ps").deleteRecord("_external", ds.meta.name, record.id).then(function (data) {

                    if (ds.meta.withGeoData) {
                        var removed = ds.records.findBy("id", parseInt(data[0].id));
                        ds.records.removeObject(removed);
                        _this4.set("record", {});
                        Ember.set(_this4.recordView, "sidebar", false);
                    }
                    _this4.get('notifications').success('Eintrag gelöscht.', { // TODO: use i18n text...
                        autoClear: true
                    });
                }).catch(function (error) {
                    error.error.errors.forEach(function (e) {
                        console.log(e.status);
                        console.log(e.title);
                    });

                    _this4.get('notifications').error('Löschen fehlgeschlagen.', { // TODO: use i18n text...
                        autoClear: true
                    });
                });
            },
            toggleLayer: function toggleLayer(layer) {
                if (!layer.hide || layer.hide === undefined) {
                    Ember.set(layer, "hide", true);
                } else {
                    Ember.set(layer, "hide", false);
                }
            },
            setLayout: function setLayout(view) {

                this.sendAction("setLayout", view);
            },
            setBackground: function setBackground(bg) {
                var map = this.get("map");
                Ember.set(map, "background", bg);
            },
            addLayer: function addLayer(dataset) {
                this.sendAction("addLayer", dataset);
            }
        }
    });
});