define("extraapps/components/ea-form-geojson/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        gc: Ember.inject.service(),
        location: {},

        init: function init() {
            this._super();

            var geoCode = this.get("geoCode");
            geoCode = geoCode.split(",");
            var that = this;
            var fields = this.get("fields");
            var record = this.get("record");
            var location = this.get("location");
            var geoField = fields.findBy("logical_type", "GEOMETRY");
            var streetField = fields.findBy("logical_type", "STREET");
            var houseField = fields.findBy("logical_type", "HOUSE_NUMBER");
            var postField = fields.findBy("logical_type", "POSTCODE");
            var cityField = fields.findBy("logical_type", "CITY");

            if (record[geoField.path] !== null && record[geoField.path] !== undefined) {
                var geometry = JSON.parse(record[geoField.path]);
                this.set("geometry", geometry);
                this.getBounds([geometry]);
            } else {
                this.getBounds();
            }
        },

        getBounds: function getBounds(objs) {
            if (objs === undefined) {
                objs = [{ type: "Polygon", coordinates: [[[4.042, 45.828], [4.042, 55.478], [15.823, 55.478], [15.820, 45.828]]] }];
            }
            var bbox = { "geometries": objs, "type": "GeometryCollection" };
            bbox = L.geoJson(bbox).getBounds();
            this.set("bounds", bbox);
        },
        getGeoLocation: function getGeoLocation(location) {
            var _this = this;

            var that = this;
            var address = {};
            var record = this.get("record");
            var fields = this.get("fields");
            var geoPath = fields.findBy("logical_type", "GEOMETRY").path;
            if (location === undefined) {
                address = this.get("location");
            } else {
                address = location;
            }
            var street = address.street;
            var number = address.number;
            var city = address.city;
            var post = address.postcode;

            var values = ["street", "number", "city", "postcode"];
            var proceed = true;
            for (var a = 0; a < values.length; a++) {
                var value = values[a];
                if (address[value] === undefined || address[value] === null) {
                    proceed = false;
                }
            }
            if (proceed) {
                return this.get("gc").geoCodeOneAddress(city, street, number, post).then(function (data) {
                    var geoData = data[0].geometry.location;
                    Ember.set(record, geoPath, JSON.stringify({ type: "Point", coordinates: [geoData.lng, geoData.lat] }));
                    Ember.set(_this, "geometry", { type: "Point", coordinates: [geoData.lat, geoData.lng] });
                    _this.getBounds([{ type: "Point", coordinates: [geoData.lng, geoData.lat] }]);
                });
            }
        },
        actions: {
            setGeoData: function setGeoData(geo) {
                console.log(geo);
                var record = this.get("record");
                var fields = this.get("fields");
                var geoPath = fields.findBy("logical_type", "GEOMETRY").path;
                Ember.set(record, geoPath, geo);
            },
            getGeoLocation: function getGeoLocation(location) {
                this.getGeoLocation(location);
            },
            updateLocation: function updateLocation(latlng) {
                var record = this.get("record");
                var fields = this.get("fields");
                var geoPath = fields.findBy("logical_type", "GEOMETRY").path;
                var geo = { type: "Point", coordinates: [latlng.lng, latlng.lat] };
                Ember.set(record, geoPath, geo);
            }
        }

    });
});