define('extraapps/admin/changes/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notifications: Ember.inject.service('notification-messages'),
        ds: Ember.inject.service(),
        cr: Ember.inject.service(),
        relation: Ember.inject.service(),
        queryParams: ['request'],
        request: null,
        status: [{ id: 'DRAFT', text: 'Entwurf' }, { id: 'REVIEW', text: 'Review ausstehend' }, { id: 'PUBLISHED', text: 'veröffentlicht' }],
        columns: [{ key: 'status', header: 'Art', component: 'changerequest-type', sortable: true }, { key: 'id', header: 'ID', sortable: true, width: '250px' }, { key: 'datasetName', header: 'Dataset', sortable: true }, { key: 'updatedOn', header: 'Datum', component: 'changerequest-date', sortable: true }, { key: 'updatedBy', header: 'Urheber', sortable: true }, { key: '', header: '', component: 'goto-button' }],
        changeChanged: function () {
            var _this = this;

            var id = this.get('request');
            var model = this.get('model');
            if (id !== null && model !== null) {
                var changes = model.changes;
                var data = changes.findBy('id', id);

                if (data === undefined) {
                    return;
                }
                var docs = [];
                var relatedData = [];
                var showDocs = false;
                var prev = false;
                var prevPath = null;
                for (var a = 1; a < data.changeRequests.length; a++) {
                    var request = data.changeRequests[a];
                    if (request.datasetName === 'DocumentLink') {
                        docs.addObject(request);
                    } else {
                        relatedData.addObject(request);
                    }
                }
                return this.get('ds').getDataset(data.changeRequests[0].datasetName).then(function (dataset) {
                    dataset.fields.sort(function (field1, field2) {
                        return field1.sequenceNumber - field2.sequenceNumber;
                    });
                    dataset.fields.removeObject(dataset.fields.findBy('path', 'owner'));
                    var imageField = dataset.fields.findBy('logical_type', 'IMAGE');
                    if (imageField !== undefined) {
                        dataset.fields.removeObject(imageField);
                        prev = true;
                        prevPath = imageField.path;
                    }
                    if (docs.length > 1 && dataset.withImage) {
                        showDocs = true;
                    } else if (docs.length = 1 && !dataset.withImage) {
                        showDocs = true;
                    }
                    _this.set('change', {
                        id: data.id,
                        geoData: dataset.withGeoData,
                        showDocs: showDocs,
                        docs: docs,
                        prev: prev,
                        prevPath: prevPath,
                        fields: dataset.fields,
                        data: JSON.parse(data.changeRequests[0].changeData)
                    });

                    _this.set('showChange', true);
                }).catch(function (error) {
                    alert(error);
                });
            } else {
                this.set('showChange', false);
            }
        }.observes('request').on('init'),
        tabChanged: function () {
            var tab = this.get('tab');
            if (tab === 'geoData') {
                Ember.run.later(this, function () {
                    this.set('showMap', true);
                }, 200);
            } else {
                this.set('showMap', false);
            }
        }.observes('tab').on('init'),
        actions: {
            accept: function accept() {
                var _this2 = this;

                var id = this.get('change').id;
                var changes = this.get('model.changes');
                this.get('cr').acceptChangeRequest(id).then(function (accepted) {
                    var change = changes.findBy('id', id);
                    changes.removeObject(change);
                    _this2.get('notifications').success('Änderungen übernommen.', {
                        autoClear: true
                    });
                    _this2.set('request', null);
                }).catch(function (error) {

                    console.log(error);
                    _this2.get('notifications').error('Fehler beim Übernhmen der Änderungen.', {
                        autoClear: true
                    });
                });
            },
            reject: function reject() {
                var _this3 = this;

                var id = this.get('change').id;
                var changes = this.get('model.changes');
                this.get('cr').rejectChangeRequest(id).then(function (removed) {
                    var change = changes.findBy('id', id);
                    changes.removeObject(change);
                    _this3.get('notifications').success('Änderungsantrag abgelehnt.', {
                        autoClear: true
                    });
                    _this3.set('request', null);
                }).catch(function (error) {
                    console.log(error);
                    _this3.get('notifications').error('Fehler beim Ablehnen der Werte.', {
                        autoClear: true
                    });
                });
            },
            goTo: function goTo(id) {

                this.set('request', id);
            }
        }
    });
});