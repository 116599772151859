define('extraapps/components/ds-viewer/component', ['exports', 'extraapps/util/util'], function (exports, _util) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        ds: Ember.inject.service(),
        ea: Ember.inject.service(),
        ps: Ember.inject.service(),
        notifications: Ember.inject.service('notification-messages'),
        loading: true,
        map: {
            background: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        },
        colors: [{ id: 0, color: "#00FF00", fillColor: "#00FF00" }, { id: 1, color: "#FF00FF", fillColor: "#FF00FF" }],
        layers: [],

        datasetsChanged: function () {
            this.set("layers", []);
            var layout = this.get("layout");
            this.set("cal", false);
            this.set("map", false);
            this.set("list", false);
            this.set("events", []);
            var that = this;
            var colors = this.get("colors");
            var layers = this.get("layers");
            var datasets = this.get("datasets");
            that.get("ea").forEachCps(datasets, function (dataset, index, next) {
                //TODO: CPS RAUS? ODER NEXT
                that.get("ds").getDataset(dataset).then(function (meta) {
                    that.get("ps").selectRecords('_external', dataset, {}).then(function (records) {
                        var color = colors[index];
                        if (meta.withGeoData) {
                            var geoField = meta.fields.findBy("logical_type", "GEOMETRY");
                            var geoPath = geoField.path;
                            var isPoint = false;
                            for (var a = 0; a < records.length; a++) {
                                var record = records[a];
                                if (record[geoPath] !== undefined && record.length !== 0 && record[geoPath] !== null) {
                                    record.geoJSON = JSON.parse(record[geoPath]);
                                    if (record.geoJSON.type === "Point") {
                                        isPoint = true;
                                        Ember.set(record, "isPoint", isPoint);
                                        var geoJSONInversed = (0, _util.flipJSON)(record.geoJSON);
                                        Ember.set(record, "geoJSONInversed", geoJSONInversed);
                                    }
                                } else if (record[geoPath] === null) {
                                    Ember.set(record, "hideOnMap", true);
                                }
                            }
                        }
                        if (meta.withTemporalData) {
                            var dateField = meta.fields.findBy("logical_type", "DATE");
                            var startField = meta.fields.findBy("logical_type", "BEGIN");
                            var endField = meta.fields.findBy("logical_type", "END");
                            if (dateField !== undefined) {
                                var datePath = dateField.path;
                                records.forEach(function (record) {
                                    if (record[datePath] !== undefined) {
                                        var event = {};
                                        event.allDay = true;
                                        event.layerId = meta.id;
                                        event.color = color.fillColor;
                                        event.start = record[datePath];
                                        that.get("events").addObject(event);
                                    }
                                });
                            }
                            if (startField !== undefined && endField !== undefined) {
                                var startPath = startField.path;
                                var endPath = endField.path;
                                records.forEach(function (record) {
                                    if (record[startPath] !== undefined) {
                                        var event = {};
                                        event.title = moment(record[startPath]).format("HH:mm") + ' - ' + moment(record[endPath]).format("HH:mm");
                                        event.layerName = meta.name;
                                        event.id = record.id;
                                        event.color = color.fillColor;
                                        event.start = record[startPath];
                                        event.end = record[endPath];
                                        that.get("events").addObject(event);
                                    }
                                });
                            }
                        }
                        var layer = { meta: meta, records: records, colors: color };
                        layers.addObject(layer);
                        if (index === 0) {
                            that.set("activeLayer", layer);
                        }
                        next();
                    }).catch(function (error) {
                        that.get('notifications').error('Fehler beim Laden der Daten f\xFCr: ' + dataset, { // TODO: use i18n text...
                            autoClear: true
                        });
                        console.log(error);
                    });
                }).catch(function (error) {
                    that.get('notifications').error('Fehler beim Laden der Metadaten f\xFCr: ' + dataset, { // TODO: use i18n text...
                        autoClear: true
                    });
                    console.log(error);
                });
            }, that.loaded, that);
            this.layoutChxanged(layout);
        }.observes("datasets").on("init"),
        loaded: function loaded(that, events) {
            that.set("loading", false);
        },
        layoutChxanged: function (layout) {
            if (layout === undefined) {
                layout = this.get("layout");
            }
            switch (layout) {
                case "map":
                    this.set("map", true);
                    this.set("list", false);
                    this.set("cal", false);
                    break;
                case "list":
                    this.set("map", false);
                    this.set("list", true);
                    this.set("cal", false);
                    break;
                case "cal":
                    this.set("map", false);
                    this.set("list", false);
                    this.set("cal", true);
                    break;
            }
        }.observes("layout").on("init"),
        insert: function insert(target, record) {
            var _this = this;

            var newRecord = {};
            if (target.meta !== undefined) {
                target = target.meta;
            }
            if (record.id === null || record.id === undefined) {

                var geoPath = target.fields.findBy("logical_type", "GEOMETRY").path;

                newRecord[geoPath] = JSON.stringify(record.geoJSON);
            }
            var layers = this.get("layers");
            var layer = layers.findBy("meta.name", target.name);

            return this.get("ps").insertRecords(layer.meta.identifier, layer.meta.name, newRecord).then(function (data) {
                record.id = data[0].id;
                layer.records.addObject(record);
            }).catch(function (error) {
                error.error.errors.forEach(function (e) {
                    console.log(e.status);
                    console.log(e.title);
                });

                _this.get('notifications').error('Speichern fehlgeschlagen.', { // TODO: use i18n text...
                    autoClear: true
                });
            });
        },
        update: function update(target, record) {
            var _this2 = this;

            console.log(record);
            var layers = this.get("layers");
            var layer = layers.findBy("meta.name", target.name);
            return this.get("ps").updateRecord(layer.meta.identifier, layer.meta.name, record).then(function (data) {

                var records = layer.records;
                var changedR = records.findBy("id", record.id);
            }).catch(function (error) {
                error.error.errors.forEach(function (e) {
                    console.log(e.status);
                    console.log(e.title);
                });

                _this2.get('notifications').error('Speichern fehlgeschlagen.', { // TODO: use i18n text...
                    autoClear: true
                });
            });
        },

        actions: {
            newRecord: function newRecord() {},
            goToRecord: function goToRecord(dsName, id) {
                this.sendAction("goTo", dsName, id);
            },
            insertRecord: function insertRecord(target, record) {
                if (record.id === null || record.id === undefined) {
                    this.insert(target, record);
                } else {
                    this.update(target, record);
                }
            },

            setLayout: function setLayout(layout) {
                this.sendAction("setLayout", layout);
            },
            deleteRecord: function deleteRecord(identifier, dsName, record) {
                var _this3 = this;

                var layers = this.get("layers");
                var layer = layers.findBy("meta.name", dsName);
                return this.get("ps").deleteRecord(identifier, dsName, record.id).then(function (data) {

                    layer.records.removeObject(record);
                }).catch(function (error) {
                    error.error.errors.forEach(function (e) {
                        console.log(e.status);
                        console.log(e.title);
                    });

                    _this3.get('notifications').error('Löschen fehlgeschlagen.', { // TODO: use i18n text...
                        autoClear: true
                    });
                });
            }
        }

    });
});