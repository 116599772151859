define('extraapps/admin/changes/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    title: 'Admin > Änderungsanträge',
    cr: Ember.inject.service(),
    model: function model() {

      return this.get("cr").getChangeRequests().then(function (changes) {
        for (var a = 0; a < changes.length; a++) {
          var change = changes[a];
          change.changeType = change.changeRequests[0].changeType;
          change.datasetName = change.changeRequests[0].datasetName;
        }
        return { changes: changes };
      }).catch(function (error) {
        alert(error);
      });
    }

  });
});