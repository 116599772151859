define("extraapps/sandbox/route", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model: function model() {
            return {
                fields: [{ label: "E-Mail", path: "mail", logical_type: "TEXT", validations: [{ type: "mail", message: " ungültig" }] }, { label: "Name", path: "name", logical_type: "TEXT", validations: [{ "type": "required", "message": "darf nicht leer sein" }] }, {
                    label: "PLZ",
                    path: "plz",
                    logical_type: "PLZ",
                    validations: [{ type: "length", args: 5, message: "ungültig" }, { type: "integer", message: "ungültig" }]
                }, { label: "Text", path: "text", logical_type: "Text", validations: null }, { label: "IBAN", path: "iban", logical_type: "IBAN", validations: [{ type: "iban", message: "ungültig" }] }],
                record: {}
            };
        }

    });
});