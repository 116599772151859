define('extraapps/admin/datasets/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend( /*AuthenticatedRouteMixin,*/{

        title: 'Admin > Datasets',
        ds: Ember.inject.service(),
        ea: Ember.inject.service(),
        notifications: Ember.inject.service('notification-messages'),
        model: function model(transition) {
            var ds = this.get('ds');
            var that = this;

            return ds.getDatasets().then(function (datasets) {
                return {
                    datasets: datasets,
                    dataset: {}
                };
            }).catch(function (error) {
                var message = 'Fehler beim Laden der Daten';
                that.get('notifications').error(message, {
                    autoClear: true
                });
            });
        },
        actions: {}

    });
});