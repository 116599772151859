define("extraapps/components/public-form/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        init: function init() {
            this._super();
            var model = this.get("model");
            var fields = model.meta.databases[0].datasets[0].fields;
            var filtered = [];
            fields.forEach(function (field) {
                if (field.logical_type !== "ID" && field.logical_type !== "GEOMETRY" && field.path !== "owner") {
                    filtered.addObject(field);
                }
            });
            this.set("fields", filtered);
        }
    });
});