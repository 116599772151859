define("extraapps/components/dataset-view-map-record/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        fieldsChanged: function () {
            var fields = JSON.parse(JSON.stringify(this.get("fields")));
            fields.forEach(function (field) {
                if (field.path === "owner") {
                    fields.removeObject(field);
                }
                if (field.logical_type === "GEOMETRY") {
                    fields.removeObject(field);
                }
            });
        }.observes("fields").on("init")
    });
});