define("extraapps/admin/templates/controller", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        ea: Ember.inject.service(),

        columns: [{ key: "id", header: "ID", sortable: true }, { key: "identifier", header: "Name", sortable: true }, { key: " ", header: " ", component: "delete-button", width: "70px" }, { key: " ", header: " ", component: "goto-button", width: "30px" }],
        actions: {
            goTo: function goTo(id) {
                this.transitionToRoute("admin.template", id);
            },
            delete: function _delete(id) {
                var _this = this;

                this.get("ea").deleteTemplate(id).then(function (deleted) {
                    var templates = _this.get("model.templates");
                    var template = templates.findBy("id", id);
                    templates.removeObject(template);
                }).catch(function (error) {
                    console.log(error);
                    _this.get('notifications').error("Das Template konnte nicht gelöscht werden", {
                        autoClear: true
                    });
                });
            }
        }
    });
});