define('extraapps/components/record-previmage/component', ['exports', 'extraapps/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    docs: Ember.inject.service(),
    ea: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    session: Ember.inject.service(),
    baseUrl: _environment.default.backend.baseURL + '/doc/',
    dropzone: null,
    record: {},
    init: function init() {
      var _this = this;

      this._super();
      var header = this.get("ea").getDefaultHeaderFiles();
      this.set("headers", header);
      var model = this.get("model");
      var fields = model.meta.databases[0].datasets[0].fields;
      var imageField = fields.findBy("logical_type", "IMAGE");
      var path = imageField.path;
      var record = model.data[0].records[0];
      var imgId = record[path];
      imgId = parseInt(imgId);
      this.set("path", path);
      if (imgId !== null && !isNaN(imgId)) {
        this.set("imgId", imgId);
        this.set("showImage", true);
      } else if (model.isNew) {
        this.set("new", true);
        this.set("noDocs", true);
        this.set("showImage", false);
      } else {
        return this.get("docs").getDocsForRecord(model.meta.databases[0].datasets[0].id, model.data[0].records[0].id).then(function (docs) {
          if (docs.length !== 0) {
            _this.set("docs", docs);
          } else {
            _this.set("new", true);
            _this.set("noDocs", true);
          }
          _this.set("showImage", false);
        }).catch(function (error) {
          console.log(error);
        });
      }
    },

    actions: {
      changeImage: function changeImage() {
        var docs = this.get("docs");
        if (docs.length < 2) {
          this.set("new", true);
        }
        this.set("showImage", false);
      },
      useImage: function useImage(id) {
        var path = this.get("path");
        this.sendAction("updatePrev", path, id);
        this.set("imgId", id);
        this.set("showImage", true);
      },
      saveFile: function saveFile() {
        var that = this;
        var dropzone = this.get("dropzone");
        var name = this.get("model.meta.title");
        var url = '' + this.get("baseUrl");
        dropzone.options.url = url;
        dropzone.processQueue();
      },
      addedFile: function addedFile() {
        this._super.apply(this, arguments);
        this.set('dropzone', Dropzone.forElement('#assetUpload'));
      },
      success: function success(response) {
        var _this2 = this;

        var path = this.get("path");
        var model = this.get("model");
        var datasetId = model.meta.databases[0].datasets[0].id;
        var recordId = model.data[0].records[0].id;
        response = JSON.parse(response.xhr.response);
        response = response.resource[0];
        var api = this.get("api");
        var that = this;
        var meta = {};
        var record = this.get("record");
        meta.title = record.title;
        meta.id = response.id;
        meta.cid = 1;
        meta.description = record.description;
        var dropzone = this.get("dropzone");

        return this.get("docs").updateDocMeta(meta).then(function (newMeta) {
          return _this2.get("docs").insertDocLink(newMeta[0].id, datasetId, recordId).then(function (link) {
            that.dropzone.removeAllFiles();
            that.set("record", {});
            that.set("imgId", newMeta[0].id);
            _this2.sendAction("updatePrev", path, newMeta[0].id);
            that.set("new", false);
            that.get('notifications').success("Datei gespeichert", {
              autoClear: true
            });
          }).catch(function (error) {
            console.log(error);
            that.get('notifications').error("Leider ist ein Fehler aufgetreten", {
              autoClear: true
            });
          });
        }).catch(function (error) {
          console.log(error);
          that.get('notifications').error("Leider ist ein Fehler aufgetreten", {
            autoClear: true
          });
        });
      }
    }
  });
});