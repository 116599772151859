define('extraapps/admin/assets/controller', ['exports', 'ember-parachute', 'extraapps/config/environment'], function (exports, _emberParachute, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.myQueryParams = undefined;
  var myQueryParams = exports.myQueryParams = new _emberParachute.default({
    type: {
      defaultValue: "images"
    },
    asset: {
      defaultValue: null
    }
  });

  exports.default = Ember.Controller.extend(myQueryParams.Mixin, {
    docs: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    session: Ember.inject.service(),
    ea: Ember.inject.service(),
    baseUrl: _environment.default.backend.baseURL + '/doc/',
    dropzone: null,
    record: {},
    singleAsset: true,
    init: function init() {
      this._super();
      var header = this.get("ea").getDefaultHeaderFiles();
      this.set("headers", header);
    },

    typeChanged: function () {
      var type = this.get("type");
      var model = this.get("model");
      this.set("asset", null);
      if (model !== undefined && model !== null) {
        if (type === "images" && !("images" in model)) {
          this.updateModel(type);
        } else if (type === "icons" && !("icons" in model)) {
          this.updateModel(type);
        } else if (type === "lists" && !("lis" in model)) {
          this.updateModel(type);
        } else {
          this.updateView(type);
        }
      } else {
        this.updateView(type);
      }
    }.observes("type").on("init"),
    assetChanged: function () {
      var id = this.get("asset");
      var type = this.get("type");
      var model = this.get("model");

      if (id !== null && id !== undefined) {
        if (model !== null) {
          if (type === "images") {
            var img = model.images.findBy("id", id);
            if (img !== undefined) {
              var image = Object.assign({}, img);
              this.set("image", image);
            }
          }
        }

        this.set("singleAsset", true);
      } else {
        this.set("singleAsset", false);
      }
    }.observes("asset").on("init"),
    updateModel: function updateModel(type) {
      var _this = this;

      var model = this.get("model");
      var that = this;
      if (type === "images") {
        return this.get("docs").getDocsMeta().then(function (assets) {
          Ember.set(model, "images", assets);
          that.updateView("images");
        }).catch(function (error) {
          _this.get('notifications').error("Fehler beim Laden der Daten", {
            autoClear: true
          });
          console.log(error);
        });
      } else if (type === "icons") {
        return this.get("docs").getDocsMeta().then(function (assets) {
          Ember.set(model, "icons", assets);
          that.updateView("icons");
        }).catch(function (error) {

          _this.get('notifications').error("Fehler beim Laden der Daten", {
            autoClear: true
          });
          console.log(error);
        });
      } else if (type === "lists") {
        return this.get("docs").getDocsMeta().then(function (assets) {
          Ember.set(model, "lists", assets);
          that.updateView("lists");
        }).catch(function (error) {
          console.log(error);
          _this.get('notifications').error("Fehler beim Laden der Daten", {
            autoClear: true
          });
        });
      }
    },
    updateView: function updateView(type) {
      switch (type) {
        case "images":
          this.set("showImages", true);
          this.set("showIcons", false);
          this.set("showLists", false);

          break;
        case "icons":
          this.set("showImages", false);
          this.set("showIcons", true);
          this.set("showLists", false);
          break;
        case "lists":
          this.set("showImages", false);
          this.set("showIcons", false);
          this.set("showLists", true);
          break;
      }
    },
    actions: {
      delete: function _delete() {
        var _this2 = this;

        var type = this.get("type");
        if (type === "images" || type === "icons") {
          var model = this.get("model");
          var images = model.images;
          var icons = model.icons;
          var image = this.get("asset");
          return this.get("docs").deleteDocById(image).then(function (removed) {
            var image = images.findBy("id", removed.id);
            if (type === "images") {
              images.removeObject(image);
            } else {
              icons.removeObject(image);
            }

            _this2.get('notifications').success('Datei entfernt', {
              autoClear: true,
              clearDuration: 1200
            });
            _this2.set("asset", null);
          });
        }
      },
      updateDoc: function updateDoc(meta) {
        var _this3 = this;

        var docs = this.get("model").images;
        return this.get("docs").updateDocMeta(meta).then(function (newData) {
          var doc = docs.findBy("id", meta.id);
          Ember.set(doc, "title", newData[0].title);
          Ember.set(doc, "description", newData[0].description);
          _this3.get('notifications').success('Änderungen gespeichert', {
            autoClear: true,
            clearDuration: 1200
          });
          _this3.set("asset", null);
        }).catch(function (error) {
          _this3.get('notifications').error('Änderungen konnten nicht gespeichert werden', {
            autoClear: true,
            clearDuration: 1200
          });
          console.log(error);
        });
      },
      saveFile: function saveFile() {
        var dropzone = this.get("dropzone");
        dropzone.processQueue();
      },
      addedFile: function addedFile() {
        this._super.apply(this, arguments);
        this.set('dropzone', Dropzone.forElement('#assetUpload'));
      },
      success: function success(response) {
        var type = this.get("type");
        var cid = this.get("session").get("client_id");
        var aid = this.get("session").get("app_id");
        var docs = this.get("model").images;
        var icons = this.get("model").icons;
        response = JSON.parse(response.xhr.response);
        response = response.resource[0];
        var that = this;
        var meta = {};
        var record = this.get("record");
        if (record.title === undefined || record.title === null) {
          record.title = "";
        }
        meta.title = record.title.replace(/\s/g, "_");
        meta.id = response.id;
        meta.cid = cid;
        meta.aid = aid;
        meta.description = record.description;
        if (type === "icons") {
          meta.fileName = cid + '_' + aid + '_mapIcon_' + meta.title;
        }

        return this.get("docs").updateDocMeta(meta).then(function (newMeta) {
          that.dropzone.removeAllFiles();
          that.set("record", {});
          that.set("new", false);
          that.get('notifications').success("Datei gespeichert", {
            autoClear: true
          });
          that.set("new", null);
          if (type === "icons") {
            icons.addObject(newMeta[0]);
          } else {
            docs.addObject(newMeta[0]);
          }
        });
      }
    }
  });
});