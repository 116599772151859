define("extraapps/admin/structure/route", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        ds: Ember.inject.service(),
        es: Ember.inject.service(),
        model: function model(transition) {
            var _this = this;

            var table = transition.name;
            return this.get("ds").getDataset(table).then(function (data) {
                return _this.get("es").getTypes().then(function (enums) {
                    enums.forEach(function (type) {
                        type.text = type.enumType;
                        type.id = type.enumType;
                    });
                    console.log(enums);
                    return { meta: data, field: {}, lists: enums };
                }).catch(function (error) {
                    console.log(error);
                });
            }).catch(function (error) {
                console.log(error);
            });
        }
    });
});