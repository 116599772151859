define("extraapps/components/news-feed/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        ea: Ember.inject.service(),
        ps: Ember.inject.service(),
        offset: 0,
        init: function init() {
            this._super();

            var filter = { "limit": 3, "offset": 0, order: "datum DESC" };
        },

        newsChanged: function () {
            var news = this.get("news").records.sort(function (a, b) {
                var dateA = new Date(a.datum),
                    dateB = new Date(b.datum);
                return dateB - dateA;
            });
            if (news !== undefined) {
                this.set("items", news.slice(0, 3));
            }
        }.observes("news").on("init"),
        actions: {
            loadNext: function loadNext() {
                var length = this.get("news").records.length;
                var o = this.get("offset");
                if (o + 3 < length) {
                    o = o + 3;
                }

                var news = this.get("news").records.sort(function (a, b) {
                    var dateA = new Date(a.datum),
                        dateB = new Date(b.datum);
                    return dateB - dateA;
                });
                if (news !== undefined) {
                    this.set("items", news.slice(o, o + 3));
                }
                this.set("offset", o);
            },
            loadPrev: function loadPrev() {
                var o = this.get("offset");
                if (o > 3) {
                    o = o - 3;
                }

                var news = this.get("news").records.sort(function (a, b) {
                    var dateA = new Date(a.datum),
                        dateB = new Date(b.datum);
                    return dateB - dateA;
                });
                if (news !== undefined) {
                    this.set("items", news.slice(o, o + 3));
                }
                this.set("offset", o);
            },
            goTo: function goTo(id) {
                var link = "/atlas/aktuelles/eintrag?id=" + id;
                this.get("ea").getController("application").transitionToRoute("/atlas/aktuelles/eintrag?id=" + id);
            }
        }
    });
});