define("extraapps/components/ea-form-geodata/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    location: {},
    drawBox: { polyline: false, polygon: false, circle: false, marker: false },
    drawSettings: {},
    enableEditing: true,
    //zoom: 5,
    //center: [49,8.5],
    rerender: false,
    gc: Ember.inject.service(),
    geoObjectTypes: [{ id: "boundary", text: "Gebietskörperschaft" }, { id: "building", text: "Gebäude" }, { id: "natural", text: "See" }, { id: "waterway", text: "Fluss" }],
    geoObjectType: "boundary",
    importedGeoObjects: [],
    selectedGeoObjects: [],
    drawGeoData: false,
    box: null,
    draw: false,
    search: false,
    drawShape: false,
    showDrawingLayer: false,

    init: function init() {
      this._super();
      var geoCode = this.get("geoCode");
      geoCode = geoCode.split(",");
      for (var a = 0; a < geoCode.length; a++) {
        var option = geoCode[a];
        switch (option) {
          case "import":
            this.set("search", true);
            this.set("import", true);
            break;
          case "address":
            this.set("addressOption", true);
            this.set("address", true);
            break;

        }
        this.set(option, true);
      }
      this.getBounds();
      if (this.import) {
        this.set("search", true);
      } else if (this.draw) {
        this.set("draw", true);
      } else {
        this.set("address", true);
      }
      var that = this;
      var fields = this.get("fields");
      var geoField = fields.findBy("logical_type", "GEOMETRY");
      var geoPath = geoField.path;

      var record = this.get("record");
      var geodata = null;
      if (record[geoPath] !== undefined && record[geoPath] !== null) {
        geodata = JSON.parse(record[geoPath]);
        var array = [];
        if (geodata.type === "GeometryCollection") {
          for (var x = 0; x < geodata.geometries.length; x++) {
            var obj = geodata.geometries[x];
            array.addObject(obj);
          }
        } else {
          array = [geodata];
        }
        this.set("selectedGeoObjects", array);
        this.set("importedGeoObjects", []);
      }
    },

    opend: function () {
      var drawGeoData = this.get("drawGeoData");
      if (drawGeoData) {
        var location = this.get("location");
        var record = this.get("record");
        var fields = this.get("fields");
        var street = fields.findBy("logical_type", "STREET");
        street = record[street.path];
        var number = fields.findBy("logical_type", "HOUSE_NUMBER");
        number = record[number.path];
        var city = fields.findBy("logical_type", "CITY");
        city = record[city.path];
        var pc = fields.findBy("logical_type", "POSTCODE");
        pc = record[pc.path];
        Ember.set(location, "street", street);
        Ember.set(location, "number", number);
        Ember.set(location, "city", city);
        Ember.set(location, "post", pc);
        var selectedGeoObjects = this.get("selectedGeoObjects");
        var geoField = fields.findBy("logical_type", "GEOMETRY");
        var geoData = record[geoField.path];
        if (geoData !== undefined) {
          if (geoData.type === "GeometryCollection") {
            for (var a = 0; a < geoData.geometries.length; a++) {
              var obj = geoData.geometries[a];
              selectedGeoObjects.addObject(obj);
            }
          } else {
            selectedGeoObjects.addObject(geoData);
          }
        }
      }
    }.observes("drawGeoData").on("init"),
    geoObjectTypeChanged: function () {
      var type = this.get("geoObjectType");
      var that = this;
      if (type === "building") {
        this.set("wantsBuilding", true);
        this.set("drawShape", true);
        this.set("drawSettings", this.drawBox);
        this.set("enableEditing", false);
        this.set("showDrawingLayer", false);
      } else {
        this.set("wantsBuilding", false);
        if (this.drawShape) {
          this.set("drawShape", false);
          this.set("rerender", true);
          Ember.run.later(function () {
            that.set("rerender", false);
          });
        }
      }
    }.observes("geoObjectType").on("init"),
    getShapeByPosition: function getShapeByPosition(box) {
      var _this = this;

      box = { "geometries": [box], "type": "GeometryCollection" };
      box = L.geoJson(box).getBounds();
      var that = this;
      return this.get("gc").getShapeByPosition(box).then(function (data) {
        var selectedGeoObjects = _this.get("selectedGeoObjects");
        var buildings = data.elements;
        for (var x = 0; x < buildings.length; x++) {
          var building = buildings[x];
          var geometry = building.geometry;
          var coordinates = [];
          for (var y = 0; y < geometry.length; y++) {
            var coordinate = geometry[y];
            coordinates.addObject([coordinate.lon, coordinate.lat]);
          }
          building = { type: "Polygon", coordinates: [coordinates] };

          selectedGeoObjects.addObject(building);
        }
        _this.getBounds();
      }).catch(function (error) {});
    },
    getBounds: function getBounds(concat) {
      if (concat === undefined) {
        concat = true;
      }
      var selectedGeoObjects = this.get("selectedGeoObjects");
      var importedGeoObjects = this.get("importedGeoObjects");
      var objs = null;
      if (concat) {
        objs = importedGeoObjects.concat(selectedGeoObjects);
      } else {
        objs = selectedGeoObjects;
      }
      var copy = JSON.parse(JSON.stringify(objs));
      for (var a = 0; a < copy.length; a++) {
        var obj = copy[a];
        if (obj.type === "Point") {
          var co = obj.coordinates;
          co = [co[1], co[0]];
          Ember.set(obj, "coordinates", co);
        }
      }
      if (copy.length === 0) {
        copy = [{ type: "Polygon", coordinates: [[[4.042, 45.828], [4.042, 55.478], [15.823, 55.478], [15.820, 45.828]]] }];
      }
      var bbox = { "geometries": copy, "type": "GeometryCollection" };
      bbox = L.geoJson(bbox).getBounds();
      this.set("bounds", bbox);
    },
    actions: {
      getGeoLocation: function getGeoLocation() {
        var _this2 = this;

        var that = this;
        var address = this.get("location");
        var street = address.street;
        var number = address.number;
        var city = address.city;
        var post = address.postcode;
        var values = ["street", "number", "city", "postcode"];
        var proceed = true;
        for (var a = 0; a < values.length; a++) {
          var value = values[a];
          if (address[value] === undefined || address[value] === null) {
            alert(value);
            proceed = false;
          }
        }
        if (proceed) {
          return this.get("gc").geoCodeOneAddress(city, street, number, post).then(function (geoData) {
            geoData = geoData[0];
            _this2.set("goeloc", JSON.stringify({ type: "Point", coordinates: [geoData.lat, geoData.lng] }));
            that.get("selectedGeoObjects").addObject({ type: "Point", coordinates: [geoData.lat, geoData.lng] });
            _this2.getBounds();
          });
        }
      },
      toggleProperty: function toggleProperty(prop) {
        this.toggleProperty(prop);
      },
      useGeodata: function useGeodata() {
        var search = this.get("search");

        var geodata = this.get("selectedGeoObjects");
        if (geodata.length === 1) {
          geodata = geodata[0];
        } else {
          geodata = { type: "GeometryCollection", geometries: geodata };
        }
        this.sendAction("setGeoData", geodata);
        this.set("drawGeoData", false);
        this.getBounds(false);
        this.set("selectedGeoObjects", []);
      },
      close: function close() {
        this.sendAction("close");
      },
      drawn: function drawn(obj) {
        var type = null;
        var nodes = [];
        var coordinates = null;
        var geojson = null;
        var newObj = null;
        switch (obj.layerType) {
          case "rectangle":
            type = "Polygon";
            coordinates = obj.layer._latlngs[0];
            for (var i = 0; i < coordinates.length; i++) {
              var node = [coordinates[i].lng, coordinates[i].lat];
              nodes.addObject(node);
            }
            geojson = { "type": type, "coordinates": [nodes] };
            break;
          case "polygon":
            type = "Polygon";
            coordinates = obj.layer._latlngs[0];
            for (var i = 0; i < coordinates.length; i++) {
              var _node = [coordinates[i].lng, coordinates[i].lat];
              nodes.addObject(_node);
            }
            geojson = { "type": type, "coordinates": [nodes] };
            break;
          case "polyline":
            type = "LineString";
            coordinates = obj.layer._latlngs;
            for (var i = 0; i < coordinates.length; i++) {
              var _node2 = [coordinates[i].lng, coordinates[i].lat];
              nodes.addObject(_node2);
            }
            geojson = { "type": type, "coordinates": nodes };
            break;
        }

        var selectedGeoObjects = this.get("selectedGeoObjects");
        if (this.wantsBuilding) {
          this.getShapeByPosition(geojson);
        } else {
          selectedGeoObjects.addObject(geojson);
        }
      },
      setValue: function setValue(property, value) {
        var that = this;
        switch (property) {
          case "draw":
            this.set("draw", true);
            this.set("address", false);
            this.set("drawShape", true);
            this.set("search", false);
            this.set("rerender", true);
            this.set("wantsBuilding", false);
            this.set("drawSettings", { marker: false });
            this.set("showDrawingLayer", true);
            this.set("enableEditing", true);
            Ember.run.later(function () {
              that.set("rerender", false);
            });
            break;
          case "search":
            this.set("search", true);
            this.set("address", false);
            this.set("drawShape", false);
            this.set("draw", false);
            this.set("rerender", true);
            Ember.run.later(function () {
              that.set("rerender", false);
            });
            break;
          case "address":
            this.set("draw", false);
            this.set("drawShape", false);
            this.set("search", false);
            this.set("rerender", true);
            this.set("wantsBuilding", false);
            this.set("drawSettings", { marker: false });
            this.set("showDrawingLayer", false);
            this.set("enableEditing", false);
            this.set("address", true);
            Ember.run.later(function () {
              that.set("rerender", false);
            });
        }
        this.set(property, value);
      },

      getObjectByName: function getObjectByName(name) {
        var _this3 = this;

        var that = this;
        this.set("importedGeoObjects", []);
        return this.get("gc").getByName(name).then(function (data) {
          var objects = [];
          var type = _this3.get("geoObjectType");
          for (var x = 0; x < data.length; x++) {
            var json = data[x];
            if (json.class === type) {
              objects.addObject(json.geojson);
            }
          }
          _this3.set("importedGeoObjects", objects);
          _this3.getBounds();
        }).catch(function (error) {
          console.log(error);
        });
      },
      selectObject: function selectObject(obj) {
        var that = this;
        var importedGeoObjects = this.get("importedGeoObjects");
        var selectedGeoObjects = this.get("selectedGeoObjects");
        importedGeoObjects.removeObject(obj);
        selectedGeoObjects.addObject(obj);
      },
      deselectObject: function deselectObject(obj) {
        var that = this;
        var importedGeoObjects = this.get("importedGeoObjects");
        var selectedGeoObjects = this.get("selectedGeoObjects");
        importedGeoObjects.addObject(obj);
        selectedGeoObjects.removeObject(obj);
      }
    }

  });
});