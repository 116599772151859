define('extraapps/admin/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        ea: Ember.inject.service(),
        session: Ember.inject.service(),
        queryParams: ["app"],
        app: null,

        notifications: Ember.inject.service('notification-messages'),
        modelChanged: function () {
            var model = this.get("model");
            if (model !== undefined && model !== null) {
                var app = this.get("app");
                if (app === undefined || app === null) {
                    app = model.apps[0].title;
                    this.set("app", app);
                }
            }
        }.observes("model").on("init"),
        actions: {

            addApp: function addApp() {
                var _this = this;

                var name = this.get("name");
                var path = this.get("pathSegment");
                var session = this.get("session");
                var appData = { title: name, pathSegment: path, cid: session.get("client_id") };
                return this.get("ea").insertApp(appData).then(function (app) {

                    var template = { "handlebarsTemplateType": "PLAIN", "hbs": "", "identifier": 'app_' + appData.title + '_page_' + appData.title };
                    var route = { "name": appData.title, "pathSegment": appData.pathSegment, "cid": session.get("client_id"), "aid": app.id, "status": "DRAFT", "visibility": "PRIVATE" };
                    Ember.set(template, "aid", app.id);
                    return _this.get("ea").updateRoutes(route).then(function (route) {
                        return _this.get("ea").storeTemplate(template).then(function (template) {
                            return _this.get("ea").addTemplateToRoute(template.id, route.pathSegment).then(function (link) {
                                _this.get('notifications').success('App erstellt', { // TODO: use i18n text...
                                    autoClear: true
                                });
                            }).catch(function (error) {
                                console.log('ERROR: ' + JSON.stringify(error));
                                _this.get('notifications').error('Beim erstellen der App trat ein Fehler auf.', { // TODO: use i18n text...
                                    autoClear: true
                                });
                            });
                        }).catch(function (error) {
                            console.log('ERROR: ' + JSON.stringify(error));
                            _this.get('notifications').error('Beim erstellen der App trat ein Fehler auf.', { // TODO: use i18n text...
                                autoClear: true
                            });
                        });
                    }).catch(function (error) {
                        console.log('ERROR: ' + JSON.stringify(error));
                        _this.get('notifications').error('Beim erstellen der App trat ein Fehler auf.', { // TODO: use i18n text...
                            autoClear: true
                        });
                    });
                }).catch(function (error) {
                    console.log('ERROR: ' + JSON.stringify(error));
                    _this.get('notifications').error('Beim erstellen der App trat ein Fehler auf.', { // TODO: use i18n text...
                        autoClear: true
                    });
                });
            },
            getSession: function getSession() {
                var cid = this.get("session").get("client_id");
                alert(cid);
            },
            updateApp: function updateApp(app) {
                this.set("app", app);
            },
            signOut: function signOut() {
                this.get("session").invalidate();
                this.transitionToRoute("login");
            }
        }
    });
});