define("extraapps/components/ea-select/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        es: Ember.inject.service(),
        init: function init() {
            var _this = this;

            this._super();
            var type = this.get("field.enumType");
            return this.get("es").getAttributesForType(type).then(function (values) {
                values.sort(function (a, b) {
                    return a.sequenceNumber - b.sequenceNumber;
                });
                for (var a = 0; a < values.length; a++) {
                    var value = values[a];
                    Ember.set(value, "id", value.enumText);
                    Ember.set(value, "text", value.enumText);
                }
                _this.set("values", values);
            });
        }
    });
});