define("extraapps/services/globals", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    applicationName: "extraapps",

    // Einstellungen für Host C2
    rootURL: "http://kombivi.de:8080/api/v2/extraapps/",
    dreamfactoryAPIKey: "d07d19689428d657ee1a916fcbefea6c8b4674107e53733c6ebbde7492d67c49",

    // Veraltete Einstellungen für Host C4:
    // dreamfactoryAPIKey: "1be0df01210d7abe557e3052b2803e41b22b57430ad76660b93deff60b23e406",
    // baseURL: "http://ressourcenrechner.de:9090/api/v2/extraapps/",

    buildURLgetRecords: function buildURLgetRecords(tableName, filter) {
      if (filter === undefined) {
        return this.rootURL + "_table/" + tableName + "?api_key=" + this.dreamfactoryAPIKey;
      } else {
        return this.rootURL + "_table/" + tableName + "?filter=" + filter + "&api_key=" + this.dreamfactoryAPIKey;
      }
    }

  });
});