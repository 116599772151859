define('extraapps/admin/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    activatePace: function () {
      return Pace.stop();
    }.on('activate'),
    title: 'Admin',
    ea: Ember.inject.service(),
    session: Ember.inject.service(),
    model: function model() {
      var _this = this;

      return this.get("ea").getApps().then(function (apps) {
        var app = apps[0];
        _this.get("session").set("app_id", app.id);
        _this.get("session").set("client_id", app.cid);
        return {
          apps: apps
        };
      }).catch(function (error) {
        _this.transitionTo("login");
      });
    }
  });
});