define("extraapps/helpers/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      inject = Ember.inject;
  exports.default = Ember.Controller.extend({
    ea: Ember.inject.service(),
    session: Ember.inject.service(),
    windoc: inject.service(),

    widthChanged: function () {
      var width = this.get("windoc.scrollWidth");
      if (width < 768) {
        this.set("xs", true);
      } else {
        this.set("xs", false);
      }
    }.observes("windoc.scrollWidth").on("init")
  });
});