define('extraapps/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.transition(this.hasClass('fade_trans'), this.use('fade'));
    this.transition(this.hasClass('left_right'), this.use('toLeft'));
    this.transition(this.fromRoute('admin.pages'), this.toRoute('admin.page'), this.use('fade'), this.reverse('fade'));
    this.transition(this.fromRoute('admin.pages'), this.toRoute('admin.datasets'), this.use('fade'), this.reverse('fade'));

    this.transition(this.fromRoute('admin.datasets'), this.toRoute('admin.dataset'), this.use('fade'), this.reverse('fade'));
  };

  ;
});