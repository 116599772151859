define("extraapps/components/delete-button-enums/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        remove: false,
        actions: {
            delete: function _delete(type, value) {
                this.sendAction("delete", type, value);
                this.set("remove", false);
            }
        }
    });
});