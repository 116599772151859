define('extraapps/util/promiseObject', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Promise = Ember.RSVP.Promise;

    /**
     * See https://github.com/emberjs/data/blob/master/addon/-private/system/promise-proxies.js
     */
    var PromiseObject = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

    var promiseObject = function promiseObject(promise, label) {
        return PromiseObject.create({
            promise: Promise.resolve(promise, label)
        });
    };

    var PromiseArray = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);

    var promiseArray = function promiseArray(promise, label) {
        return PromiseArray.create({
            promise: Promise.resolve(promise, label)
        });
    };

    exports.promiseObject = promiseObject;
    exports.promiseArray = promiseArray;
});