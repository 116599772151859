define('extraapps/services/api', ['exports', 'extraapps/config/environment', 'extraapps/util/promiseObject'], function (exports, _environment, _promiseObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        ajax: Ember.inject.service(),
        ea: Ember.inject.service(),
        event: function event(type, _event) {
            var URL = _environment.default.backend.baseURL + '/api/event/' + type;
            var that = this;
            var json = JSON.stringify(_event);
            var params = {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: json
            };

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').post(URL, params).then(function (data) {

                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        service: 'api',
                        method: 'storeSettings',
                        args: {
                            container: json
                        },
                        error: error
                    };
                    reject(reason);
                });
            });

            return (0, _promiseObject.promiseObject)(promise, 'api.event(' + JSON.stringify(_event) + ')');
        },
        getTexts: function getTexts() {
            var that = this;
            var URL = _environment.default.backend.baseURL + '/api/texts/global';
            var headers = that.get("ea").getDefaultsHeaders();
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, { headers: headers }).then(function (data) {
                    var style = data.resource;
                    resolve(style);
                }).catch(function (error) {
                    var reason = {
                        service: 'api',
                        method: 'getTexts',
                        args: {},
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, 'api.getSettingJSON()');
        },
        getTextsWith: function getTextsWith(textKeyPattern) {
            var that = this;
            var URL = _environment.default.backend.baseURL + '/api/texts/' + textKeyPattern;
            var headers = that.get("ea").getDefaultsHeaders();
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, { headers: headers }).then(function (data) {
                    var style = data.resource;
                    resolve(style);
                }).catch(function (error) {
                    var reason = {
                        service: 'api',
                        method: 'getTextsWith',
                        args: {},
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, 'api.getSettingJSON()');
        },
        /* TODO TEXT
        insertText:function(textKey, text){
          var that = this;
          var URL = `${ENV.backend.baseURL}/api/texts`;
          let headers = that.get("ea").getDefaultsHeaders();
          let json = {headers: headers, data:{}}
           let promise = new Ember.RSVP.Promise(
              function (resolve, reject) {
                  that.get('ajax').put(URL).then(data => {
                      var style = data.resource;
                      resolve(style);
                  }).catch(error => {
                      var reason = {
                          service: 'api',
                          method: 'getTexts',
                          args: {
                          },
                          error: error
                      };
                      reject(reason);
                  });
              }
          );
          return promiseArray(promise, `api.getSettingJSON()`);
        },
        */
        getSettingJSON: function getSettingJSON(name) {
            var that = this;
            var URL = _environment.default.backend.baseURL + '/api/settingAsJSON/' + name;
            var headers = that.get("ea").getDefaultsHeaders();
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, { headers: headers }).then(function (data) {
                    var style = data;
                    resolve(style);
                }).catch(function (error) {
                    var reason = {
                        service: 'api',
                        method: 'getSettingJSON',
                        args: {},
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'api.getSettingJSON()');
        },
        getSettings: function getSettings(filter) {
            var that = this;
            var headers = this.get("ea").getDefaultsHeaders();
            console.log(headers);
            if (filter === undefined) {
                var URL = _environment.default.backend.baseURL + '/api/settings';
            } else {
                var URL = _environment.default.backend.baseURL + '/api/settings/' + filter;
            }
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, { headers: headers }).then(function (data) {
                    var styles = data.resource;
                    resolve(styles);
                }).catch(function (error) {
                    var reason = {
                        service: 'api',
                        method: 'getSettings',
                        args: {},
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, 'api.getSettings()');
        },
        removeSettings: function removeSettings(setting) {
            var URL = _environment.default.backend.baseURL + '/api/settings';
            var that = this;
            var json = JSON.stringify({ resource: [setting] });
            var params = {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: json
            };

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, params).then(function (data) {

                    resolve(data.resource);
                }).catch(function (error) {
                    var reason = {
                        service: 'api',
                        method: 'deleteSettings',
                        args: {
                            container: json
                        },
                        error: error
                    };
                    reject(reason);
                });
            });

            return (0, _promiseObject.promiseArray)(promise, 'api.deleteSettings(' + JSON.stringify(settings) + ')');
        },
        storeSettings: function storeSettings(settings) {
            if (settings === null) {
                return null;
            }

            var URL = _environment.default.backend.baseURL + '/api/settings';
            var that = this;
            var json = JSON.stringify({ resource: [settings] });
            var params = {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: json
            };

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, params).then(function (data) {

                    resolve(data.resource);
                }).catch(function (error) {
                    var reason = {
                        service: 'api',
                        method: 'storeSettings',
                        args: {
                            container: json
                        },
                        error: error
                    };
                    reject(reason);
                });
            });

            return (0, _promiseObject.promiseArray)(promise, 'api.storeSettings(' + JSON.stringify(settings) + ')');
        },

        createAssetSetting: function createAssetSetting(response, name, description) {
            var that = this;
            var URL = _environment.default.backend.baseURL + '/api/settings';

            var mimeType = response.mimeType;
            mimeType = mimeType.split("/");
            var docType = mimeType[0];

            var metadata = JSON.stringify({ id: response.id, name: name, description: description, type: docType, format: mimeType[1], size: response.documentSize });
            var settings = {
                name: 'asset.' + docType + '.' + response.id,
                setting: metadata
            };
            var nutzlast = [settings];
            var json = JSON.stringify(nutzlast);
            var params = {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: json
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, params).then(function (data) {
                    var storedSettings = data;
                    resolve(storedSettings);
                }).catch(function (error) {
                    var reason = {
                        service: 'api',
                        method: 'addToSet',
                        args: {
                            container: json
                        },
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'api.addToSet()');
        },

        addToSet: function addToSet(set, memberName) {
            var that = this;
            var URL = _environment.default.backend.baseURL + '/api/settings';

            var settings = {
                name: set + '.' + memberName,
                setting: memberName
            };
            var nutzlast = [settings];
            var json = JSON.stringify(nutzlast);
            var params = {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: json
            };
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, params).then(function (data) {
                    var storedSettings = data;
                    resolve(storedSettings);
                }).catch(function (error) {
                    var reason = {
                        service: 'api',
                        method: 'addToSet',
                        args: {
                            container: json
                        },
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'api.addToSet()');
        }
    });
});