define("extraapps/admin/importer/controller", ["exports", "extraapps/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ["ds"],
        ds: null,
        ea: Ember.inject.service(),
        notifications: Ember.inject.service('notification-messages'),
        api: Ember.inject.service(),
        selected: "FIELD",
        valueTypes: [{ text: "Feld", id: "FIELD" }, { text: "Lat/Lng", id: "geo" }, { text: "JS-Funktion", id: "FUNCTION" }, { text: "URL eines neuen Vorschaubilds", id: "PREVIEW_IMAGE" }, { text: "Id eines bestehenden Vorschaubilds", id: "PREVIEW_IMAGE_ID" }, { text: "Name eines bestehenden Vorschaubilds", id: "PREVIEW_IMAGE_NAME" }, { text: "URL eines neuen Dokuments", id: "DOCUMENT" }, { text: "Id eines bestehenden Dokuments", id: "DOCUMENT_ID" }],
        valueType: "FIELD",
        valueTypeChanged: function () {
            var value = this.get("valueType");
            if (value === "geo") {
                this.set("geo", true);
                this.set("isField", false);
            } else {
                this.set("geo", false);
                this.set("isField", true);
            }
            if (value !== "geo" && value !== "field") {
                this.set("value", null);
            }
        }.observes("valueType").on("init"),
        encodings: [{ id: "UTF-8", text: "UTF-8" }],
        fieldSeparators: [{ id: '\t', text: "Tab" }, { id: ';', text: ";" }, { id: ',', text: "," }, { id: ':', text: ":" }],
        recordSeparators: [{ id: "\n", text: "Zeilenumbruch" }],
        import: {
            fieldSeparator: '\t',
            recordSeparator: "\n",
            encoding: "UTF-8",
            datasetName: null,
            hasHeaderLine: true,
            importFields: [],
            stripWhitespace: false,
            validate: false,
            ignoreWhitespaceAroundFieldValue: false
        },
        showImport: false,
        dropzone: null,

        init: function init() {
            var headers = this.get("ea").getDefaultHeaders();
            var that = this;
            var dropzoneOptions = {
                options: {
                    url: _environment.default.backend.baseURL + "/doc/import" // required early, otherwise component will complain about missing url
                }
            };

            this.dropzone = dropzoneOptions;
        },

        saveFile: function saveFile() {
            var ds = this.get("ds");
            var imp = this.get("import");
            imp.datasetName = ds;

            var settings = JSON.stringify(imp);
            var params = {};
            params.settings = settings;

            var dropzone = this.get("dropzone");
            Ember.set(dropzone.options, "params", params);
            Ember.set(dropzone.options, "paramName", "data");
            var headers = this.get("ea").getDefaultHeaders();
            delete headers["Content-Type"]; // do not set Content-Type, let dropzone set multipart...
            Ember.set(dropzone.options, "headers", headers);
            dropzone.processQueue();
        },

        fieldChanged: function () {
            var field = this.get("field");
            if (field !== undefined && field !== null) {
                var value = JSON.parse(JSON.stringify(field.path));
                if (this.get("valueType") === "field") {
                    this.set("value", value);
                }
            }
        }.observes("field").on("init"),
        settingChanged: function () {
            var setting = this.get("setting");
            if (setting !== undefined && setting !== null) {
                var imp = this.get("import");
                this.set("name", setting.setting.name);
                this.set("showImport", false);
                console.log(setting.setting.fields);
                Ember.set(imp, "importFields", setting.setting.fields);
            }
        }.observes("setting").on("init"),
        selectedChanged: function () {
            var selected = this.get("selected");
            switch (selected) {
                case "fun":
                    this.set("fun", true);
                    this.set("geo", false);
                    this.set("fieldS", false);
                    break;
                case "field":
                    this.set("fun", false);
                    this.set("geo", false);
                    this.set("fieldS", true);
                    break;
                case "geo":
                    this.set("fun", false);
                    this.set("geo", true);
                    this.set("fieldS", false);
                    break;
            }
        }.observes("selected").on("init"),

        actions: {
            error: function error(first, second, third) {
                second = second.replace('\n', '\r\n');
                this.set("errors", second);
                console.log(second);
                this.set("showErrors", true);
            },
            removeField: function removeField(field) {
                var fields = this.get("import").importFields;
                fields.removeObject(field);
            },
            saveFields: function saveFields(name) {
                console.log(this);
                var that = this;
                var setting = this.get("setting");
                if (setting === undefined) {
                    setting = {};
                }
                var ds = this.get("ds");
                var importFields = this.get("import.importFields");
                setting.name = "ea.importFields." + ds;
                setting.setting = JSON.stringify({ name: name, fields: importFields });
                return this.get("api").storeSettings(setting).then(function (setting) {
                    that.get('notifications').success("Felddefinitionen gespeichert", {
                        autoClear: true
                    });
                    that.set("showSave", false);
                }).catch(function (error) {
                    alert(error);
                });
            },
            save: function save() {
                this.saveFile();
            },

            addField: function addField() {
                var importFields = this.get("import.importFields");
                var fun = this.get("fun");
                var field = this.get("field");
                var type = this.get("valueType");
                var value = this.get("value");
                var importField = {
                    type: null,
                    name: null,
                    value: null
                };

                if (type === "geo") {
                    importField.type = "FUNCTION";
                    importField.name = field.path;
                    var lon = this.get("lon");
                    var lat = this.get("lat");
                    importField.value = "'{ \"type\": \"Point\", \"coordinates\": [' + " + lon + " +', '+ " + lat + " +'] }'";
                } else {
                    importField.type = type;
                    importField.name = field.path;
                    importField.value = value;
                }
                importFields.addObject(importField);
            },
            addedFile: function addedFile() {
                this._super.apply(this, arguments);
                this.set('dropzone', Dropzone.forElement('#assetUpload'));
            },

            success: function success(response) {

                var ds = this.get("ds");
                this.get("notifications").success("Daten importiert", {
                    autoClear: true
                });
                var imp = {
                    fieldSeparator: '\t',
                    recordSeparator: "\n",
                    encoding: "UTF-8",
                    datasetName: null,
                    hasHeaderLine: true,
                    importFields: []
                };
                this.set("selected", "field");
                this.set("column", null);
                this.set("fun", null);
                this.set("lat", null);
                this.set("lon", null);
                this.set("setting", null);
                this.set("field", null);
                this.set("import", imp);
                this.transitionToRoute("admin.view", { queryParams: { datasets: ds } });
            }
        }
    });
});