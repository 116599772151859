define("extraapps/components/ea-list-view/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        ea: Ember.inject.service(),
        api: Ember.inject.service(),
        init: function init() {
            var _this = this;

            this._super();
            var that = this;
            var list = this.get("list");
            var fields = list.fields;
            var columns = [];

            for (var a = 0; a < fields.length; a++) {
                var field = fields[a];
                if (field.logical_type !== "IMAGE" && field.path !== "owner" && field.logical_type !== "GEOMETRY") {
                    var width = field.label.length * 20;
                    var filter = false;
                    if (field.logical_type === "TEXT") {
                        filter = {
                            type: 'search'
                        };
                    }
                    var column = { propertyName: field.path, header: field.label };
                    columns.addObject(column);
                }
            }
            this.set("columns", columns);
            this.set("records", list.records);

            this.get("api").getSettings("setting." + list.path + "." + list.name).then(function (settings) {
                if (settings.length !== 0) {

                    var setting = settings[0];
                    var link = JSON.parse(setting.setting).link;
                    if (link !== undefined) {
                        var btn = [{ propertyName: "", header: "", component: "goto-button-model-table" }];
                        var fullCol = btn.concat(columns);
                        _this.set("columns", fullCol);
                        _this.set("link", link);
                    }
                }
                Ember.run.later(function () {
                    that.set("show", true);
                }, 100);
            });
        },

        actions: {
            goTo: function goTo(record) {

                var appCont = this.get("ea").getController("application");

                var link = this.get("link");
                link = link + "?id=" + record.id;
                appCont.transitionToRoute(link);
            }
        }

    });
});