define("extraapps/components/remove-field/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            delete: function _delete(field) {
                this.sendAction("delete", field);
            }
        }
    });
});