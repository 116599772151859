define("extraapps/components/ds-viewer-map-layout/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            open: function open() {
                this.sendAction("open", "layoutMenu");
            },
            close: function close() {
                this.sendAction("close", "layoutMenu");
            },
            setLayout: function setLayout(layout) {
                this.sendAction("setLayout", layout);
            }
        }
    });
});