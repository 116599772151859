define("extraapps/components/ea-breadcrumbs/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        ea: Ember.inject.service(),
        style: "triangle",
        start: "start",
        init: function init() {
            this._super();
            var path = Ember.getOwner(this).lookup('controller:application').currentPath;
            if (path.indexOf(".index") !== -1) {
                path = path.split(".index");
                path = path[0];
            }
            var conrtroller = this.get("ea").getController(path);
            var url = conrtroller.get("model").path;
            var routes = conrtroller.get("model").routeNames;
            routes = routes.split(",");
            routes.removeObject(routes[0]);
            if (url === undefined) {
                url = "startseite/thema/subthema/subsubthema";
            }
            url = url.split("/");
            url.removeObject(url[0]);
            this.set("start", url[0]);
            var style = null;
            var newStyle = this.get("style");
            if (style !== undefined) {
                switch (newStyle) {
                    case "triangle":
                        style = "triangle";break;
                    case "steps":
                        style = "cd-multi-steps";break;
                    case "dots":
                        style = "cd-multi-steps text-top";break;
                    case "countedDots":
                        style = "cd-multi-steps text-top count";break;
                }
                this.set("style", style);
            }

            var segments = [];

            for (var a = 0; a < url.length; a++) {
                var segment = url[a];
                var level = {};
                var target = {};
                var text = {};
                var name = url[a];
                name = name.replace(/ae/g, 'ä');
                name = name.replace(/oe/g, 'ö');
                name = name.replace(/ue/g, 'ü');

                switch (a) {
                    case 0:
                        level.one = true;
                        target.r1 = url[0];
                        text.r1 = routes[0];
                        break;
                    case 1:
                        level.two = true;
                        target.r1 = url[0];
                        text.r1 = routes[0];
                        target.r2 = url[1];
                        text.r2 = routes[1];
                        break;
                    case 2:
                        level.three = true;
                        target.r1 = url[0];
                        text.r1 = routes[0];
                        target.r2 = url[1];
                        text.r2 = routes[1];
                        target.r3 = url[2];
                        text.r3 = routes[2];
                        break;
                    case 3:
                        level.four = true;
                        target.r1 = url[0];
                        target.r2 = url[1];
                        target.r3 = url[2];
                        target.r4 = url[3];
                        break;
                    case 4:
                        level.five = true;
                        target.r1 = url[0];
                        target.r2 = url[1];
                        target.r3 = url[2];
                        target.r4 = url[3];
                        target.r5 = url[4];
                        break;
                    case 5:
                        level.six = true;
                        target.r1 = url[0];
                        target.r2 = url[1];
                        target.r3 = url[2];
                        target.r4 = url[3];
                        target.r5 = url[4];
                        target.r6 = url[5];
                        break;
                    case 6:
                        level.seven = true;
                        target.r1 = url[0];
                        target.r2 = url[1];
                        target.r3 = url[2];
                        target.r4 = url[3];
                        target.r5 = url[4];
                        target.r6 = url[5];
                        target.r7 = url[6];
                        break;
                }

                segments.addObject({ id: a + 1, name: name, level: level, target: target, text: text });
            }
            this.set("segments", segments);
        }
    });
});