define('extraapps/components/record-docs-upload/component', ['exports', 'extraapps/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        docs: Ember.inject.service(),
        notifications: Ember.inject.service('notification-messages'),
        session: Ember.inject.service(),
        ea: Ember.inject.service(),
        baseUrl: _environment.default.backend.baseURL + '/doc/',
        dropzone: null,
        record: {},
        saveChanged: function () {
            if (this.get("save") !== undefined) {
                this.saveFile();
            }
        }.observes("save").on("init"),
        saveFile: function saveFile() {
            var that = this;
            var dropzone = this.get("dropzone");
            var name = this.get("model.meta.title");
            dropzone.processQueue();
        },
        actions: {

            addedFile: function addedFile() {
                this._super.apply(this, arguments);
                this.set('dropzone', Dropzone.forElement('#assetUpload'));
            },
            success: function success(response) {
                var _this = this;

                var type = this.get("type");
                var cid = this.get("session").get("client_id");
                var aid = this.get("session").get("app_id");
                response = JSON.parse(response.xhr.response);
                response = response.resource[0];
                var api = this.get("api");
                var that = this;
                var meta = {};
                var record = this.get("record");
                meta.title = record.title.replace(/\s/g, "_");
                meta.id = response.id;
                meta.cid = cid;
                meta.aid = aid;
                meta.description = record.description;
                if (type === "icons") {
                    meta.fileName = cid + '_' + aid + '_mapIcon_' + meta.title;
                }
                var dropzone = this.get("dropzone");

                return this.get("docs").updateDocMeta(meta).then(function (newMeta) {
                    _this.sendAction("add", response.id);
                    _this.sendAction("saveFiles");
                    that.dropzone.removeAllFiles();
                    that.set("record", {});
                    that.get('notifications').success("Datei gespeichert", {
                        autoClear: true
                    });
                });
            }
        }
    });
});