define('extraapps/admin/assets/route', ['exports', 'extraapps/util/util'], function (exports, _util) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    docs: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),

    model: function model(transition) {
      var that = this;
      return this.get("docs").getDocsMeta().then(function (assets) {
        var icons = assets.filter(_util.filterIcons);
        var images = assets.filter(_util.filterAssets);
        return { images: images, icons: icons };
      }).catch(function (error) {
        console.log(error);
        that.get('notifications').error("Fehler beim Laden der Daten", {
          autoClear: true
        });
      });
    },
    isAsset: function isAsset(asset) {
      if (!(asset.title.indexOf("mapIcon") !== -1)) {
        return true;
      }
    }
  });
});