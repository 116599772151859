define('extraapps/components/ea-image/component', ['exports', 'extraapps/config/environment', 'ember-in-viewport'], function (exports, _environment, _emberInViewport) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_emberInViewport.default, {

        docs: Ember.inject.service(),
        ea: Ember.inject.service(),
        session: Ember.inject.service(),
        imgId: "",
        imgName: "",
        loading: true,
        inView: false,
        didEnterViewport: function didEnterViewport() {
            var _this = this;

            var session = this.get("session");
            var lazyLoad = this.get("lazyLoad");
            if (lazyLoad === undefined) {
                lazyLoad = true;
            }
            if (lazyLoad) {

                var imgName = this.get("imgName");
                var imgId = this.get("imgId");
                var _session = this.get("session");

                var that = this;

                if (imgName !== undefined && imgName !== null && imgName !== "") {
                    var _URL = _environment.default.backend.assetNameBaseURL + '/' + imgName;
                    that.set("src", _URL);
                } else if (imgId !== undefined && imgId !== null && imgId !== "" && isNaN(imgId) === false) {
                    var _URL2 = _environment.default.backend.assetIdBaseURL + '/' + imgId;
                    that.set("src", _URL2);
                    this.get("docs").getDocMeta(imgId).then(function (meta) {
                        _this.set("meta", meta);
                        _this.set("rerender", true);
                        Ember.run.later(function () {
                            that.set("loading", false);
                            that.set("rerender", false);
                        }, 100);
                    });
                }
                if (_session.client_id !== undefined || _session.client_id !== null) {
                    URL = URL + '?cid=' + _session.client_id;
                }
            }
        },

        imgNameChanged: function () {
            var session = this.get("session");
            var lazyLoad = this.get("lazyLoad");
            if (lazyLoad === undefined) {
                lazyLoad = false;
            }
            if (lazyLoad !== true) {
                var inView = this.get("inView");
                var imgName = this.get("imgName");
                if (imgName !== undefined && imgName !== null && imgName !== "") {
                    var _URL3 = _environment.default.backend.assetNameBaseURL + '/' + imgName + '?cid=' + session.client_id;

                    this.set("src", _URL3);
                    var that = this;
                    this.set("rerender", true);
                    Ember.run.later(function () {
                        that.set("rerender", false);
                    }, 100);
                }
            }
        }.observes("imgName").on("init"),
        imgIdChanged: function () {
            var session = this.get("session");
            var lazyLoad = this.get("lazyLoad");
            if (lazyLoad === undefined) {
                lazyLoad = true;
            }
            if (lazyLoad !== true) {
                var inView = this.get("inView");
                var imgId = this.get("imgId");
                if (imgId !== undefined && imgId !== null && imgId !== "") {
                    var _URL4 = _environment.default.backend.assetIdBaseURL + '/' + imgId + '?cid=' + session.client_id;
                    this.set("src", _URL4);
                    var that = this;
                    this.set("rerender", true);
                    Ember.run.later(function () {
                        that.set("rerender", false);
                    }, 100);
                }
            }
        }.observes("imgId").on("init")
    });
});