define("extraapps/components/ea-cards/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            var _this = this;

            this._super();
            var model = this.get("model");
            var styles = model.styles;
            if (model.data.length < 2) {
                this.set("hideTabs", true);
            }
            model.data.forEach(function (ds) {
                Ember.set(ds, "card", ds.dataset + "card");
                if (styles !== undefined) {
                    //since admine/dataset loads only a single style
                    var popUpSetting = styles.findBy("name", "map.popup." + ds.dataset);

                    var name = ds.dataset.replace(/_/g, "");
                    name = name + "card";
                    if (popUpSetting !== undefined) {
                        //name = name.replace(/_/g, "");
                        Ember.set(ds, "card", name);
                        Ember.set(ds, "hasPopUp", true);
                        var templateRaw = popUpSetting.setting;
                        if (templateRaw === undefined) {
                            templateRaw = popUpSetting.lob;
                        }
                        var string = templateRaw.split("}}");
                        string = string[0] + "}}";
                        var cleanTemplate = templateRaw.replace(string, " ");

                        cleanTemplate = cleanTemplate.replace('{{/popup-layer}}', " ");
                        try {
                            var templateCompiled = Ember.HTMLBars.compile(cleanTemplate);
                            Ember.TEMPLATES[name] = templateCompiled;
                        } catch (e) {
                            console.log("Could not compile partial template " + ds.dataset + ":");
                            console.log(e.message);
                        }
                    }
                }
                _this.set("show", true);
            });
        }
    });
});