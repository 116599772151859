define("extraapps/components/field-validation/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super();
            var val = this.get("validation");
            switch (val.type) {
                case "requierd":
                    this.set("text", "notwendig");
                    break;
                case "number":
                    this.set("text", "Zahl");
                    break;
                case "integer":
                    this.set("text", "Ganzzahl");
                    break;
                case "string":
                    this.set("text", "Text");
                    break;
                case "min":
                    this.set("text", "min");
                    break;
                case "max":
                    this.set("text", "max");
                    break;
                case "minLength":
                    this.set("text", "minLength");
                    break;
                case "maxLength":
                    this.set("text", "maxLength");
                    break;
                case "mail":
                    this.set("text", "mail");
                    break;
                case "match":
                    this.set("text", "match");
                    break;
                case "noMatch":
                    this.set("text", "noMatch");
                    break;
            }
        },

        actions: {
            removeVal: function removeVal(val) {
                this.sendAction("removeVal", val);
            } }
    });
});