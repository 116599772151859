define("extraapps/components/ea-form-map/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        show: false,
        init: function init() {
            this._super();
            this.set("shape", "MultiPoint");
            this.set("nodes", []);
            this.set("newNode", null);
            var geometry = this.get("geometry");
            if (geometry !== null && geometry !== undefined) {

                if (geometry.type === "Point") {
                    this.set("pointData", true);
                    var location = [geometry.coordinates[1], geometry.coordinates[0]];
                    this.set("location", location);
                    this.set("initCorrection", true);
                } else {
                    this.set("obj", geometry);
                }
                this.set("show", true);
            }
        },

        newShape: Ember.computed('nodes.@each.coordinates', 'newNode', function () {
            var nodes = this.get("nodes");
            var newNode = this.get("newNode");
            var s = this.get("shape");
            var shape = null;
            if (nodes.length !== 0) {
                if (nodes.length === 1 && s === "MultiPolygon") {
                    s = "LineString";
                }
                shape = {
                    type: s,
                    coordinates: nodes.map(function (node) {
                        return [node.coordinates[1], node.coordinates[0]];
                    })
                };
                if (newNode !== undefined && newNode !== null) {
                    shape.coordinates.addObject(newNode.coordinates);
                }
                if (s === "Polygon") {
                    shape.coordinates = [shape.coordinates];
                }
                return shape;
            } else {
                return null;
            }
        }),
        newShapeChanged: function () {

            var shape = this.get("newShape");
            if (shape !== null && shape !== undefined) {

                this.set("showNewShape", true);
                if (shape.coordinates.length > 1) {
                    this.set("showNewShape", true);
                } else if (Array.isArray(shape.coordinates)) {
                    if (shape.coordinates[0].length > 2) {
                        this.set("showNewShape", true);
                    }
                } else {
                    this.set("showNewShape", false);
                }
            }
        }.observes("newShape.coordiantes").on("init"),

        dbClick: function dbClick(e) {
            this.set("click", false);
            var draw = this.get("showDraw");
            if (draw) {
                var that = this;
                var lat = e.latlng.lat;
                var lng = e.latlng.lng;
                var shape = this.get("shape");
                var nodes = this.get("nodes");
                if (shape !== "MultiPoint") {
                    nodes.addObject({ type: "Point", coordinates: [lat, lng] });
                    var newShape = this.get("newShape");
                    this.sendAction("setGeoData", newShape);
                }

                this.set("showDraw", false);
            }
        },

        sClick: function sClick(e) {
            this.set("click", false);
            var draw = this.get("showDraw"); // check if drawing is enabled
            if (draw) {
                var that = this;
                var lat = e.latlng.lat;
                var lng = e.latlng.lng;
                var shape = this.get("shape");
                var nodes = this.get("nodes");
                if (shape === "MultiPoint") {
                    this.set("nodes", [{ type: "Point", coordinates: [lat, lng] }]);
                    this.set("newPoint", true);
                    this.sendAction("setGeoData", { type: "Point", coordinates: [lng, lat] });
                } else {
                    this.set("newPoint", false);
                    nodes.addObject({ type: "Point", coordinates: [lat, lng] });
                }
            }
        },
        checkClick: function checkClick(e) {
            var blockMapClick = this.get("blockMapClick");
            if (blockMapClick === true) {
                return;
            }
            var check = this.get("click");
            if (check === true) {
                this.sClick(e);
            }
        },


        dataChanged: function () {

            var geometry = this.get("geometry");
            if (geometry !== undefined) {
                var initCorrection = this.get("initCorrection");
                if (initCorrection === true) {
                    this.set("initCorrection", false);
                } else {
                    if (geometry.type === "Point") {
                        this.set("pointData", true);
                        this.set("location", geometry.coordinates);
                    }
                    this.set("show", true);
                }
            }
        }.observes("geometry").on("didRender"),
        actions: {
            onMousemove: function onMousemove(e) {
                if (this.get("showDraw")) {
                    var shape = this.get("shape");
                    if (shape !== "MultiPoint") {
                        var currentPosition = [e.latlng.lng, e.latlng.lat];
                        var newNode = { type: "Point", coordinates: currentPosition };
                        this.set("newNode", newNode);
                    }
                }
            },

            mapClick: function mapClick(e) {
                var that = this;
                var click = this.get("click");
                if (click === true) {
                    this.dbClick(e);
                    this.set("click", false);
                } else {
                    this.set("click", true);
                }
                Ember.run.later(function () {
                    that.checkClick(e);
                }, 200);
            },
            toggleDraw: function toggleDraw() {
                this.toggleProperty("showDraw");
            },
            setShape: function setShape(shape) {
                this.set("shape", shape);
            },
            getGeoLocation: function getGeoLocation(location) {
                this.sendAction("getGeoLocation", location);
            },
            updateLocation: function updateLocation(e) {
                this.sendAction("updateLocation", e.target._latlng);
            }
        }
    });
});