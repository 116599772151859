define('extraapps/components/dataset-view-map/component', ['exports', 'ember-context-menu'], function (exports, _emberContextMenu) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function saveShape(detail) {
        detail.that.set("showNewShape", false);
        var recordView = detail.that.get("recordView");
        var geodaten = JSON.parse(JSON.stringify(detail.that.get("newShape")));
        var geodata = JSON.parse(JSON.stringify(geodaten));
        var isPoint = false;
        if (detail.that.get("shape") === "MultiPoint") {
            geodata.type = "Point";
            geodata.coordinates = [geodata.coordinates[0][1], geodata.coordinates[0][0]];
            geodaten = { type: "Point", coordinates: geodaten.coordinates[0] };
            Ember.set(recordView, "isPoint", true);
        } else {
            Ember.set(recordView, "isPoint", false);
        }
        detail.that.set("record", { geodata: geodata, name: "test", geodaten: JSON.stringify(geodaten) });
        var currentDataset = detail.that.get("currentDataset");
        detail.that.set("recordMeta", currentDataset);

        detail.that.set("nodes", []);
        detail.that.set("draw", false);
        Ember.set(recordView, "sidebar", true);
        detail.that.createContextMenu();
    }
    function saveImported(detail) {
        var recordView = detail.that.get("recordView");
        var record = detail.that.get("record");
        var importedData = detail.that.get("importedData");
        var geodata = { type: null, coordinates: null };
        if (importedData.length === 1) {
            geodata.type = "Polygon";
            geodata.coordinates = importedData[0].coordinates;
        } else if (importedData.length > 1) {
            geodata.type = "GeometryCollection";
            geodata.geometries = importedData;
        }
        detail.that.set("importedData", []);
        if (record.geodata.geometries !== undefined) {
            geodata.geometries.forEach(function (element) {
                record.geodata.geometries.addObject(element);
                Ember.set(record, "geodaten", JSON.stringify(record.geodata));
            });
        } else {
            detail.that.set("record", { geodata: geodata, geodaten: JSON.stringify(geodata) });
        }
        detail.that.set("osm", false);
        Ember.set(recordView, "sidebar", true);
        detail.that.createContextMenu();
    }
    function importData(detail) {
        var polygon = detail.that.get("newShape");
        detail.that.get("gc").getShapeByPosition(polygon).then(function (imported) {
            var importedData = [];
            imported.elements.forEach(function (element) {
                var record = { "type": "Polygon", coordinates: [[]] };
                element.geometry.forEach(function (c) {
                    if (c.lon !== undefined && c.lat !== undefined) {
                        record.coordinates[0].addObject([c.lon, c.lat]);
                        importedData.addObject(record);
                    }
                });
            });

            detail.that.set("nodes", []);
            detail.that.set("draw", false);
            detail.that.set("new", false);

            detail.that.createContextMenu();
            detail.that.set("importedData", importedData);
            detail.that.set("showImported", true);
        });
    }

    exports.default = Ember.Component.extend(_emberContextMenu.default, {
        that: null,

        init: function init() {
            this._super();
            this.getBounds();
            var contextDetails = this.get("contextDetails");
            Ember.set(contextDetails, "that", this);
        },

        contextItems: [],
        contextDetails: {
            this: undefined
        },
        osmType: { text: "Gebäude" },
        newData: "address",
        gc: Ember.inject.service(),
        showSelectedPlace: false,
        latlng: null,
        bounds: null,
        new: {},
        shape: "LineString",
        nodes: [],
        draw: false,
        iconSize: [1, 1],
        iconAnchor: [1000, -1000],
        record: {},
        showNewShape: false,
        newShape: Ember.computed('nodes.@each.coordinates', function () {
            var nodes = this.get("nodes");
            var s = this.get("shape");
            var shape = null;
            if (nodes.length !== 0) {

                shape = {
                    type: s,
                    coordinates: nodes.map(function (node) {
                        return [node.coordinates[0].toString(), node.coordinates[1].toString()];
                    })
                };
                if (shape.type === "Polygon") {
                    shape.coordinates = [shape.coordinates];
                }

                return shape;
            } else {
                return null;
            }
        }),

        createContextMenu: function createContextMenu() {
            var contextItems = [];
            var draw = this.get("draw");
            var osm = this.get("osm");
            var osmType = this.get("osmType");
            if (draw && !osm) {
                contextItems.addObject({
                    label: 'übernehmen',
                    action: function action(selected, details, e) {
                        saveShape(details);
                    }
                });
            } else if (draw && osm) {
                contextItems.addObject({
                    label: osmType.text + ' impotieren',
                    action: function action(selected, details, e) {
                        importData(details);
                    }
                });
            } else if (!draw && osm) {
                contextItems.addObject({
                    label: 'übernehmen',
                    action: function action(selected, details, e) {
                        saveImported(details);
                    }
                });
            }
            this.set("contextItems", contextItems);
        },

        newShapeChanged: function () {
            var newShape = this.get("newShape");
            if (this.get("editRecordGeodata")) {
                var record = this.get("record");
                Ember.set(record, "hideOnMap", true);

                Ember.set(record, "geodata", newShape);
                Ember.run.later(function () {
                    Ember.set(record, "hideOnMap", false);
                }, 0);
            }
            var that = this;

            this.set("showNewShape", false);
            if (newShape !== null) {
                Ember.run.later(function () {
                    that.set("showNewShape", true);
                }, 0);
            }
        }.observes("newShape").on("init"),
        getBounds: function getBounds(objs) {
            if (objs === undefined) {
                objs = [{ type: "Polygon", coordinates: [[[4.042, 45.828], [4.042, 55.478], [15.823, 55.478], [15.820, 45.828]]] }];
            } else if (objs.length === 0) {
                objs = [{ type: "Polygon", coordinates: [[[4.042, 45.828], [4.042, 55.478], [15.823, 55.478], [15.820, 45.828]]] }];
            }
            var bbox = { "geometries": objs, "type": "GeometryCollection" };
            bbox = L.geoJson(bbox).getBounds();
            this.set("bounds", bbox);
        },
        layersChanged: function () {
            var layers = JSON.parse(JSON.stringify(this.get("dataLayers")));
            var currentDataset = this.get("currentDataset");
            var geoData = [];
            if (layers !== null && layers !== undefined) {
                for (var a = 0; a < layers.length; a++) {
                    var layer = layers[a];
                    if (layer.meta.withGeoData) {
                        var records = layers[a].records;

                        for (var b = 0; b < records.length; b++) {
                            var r = records[b];
                            if (r.geodata === undefined) {
                                Ember.set(r, "hideOnMap", true);
                            } else {
                                if (r.geodata.type === "Point") {
                                    Ember.set(r, "isPoint", true);
                                    var geodata = JSON.parse(JSON.stringify(r.geodata));
                                    geoData.addObject(geodata);
                                    r.geodata.coordinates = [r.geodata.coordinates[1], r.geodata.coordinates[0]];
                                } else {
                                    geoData.addObject(r.geodata);
                                }
                            }
                        }
                    } else {
                        layers.removeObject(layer);
                    }
                }
                if (currentDataset === undefined) {
                    this.set("currentDataset", layers[0]);
                    this.set("recordMeta", layers[0]);
                }
            } else {
                geoData = [{ type: "Polygon", coordinates: [[[4.042, 45.828], [4.042, 55.478], [15.823, 55.478], [15.820, 45.828]]] }];
            }
            this.getBounds(geoData);
        }.observes("dataLayers.length", "dataLayers.@each.records", "dataLayers.@each.records.@each").on("init"),

        currentPanelChanged: function () {
            var currentPanel = this.get("currentPanel");
            switch (currentPanel) {
                case "bg":
                    this.set("showBackgrounds", true);
                    this.set("showLayers", false);
                    this.set("showPlacePanel", false);
                    this.set("showNew", false);

                    break;
                case "layer":
                    this.set("showBackgrounds", false);
                    this.set("showLayers", true);
                    this.set("showPlacePanel", false);
                    this.set("showNew", false);
                    break;
                case "places":
                    this.set("showBackgrounds", false);
                    this.set("showLayers", false);
                    this.set("showPlacePanel", true);
                    this.set("showNew", false);
                    break;
                case "new":
                    this.set("showBackgrounds", false);
                    this.set("showLayers", false);
                    this.set("showPlacePanel", false);
                    this.set("showNew", true);
                    break;
                case "none":
                    this.set("showBackgrounds", false);
                    this.set("showLayers", false);
                    this.set("showPlacePanel", false);
                    this.set("showNew", false);
                    break;
            }
            this.createContextMenu();
        }.observes("currentPanel").on("init"),
        newDataChanged: function () {
            var newData = this.get("newData");
            switch (newData) {
                case "osm":
                    this.set("draw", true);
                    this.set("osm", true);
                    this.set("shape", "Polygon");
                    this.createContextMenu();
                    break;
                case "draw":
                    this.set("draw", true);
                    this.set("osm", false);
                    this.set("shape", "LineString");
                    this.createContextMenu();
            }
        }.observes("newData").on("init"),
        getShapeByPosition: function getShapeByPosition(polygon) {

            this.get("gc").getShapeByPosition(polygon).then(function (data) {

                var importedData = [];
                data.elements.forEach(function (element) {
                    var record = { "type": "Polygon", coordinates: [] };
                    data.elements[0].geometry.forEach(function (c) {
                        if (c.lon !== undefined && c.lat !== undefined) {
                            record.coordinates.addObject([c.lon, c.lat]);
                            importedData.addObject(record);
                        }
                    });
                    console.log(importedData);
                    /*
                    this.set("importedData", importedData);
                    this.set("showImported", true);*/
                });
            });
        },
        actions: {
            deselect: function deselect(record) {
                var importedData = this.get("importedData");
                var deselectedObjects = this.get("deselectedObjects");
                importedData.removeObject(record);
                if (deselectedObjects === undefined) {
                    Ember.set(this, "deselectedObjects", [record]);
                } else {
                    deselectedObjects.addObject(record);
                }
            },
            reselect: function reselect(record) {
                var importedData = this.get("importedData");
                var deselectedObjects = this.get("deselectedObjects");
                deselectedObjects.removeObject(record);
                importedData.addObject(record);
            },
            setView: function setView(view) {
                this.sendAction("setLayout", view);
            },
            centerOn: function centerOn(obj) {
                obj = JSON.parse(JSON.stringify(obj));
                if (obj.type === "Point") {
                    Ember.set(obj, "coordinates", [obj.coordinates[1], obj.coordinates[0]]);
                }
                this.getBounds([obj]);
            },
            editGeoData: function editGeoData(geodata) {
                var record = this.get("record");
                var rGeo = record.geodata;
                var newShape = this.get("newShape");
                if (rGeo.geometries !== undefined) {
                    this.set("importedData", JSON.parse(JSON.stringify(rGeo.geometries)));
                    Ember.set(rGeo, "geometries", []);
                    this.set("showImported", true);
                } else {
                    geodata = JSON.parse(JSON.stringify(geodata));
                    var nodes = geodata.coordinates;
                    if (geodata.type === "Polygon") {
                        nodes = geodata.coordinates[0];
                    }
                    nodes = nodes.map(function (node) {
                        return { type: "Point", coordinates: node };
                    });
                    this.set("nodes", nodes);
                    this.set("shape", geodata.type);
                    this.set("editRecordGeodata", true);
                }
                this.set("updateGeometry", true);
            },
            updateGeodata: function updateGeodata(record) {
                var newData = this.get("importedData");
                if (record.geodata !== undefined) {
                    if (record.geodata.geometries !== undefined) {

                        Ember.set(record.geodata, "geometries", newData);
                        Ember.set(record, "geodaten", JSON.stringify(record.geodata));
                        var ds = this.get("recordMeta");
                        record = JSON.parse(JSON.stringify(record));
                        this.sendAction("updateRecord", ds, record);
                        this.set("importedData", []);
                        this.set("deselectedObjects", []);
                        this.set("showImported", false);
                    }
                }
            },
            showRecord: function showRecord(dl, record) {
                var recordView = this.get("recordView");
                this.set("recordMeta", dl);
                this.set("record", record);
                if (record.geodata.type === "Point") {
                    Ember.set(recordView, "isPoint", true);
                } else {

                    Ember.set(recordView, "isPoint", false);
                }
                Ember.set(recordView, "sidebar", true);
                Ember.set(recordView, "sidebarDidOpen", true);
            },
            structure: function structure(dataset) {
                var url = window.location.href;
                url = url.split("/view");
                url = url[0] + '/structure/' + dataset;
                var win = window.open(url, '_blank');
                win.focus();
            },
            updateLocation: function updateLocation(node, e) {
                var location = e.target.getLatLng();
                Ember.set(node, "coordinates", [location.lng, location.lat]);
            },

            mapDoubleClick: function mapDoubleClick() {},
            mapClick: function mapClick(e) {
                var recordView = this.get("recordView");
                var showImported = this.get("showImported");
                if (this.draw || this.editRecordGeodata) {
                    var that = this;
                    var lat = e.latlng.lat;
                    var lng = e.latlng.lng;
                    var shape = this.get("shape");
                    var nodes = this.get("nodes");
                    if (shape === "MultiPoint") {
                        this.set("nodes", [{ type: "Point", coordinates: [lng, lat] }]);
                        this.set("newPoint", true);
                    } else {
                        this.set("newPoint", false);
                        nodes.addObject({ type: "Point", coordinates: [lng, lat] });
                    }
                } else if (recordView.sidebar) {
                    if (recordView.sidebarDidOpen) {
                        Ember.set(recordView, "sidebarDidOpen", false);
                    } else if (!showImported) {
                        Ember.set(recordView, "sidebar", false);
                    }
                }
            },
            usePosition: function usePosition(e) {
                var lat = e.latlng.lat;
                var lng = e.latlng.lng;
                var recordView = this.get("recordView");
                var record = { geodata: { type: "Point", coordinates: [lat, lng] } };
                this.set("record", record);
                Ember.set(recordView, "show", true);
                this.getBounds([{ type: "Point", coordinates: [lng, lat] }]);
            },
            addLayer: function addLayer() {
                this.sendAction("addLayer", "photovoltaik");
            },
            getGeoDataForAddress: function getGeoDataForAddress(place) {
                var _this = this;

                var that = this;
                var recordView = this.get("recordView");
                var address = place;
                var street = address.street;
                var number = address.number;
                var city = address.city;
                var post = address.post;
                var values = ["street", "number", "city", "post"];
                var proceed = true;
                for (var a = 0; a < values.length; a++) {
                    var value = values[a];
                    if (address[value] === undefined || address[value] === null) {
                        alert(value);
                        proceed = false;
                    }
                }
                if (proceed) {
                    return this.get("gc").geoCodeOneAddress(city, street, number, post).then(function (geoData) {
                        geoData = geoData[0];console.log(geoData);
                        var record = { geodata: { type: "Point", coordinates: [geoData.lat, geoData.lon] } };
                        record.geodaten = JSON.stringify({ type: "Point", coordinates: [geoData.lon, geoData.lat] });
                        _this.set("record", record);
                        Ember.set(recordView, "isPoint", true);
                        Ember.set(recordView, "sidebar", true);
                        _this.getBounds([{ type: "Point", coordinates: [geoData.lon, geoData.lat] }]);
                    });
                }
            },
            selectRecord: function selectRecord(meta, record) {
                var selectedObject = { meta: meta, record: record };
                this.sendAction("selectObject", selectedObject);
            },
            setBackground: function setBackground(value) {
                switch (value) {
                    case "hell":
                        this.sendAction("setBackground", "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png");
                        break;
                    case "dunkel":
                        this.sendAction("setBackground", "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png");
                        break;
                    case "sat":
                        this.sendAction("setBackground", "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}");

                }
            },
            toggleLayer: function toggleLayer(layer) {
                // show/hide Layer
                this.sendAction("toggleLayer", layer);
            },
            importObject: function importObject(obj) {
                console.log(obj);
                var importedGeoObjects = this.get("importedGeoObjects");
                importedGeoObjects.addObject(obj);
            },
            setValue: function setValue(key, value) {
                this.set(key, value);
            },

            onMousemove: function onMousemove(e) {
                //let latlng = e.latlng;
                //this.set("latlng", latlng);
            },

            getPlaces: function getPlaces(name) {
                var _this2 = this;

                this.set("showSelectedPlace", false);
                if (name.length > 3) {
                    return this.get("gc").getByName(name).then(function (data) {
                        //Imports Geolocations from osm
                        var objects = [];
                        for (var x = 0; x < data.length; x++) {
                            var json = data[x];
                            if (json.class === "boundary") {
                                objects.addObject(json);
                            }
                        }
                        _this2.set("places", objects);
                        _this2.set("currentPanel", "places");
                    }).catch(function (error) {
                        console.log(error);
                    });
                }
            },
            goToPlace: function goToPlace(place) {
                this.set("showSelectedGeodata", false);
                var objs = [{ type: place.geojson.type, coordinates: place.geojson.coordinates }];
                this.getBounds(objs);
                this.set("selectedPlace", place);
                this.set("showSelectedPlace", true);
            },
            importSelectedGeoData: function importSelectedGeoData() {
                var data = this.get("selectedPlace");
                data = JSON.parse(JSON.stringify(data));
                console.log({ geodata: data.geojson });
                this.set("record", { geodata: data.geojson, geodaten: JSON.stringify(data.geojson) });
                this.set("showImported", false);
                this.set("showSelectedPlace", false);
                this.set("selectedPlace", null);
                this.set("currentPanel", "none");
                var currentDataset = this.get("currentDataset");
                this.set("recordMeta", currentDataset);
                Ember.set(this.recordView, "sidebar", true);
            },
            setCurrentSet: function setCurrentSet(ds) {
                this.set("currentDataset", ds);
            },
            insertRecord: function insertRecord() {
                var recordView = this.get("recordView");
                var ds = this.get("recordMeta");
                var record = JSON.parse(JSON.stringify(this.get("record")));
                delete record["geodata"];
                var isNew = true;
                if (record.id) {
                    isNew = false;
                }
                this.sendAction("insertRecord", ds, record, recordView, isNew);
            },

            deleteRecord: function deleteRecord(ds, record) {
                this.sendAction("deleteRecord", ds, record);
            }
        }
    });
});