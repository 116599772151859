define('extraapps/components/ea-enumtypes/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    es: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    values: [],
    init: function init() {
      var _this = this;

      this._super();
      return this.get("es").getTypes().then(function (enums) {
        _this.set("lists", enums);
      }).catch(function (error) {});
    },

    removeObjectChanged: function () {
      var removeType = this.get("removeType");
      var removeAttr = this.get("removeAttr");
      if (removeType !== null && removeType !== undefined || removeAttr !== null && removeAttr !== undefined) {
        this.set("removeDialog", true);
      } else {
        this.set("removeDialog", false);
      }
    }.observes("removeType", "removeAttr").on("init"),
    deleteValue: function deleteValue(value) {
      var _this2 = this;

      var values = this.get("values");
      return this.get("es").deleteAttributes(value).then(function (data) {
        values.removeObject(value);
        _this2.close();
        _this2.get('notifications').success("Wert entfernt", {
          autoClear: true
        });
      }).catch(function (error) {
        console.log(error);
        _this2.get('notifications').error("Fehler beim löschen des Wertes.", {
          autoClear: true
        });
      });
    },

    close: function close() {
      this.set("removeType", null);
      this.set("removeAttr", null);
      this.set("removeDialog", false);
    },
    deleteType: function deleteType(type) {
      var _this3 = this;

      var lists = this.get("lists");

      return this.get("es").deleteType(type.enumType).then(function (data) {
        lists.removeObject(type);
        _this3.set("values", []);
        _this3.close();
        _this3.get('notifications').success("Werteliste entfernt", {
          autoClear: true
        });
      }).catch(function (error) {
        console.log(error);
        _this3.get('notifications').error("Fehler beim löschen der Werteliste.", {
          autoClear: true
        });
      });
    },
    actions: {

      remove: function remove(type, value) {

        if (type === "type") {
          this.deleteType(value);
        } else {
          this.deleteValue(value);
        }
      },
      close: function close() {
        this.close();
      },
      reorderItems: function reorderItems(itemModels, draggedModel) {
        var _this4 = this;

        for (var a = 0; a < itemModels.length; a++) {
          var item = itemModels[a];
          Ember.set(item, "sequenceNumber", a + 1);
        }
        return this.get("es").createAttributes(itemModels).then(function (data) {
          _this4.set("values", itemModels);
        }).catch(function (error) {
          console.log(error);
        });
      },

      getValues: function getValues(key) {
        var _this5 = this;

        return this.get("es").getAttributesForType(key).then(function (values) {
          values.sort(function (a, b) {
            return a.sequenceNumber - b.sequenceNumber;
          });
          _this5.set("current", key);
          _this5.set("values", values);
          _this5.set("showValues", true);
        }).catch(function (error) {});
      },

      addType: function addType() {
        var _this6 = this;

        var type = this.get("name");
        if (type !== undefined && type !== null && type !== "") {
          var lists = this.get("lists");
          return this.get("es").createType(type).then(function (newType) {
            lists.addObject(newType[0]);
            _this6.set("current", type);
            _this6.set("showValues", true);
            _this6.set("name", null);
            _this6.get('notifications').success("Werteliste erstellt", {
              autoClear: true
            }).catch(function (error) {
              console.log(error);
              _this6.get('notifications').error("Fehler beim erstellen der Werteliste", {
                autoClear: true
              });
            });
          });
        }
      },
      addValue: function addValue() {
        var _this7 = this;

        var current = this.get("current");
        var values = this.get("values");
        var value = this.get("value");
        if (value !== undefined && value !== null) {
          if (values === undefined) {
            values = [];
          }
          var num = values.length + 1;
          var attr = { enumType: current, enumKey: '' + num, enumNumber: num, enumText: value, sequenceNumber: num };
          return this.get("es").createAttributes([attr]).then(function (enumAtr) {
            values.addObject(enumAtr[0]);
            _this7.set("value", null);
          }).catch(function (error) {});
        }
      }
    }
  });
});