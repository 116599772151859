define("extraapps/components/remove-importfield/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            removeField: function removeField(field) {
                this.sendAction("remove", field);
            }
        }
    });
});