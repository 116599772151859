define('extraapps/admin/datasets/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        session: Ember.inject.service(),
        ds: Ember.inject.service(),
        gc: Ember.inject.service(),
        notifications: Ember.inject.service('notification-messages'),
        queryParams: ["path"],
        path: null,
        columns: [{ key: "title", header: "Name", sortable: true }, { key: "id", header: "ID", sortable: true }, { key: " ", header: " ", component: "delete-button", width: "60px" }, { key: " ", header: " ", component: "goto-structure-button", width: "35px" }, { key: " ", header: " ", component: "import-button", width: "35px" }, { key: " ", header: " ", component: "button-geocode", width: "35px" }, { key: " ", header: " ", component: "goto-icon-button", width: "35px" }, { key: " ", header: " ", component: "goto-ds-button", width: "60px" }],
        ascending: true,
        sortKey: "name",
        addressFieldChanged: function () {
            var model = this.get("model");
            if (model !== undefined && model !== null) {
                var dataset = model.dataset;
                if ("address" in dataset) {
                    if (dataset.address === true) {
                        Ember.set(dataset, "withGeoData", true);
                    }
                }
            }
        }.observes("model.dataset.address").on("init"),
        actions: {

            createDS: function createDS() {
                var _this = this;

                var cid = this.get("session").get("client_id");
                var aid = this.get("session").get("app_id");
                var ds = this.get("model.dataset");
                var name = ds.title.toLowerCase();
                name = name.replace(/\u00e4/g, "ae");
                name = name.replace(/\u00fc/g, "ae");
                name = name.replace(/\u00f6/g, "ae");
                name = name.replace(/\u00df/g, "ss");
                name = name.replace(/ /g, "_");
                name = name.replace(/\./g, '_');
                name = name.replace(/\,/g, '_');
                name = name.replace(/\:/g, '_');
                name = name.replace(/\;/g, '_');
                name = name.replace(/\-/g, '_');
                var fields = [];
                if (ds.address) {
                    fields = [{ "label": "Straße", "logical_type": "STREET", validations: [{ "type": "required", "args": null, "message": "darf nicht leer sein" }] }, { "label": "Hausnummer", "logical_type": "HOUSE_NUMBER", validations: [{ "type": "required", "message": "darf nicht leer sein" }] }, { "label": "PLZ", "logical_type": "POSTCODE" }, { "label": "Stadt", "logical_type": "CITY" }];
                }
                if (ds.withGeoData) {
                    fields.addObject({ "label": "Geodaten", "logical_type": "GEOMETRY" });
                }
                if (ds.preview) {
                    fields.addObject({ "label": "Vorschaubild", "logical_type": "IMAGE" });
                }
                if (ds.appointment) {
                    fields.addObject({ "label": "Anfang", "logical_type": "BEGIN" });
                    fields.addObject({ "label": "Ende", "logical_type": "END" });
                }
                if (ds.date) {
                    fields.addObject({ "label": "Datum", "logical_type": "DATE" });
                }
                for (var a = 0; a < fields.length; a++) {
                    var field = fields[a];
                    Ember.set(field, "sequenceNumber", a + 1);
                    Ember.set(field, "cid", cid);
                    Ember.set(field, "aid", aid);
                }
                var dataset = { name: name, title: ds.title, fields: fields, cid: cid, aid: aid };
                return this.get("ds").replaceDataset(dataset).then(function (data) {
                    var datasets = _this.get("model.datasets");
                    datasets.addObject(data[0]);
                    _this.transitionToRoute("admin.structure", data[0].name);
                    _this.set("newDataset", false);
                }).catch(function (error) {
                    console.log(error);
                });
            },
            delete: function _delete(id) {
                var _this2 = this;

                var datasets = this.get("model").datasets;
                var ds = datasets.findBy("id", id);
                return this.get("ds").deleteDataset(ds.name).then(function (deletedDataset) {
                    datasets.removeObject(ds);
                    _this2.get('notifications').success("Das Dataset wurde gelöscht.", {
                        autoClear: true
                    });
                }).catch(function (error) {
                    console.log(error);
                    _this2.get('notifications').error("Beim löschen des Datasets trat ein Fehler auf.", {
                        autoClear: true
                    });
                });
            },
            goToImporter: function goToImporter(ds) {
                this.transitionToRoute("admin.importer", { queryParams: { ds: ds } });
            },

            goToStructure: function goToStructure(id) {
                var datasets = this.get("model").datasets;
                var dataset = datasets.findBy("id", id);
                this.transitionToRoute("admin.structure", dataset.name);
            },

            geoCodeDS: function geoCodeDS(dsName) {
                this.get("gc").geoCodeDS(dsName);
            },

            goToDataset: function goToDataset(id, layout) {
                var datasets = this.get("model").datasets;
                var dataset = datasets.findBy("id", id);
                this.transitionToRoute("admin.view", { queryParams: { display: layout, datasets: [dataset.name] } });
            },
            goToIcon: function goToIcon(id) {
                var datasets = this.get("model").datasets;
                var dataset = datasets.findBy("id", id);
                this.transitionToRoute("admin.icons", { queryParams: { dataset: [dataset.name] } });
            }
        }
    });
});