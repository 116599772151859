define("extraapps/components/ea-form-geo-search/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        location: {},
        actions: {
            getGeoLocation: function getGeoLocation(location) {

                this.sendAction("getGeoLocation", location);
            }
        }
    });
});