define('extraapps/admin/view/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        title: 'Admin > Datasets > Viewer',
        actions: {
            willTransition: function willTransition() {
                console.log("now");
                this.controller.set("display", null);
            }
        }

    });
});