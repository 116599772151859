define('extraapps/router', ['exports', 'extraapps/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route("r1", { path: "/:u1" }, function () {
      this.route("r2", { path: "/:u2" }, function () {
        this.route("r3", { path: "/:u3" }, function () {
          this.route("r4", { path: "/:u4" }, function () {
            this.route("r5", { path: "/:u5" });
          });
        });
      });
    });

    this.route('login');
    this.route('admin', function () {
      this.route('home');
      this.route("page", { path: "page/:pathSegment" });
      this.route('pages');
      this.route('datasets');
      this.route("dataset", { path: "dataset/:name" });
      this.route('assets', function () {
        this.route('images');
      });
      this.route('changes');
      this.route('loading');
      this.route('style');
      this.route('record', { path: "record/:dataset/:id" });
      this.route('structure', { path: "structure/:name" });
      this.route('view');
      this.route('icons');
      this.route('datasets-loading');
      this.route('importer', { path: "importer" });
      this.route('templates');
      this.route('template', { path: "template/:id" });
      this.route('display');
      this.route('import');
      this.route('dsView');
      this.route('upload');
      this.route('apps');
    });
    this.route('admin.loading');
    this.route('sandbox');
    this.route('graph');
  });

  Ember.Router.reopen({
    ea: Ember.inject.service(),
    session: Ember.inject.service(),
    subpagePresent: function (transition) {
      var url = null;
      window.scrollTo(0, 0);

      /* for willTransition
       if("name" in transition.intent ){
        url = transition.intent.name.split("/");
        url = url[0].split(".")
      }else{
        url = transition.intent.url.split("/");
        url = url.removeObject(url[0])
        if(url.length > 3 && url[4].includes("?path=")){
          url.removeObject(url[4]);
        }
      }*/

      var ea = this.get("ea");
      var rootURL = _environment.default.server.baseURLFrontEnd + '/';
      url = window.location.href;
      if (url === _environment.default.server.baseURLFrontEnd + '/') {
        ea.getController('application').set("subpage", false);
      } else {
        ea.getController('application').set("subpage", true);
        url = url.split(rootURL)[1];
        url = url.split("/");
        if (url.length > 1) {
          // removing queryParams
          var last = url[url.length - 1];
          last = last.split("?");
          last = last[0];
          url.removeObject(url[url.length - 1]);
          url.addObject(last);
        }
        if (url[0].indexOf("admin") !== -1) {
          if (url.length === 1) {
            ea.getController('admin').set("path", "admin");
            ea.getController('admin').set("subpage", false);
          } else {
            ea.getController('admin').set("path", url[1]);
            ea.getController('admin').set("subpage", true);
          }
          if (url.length === 2) {
            var path = Ember.getOwner(this).lookup('controller:application').currentPath.split(".");
            var controllerPath = 'admin.' + path[1];
            var controller = ea.getController(controllerPath);
          }
          if (url.length === 3) {
            var _path = Ember.getOwner(this).lookup('controller:application').currentPath.split(".");
            var _controllerPath = 'admin.' + _path[1];
          }
        } else {
          var r1 = this.get("ea").getController("r1");
          var r2 = this.get("ea").getController("r1.r2");
          var r3 = this.get("ea").getController("r1.r2.r3");
          var r4 = this.get("ea").getController("r1.r2.r3.r4");
          var r5 = this.get("ea").getController("r1.r2.r3.r4.r5");
          r1.set("path", url);
          r2.set("path", url);
          r3.set("path", url);
          r4.set("path", url);
          r5.set("path", url);
          console.log(url.length);
          switch (url.length) {
            case 1:
              r1.set("subpage", false);
              var title = ea.getController("r1").model.pageTitle;
              if (title !== undefined) {
                Ember.set(document, "title", title);
              }
              break;
            case 2:
              r1.set("subpage", true);
              r2.set("subpage", false);
              title = ea.getController("r1.r2").model.pageTitle;
              if (title !== undefined) {
                Ember.set(document, "title", title);
              }
              break;
            case 3:
              r1.set("subpage", true);
              r2.set("subpage", true);
              r3.set("subpage", false);
              title = ea.getController("r1.r2.r3").model.pageTitle;
              if (title !== undefined) {
                Ember.set(document, "title", title);
              }
              break;
            case 4:
              r1.set("subpage", true);
              r2.set("subpage", true);
              r3.set("subpage", true);
              r4.set("subpage", false);
              title = ea.getController("r1.r2.r.4").model.pageTitle;
              if (title !== undefined) {
                Ember.set(document, "title", title);
              }
              break;
            case 5:
              r1.set("subpage", true);
              r2.set("subpage", true);
              r3.set("subpage", true);
              r4.set("subpage", true);
              r5.set("subpage", false);
              title = ea.getController("r1.r2.r3.r4.r5").model.pageTitle;
              if (title !== undefined) {
                Ember.set(document, "title", title);
              }
              break;
          }
        }
      }
    }.on('didTransition'),
    prevPage: function (transition) {
      var path = Ember.getOwner(this).lookup('controller:application').currentPath;
      this.get("ea").getController('application').set("prevPage", path);
    }.on("willTransition")
  });

  exports.default = Router;
});