define('extraapps/admin/record/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        ea: Ember.inject.service(),
        ps: Ember.inject.service(),
        session: Ember.inject.service(),
        options: {
            selector: 'textarea' // change this value according to your html
        },
        notifications: Ember.inject.service('notification-messages'),
        queryParams: ["show"],
        dates: {},
        show: "data",
        docs: "prev",
        modelChanged: function () {
            var dates = this.get("dates");
            var model = this.get("model");
            if (model !== null && model !== undefined) {
                var fields = model.meta.databases[0].datasets[0].fields;
                var ofield = fields.findBy("label", "owner");
                var dateField = fields.findBy("logical_type", "DATE");
                var startField = fields.findBy("logical_type", "BEGIN");
                var endField = fields.findBy("logical_type", "END");

                fields.removeObject(startField);
                fields.removeObject(endField);
                fields.removeObject(dateField);
                fields.removeObject(ofield);

                if (dateField !== undefined) {
                    Ember.set(dates, "hasDate", true);
                    Ember.set(dates, "dateField", dateField);
                }

                if (startField !== undefined) {
                    Ember.set(dates, "start", startField);
                    Ember.set(dates, "hasDate", true);
                }

                if (endField !== undefined) {
                    Ember.set(dates, "end", endField);
                }
            }
        }.observes("model").on("init"),
        showChanged: function () {
            var show = this.get("show");
            switch (show) {
                case "geodata":
                    this.set("showImage", false);
                    this.set("showGeodata", true);
                    this.set("showData", false);
                    this.set("showRelations", false);
                    this.set("showDate", false);
                    break;
                case "data":
                    this.set("showImage", false);
                    this.set("showData", true);
                    this.set("showGeodata", false);
                    this.set("showRelations", false);
                    this.set("showDate", false);
                    break;
                case "image":
                    this.set("showImage", true);
                    this.set("showData", false);
                    this.set("showGeodata", false);
                    this.set("showRelations", false);
                    this.set("showDate", false);
                    break;
                case "relations":
                    this.set("showImage", false);
                    this.set("showData", false);
                    this.set("showGeodata", false);
                    this.set("showRelations", true);
                    this.set("showDate", false);
                    break;
                case "date":
                    this.set("showImage", false);
                    this.set("showData", false);
                    this.set("showGeodata", false);
                    this.set("showRelations", false);
                    this.set("showDate", true);
                    break;

            }
        }.observes("show").on("init"),
        insertRecord: function insertRecord() {
            var _this = this;

            var model = this.get("model");
            var that = this;
            var ps = this.get('ps');
            var data = this.get("data");
            var record = model.data[0].records[0];
            //var record = [record];
            var meta = this.get("model.meta");
            var datasetName = meta.databases[0].datasets[0].name;
            return ps.insertRecords('_external', datasetName, record).then(function (data) {
                _this.get('notifications').success('Datensatz gespeichert.', { // TODO: use i18n text...
                    autoClear: true
                });

                that.transitionToRoute("admin.record", datasetName, data[0].id);
            }).catch(function (error) {
                _this.get('notifications').error(error, { // TODO: use i18n text...
                    autoClear: true
                });
            });
        },
        updateRecord: function updateRecord() {
            var _this2 = this;

            var ps = this.get('ps');
            var record = this.get("model.data")[0].records[0];
            delete record['geodata'];
            delete record['documentLinks'];
            var meta = this.get("model.meta");
            var that = this;
            var datasetName = meta.databases[0].datasets[0].name;
            return ps.updateRecord("_external", datasetName, record).then(function (data) {
                _this2.get('notifications').success('Änderungen gespeichert.', { // TODO: use i18n text...
                    autoClear: true
                });
            }).catch(function (error) {
                console.log(error);
                _this2.get('notifications').error('Beim Speichern ist ein Fehler aufgetreten', { // TODO: use i18n text...
                    autoClear: true
                });
            });
        },
        actions: {
            remove: function remove() {
                var _this3 = this;

                var model = this.get("model");
                return this.get("ps").deleteRecord("_external", model.meta.databases[0].datasets[0].name, model.data[0].records[0].id).then(function (removed) {
                    _this3.get('notifications').success('Datensatz gelöscht', { // TODO: use i18n text...
                        autoClear: true
                    });
                    _this3.transitionToRoute("admin.view", { queryParams: {
                            datasets: model.meta.databases[0].datasets[0].name
                        } });
                }).catch(function (error) {
                    console.log(error);
                    _this3.get('notifications').error('Beim Löschen ist ein Fehler aufgetreten', { // TODO: use i18n text...
                        autoClear: true
                    });
                });
            },
            updatePrev: function updatePrev(path, id) {
                var record = this.get("model").data[0].records[0];
                Ember.set(record, path, id);
            },
            save: function save() {
                var record = this.get("model.data")[0].records[0];console.log(record);
                if (record.id === undefined) {
                    this.insertRecord();
                } else {
                    this.updateRecord();
                }
            }
        }
    });
});