define("extraapps/components/ea-datacal/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        api: Ember.inject.service(),
        ea: Ember.inject.service(),
        init: function init() {
            var _this = this;

            this._super();
            var events = [];

            var model = this.get("model");
            var links = [];
            model.meta.databases[0].datasets.forEach(function (ds) {
                if (ds.withTemporalData === true) {
                    var records = model.data.findBy("dataset", ds.name).records;
                    var fields = ds.fields;
                    var dateField = fields.findBy("logical_type", "DATE");
                    var startField = fields.findBy("logical_type", "BEGIN");
                    var endField = fields.findBy("logical_type", "END");
                    if (dateField !== undefined) {
                        var datePath = dateField.path;
                        records.forEach(function (record) {
                            var event = {};
                            event.allDay = true;
                            event.layerName = ds.name;
                            event.color = layer.colors.fillColor;
                            event.start = record[datePath];
                            events.addObject(event);
                        });
                    } else if (startField !== undefined && endField !== undefined) {
                        var startPath = startField.path;
                        var endPath = endField.path;
                        records.forEach(function (record) {
                            var event = {};
                            if (record.titel !== undefined) {
                                event.title = record.titel;
                            } else {
                                event.title = ds.name + " " + moment(record[startPath]).format("HH:mm") + " - " + moment(record[endPath]).format("HH:mm");
                            }
                            event.layerName = ds.name;
                            event.id = record.id;
                            event.start = record[startPath];
                            event.end = record[endPath];
                            events.addObject(event);
                        });
                    }
                    _this.set("cleanedEvents", events);
                    _this.set("show", true);
                    var path = model.meta.pathSegment.replace(/\//g, "");
                    _this.get("api").getSettings("setting." + path + "." + ds.name).then(function (settings) {
                        var setting = settings[0];
                        Ember.set(setting, "setting", JSON.parse(setting.setting));
                        var link = { name: ds.name, link: setting.setting.link };
                        links.addObject(link);
                    });
                }
            });
            this.set("links", links);
        },

        actions: {
            eventClick: function eventClick(data) {
                var appCont = this.get("ea").getController("application");
                var links = this.get("links");
                var link = links.findBy("name", data.layerName);
                link = link.link;

                link = link + "?id=" + data.id;
                console.log(link + "?id=" + data.id);

                appCont.transitionToRoute(link);
            }
        }
    });
});