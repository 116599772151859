define('extraapps/services/docs', ['exports', 'extraapps/config/environment', 'extraapps/util/promiseObject'], function (exports, _environment, _promiseObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        ajax: Ember.inject.service(),
        ea: Ember.inject.service(),
        session: Ember.inject.service(),
        insertDocLink: function insertDocLink(doc, dataset, record) {
            if (doc === null || dataset === null || record === null) {
                return null;
            }
            var ea = this.get("ea");
            var that = this;
            var URL = _environment.default.backend.baseURL + '/doc/link/' + doc + '/' + dataset + '/' + record;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {

                that.get('ajax').put(URL, { headers: ea.getDefaultsHeaders() }).then(function (data) {
                    resolve(data);
                }).catch(function (error) {

                    var reason = {
                        service: 'docs',
                        method: 'insertDOcLink',
                        args: {
                            doc_id: doc,
                            dataset_id: dataset,
                            record_id: record
                        },
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'docs.insertDocLink(' + doc + ',' + dataset + ',' + record + ')');
        },

        deleteDocLink: function deleteDocLink(doc, dataset, record) {
            if (doc === null || dataset === null || record === null) {
                return null;
            }
            var ea = this.get("ea");
            var that = this;
            var URL = _environment.default.backend.baseURL + '/doc/link/' + doc + '/' + dataset + '/' + record;

            var promise = new Ember.RSVP.Promise(function (resolve, reject) {

                that.get('ajax').delete(URL, { headers: ea.getDefaultsHeaders() }).then(function (data) {
                    resolve(data);
                }).catch(function (error) {

                    var reason = {
                        service: 'docs',
                        method: 'deleteDOcLink',
                        args: {
                            doc_id: doc,
                            dataset_id: dataset,
                            record_id: record
                        },
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'docs.deleteDocLink(' + doc + ',' + dataset + ',' + record + ')');
        },

        getDocById: function getDocById(id) {
            var ea = this.get("ea");
            var that = this;

            var URL = _environment.default.backend.baseURL + '/doc/id/' + id;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", URL, true);
                xhr.responseType = "blob";
                xhr.setRequestHeader("cid", "1");
                xhr.onreadystatechange = function () {
                    if (this.readyState == this.DONE) {
                        if (this.status == 200 && this.response != null) {
                            // success!
                            resolve(this.response);
                            return;
                        }
                        // something went wrong
                        reject();
                    }
                };
                xhr.send();
            });
            return (0, _promiseObject.promiseObject)(promise, 'docs.getDocById(' + id + ')');
        },

        getDocByName: function getDocByName(name) {
            var ea = this.get("ea");
            var that = this;

            var URL = _environment.default.backend.baseURL + '/doc/name/' + name;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", URL, true);
                xhr.responseType = "blob";
                xhr.setRequestHeader("cid", "1");
                xhr.onreadystatechange = function () {
                    if (this.readyState == this.DONE) {
                        if (this.status == 200 && this.response != null) {
                            // success!
                            resolve(this.response);
                            return;
                        }
                        // something went wrong
                        reject();
                    }
                };
                xhr.send();
            });
            return (0, _promiseObject.promiseObject)(promise, 'docs.getDocById(' + name + ')');
        },

        deleteDocByName: function deleteDocByName(name) {
            var ea = this.get("ea");
            var that = this;

            var URL = _environment.default.backend.baseURL + '/doc/name/' + name;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, { headers: { cid: 1 } }).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        service: 'docs',
                        method: 'getDoc',
                        args: {
                            json: name
                        },
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'docs.deleteDocByName(' + name + ')');
        },

        deleteDocById: function deleteDocById(id) {
            var ea = this.get("ea");
            var that = this;

            var URL = _environment.default.backend.baseURL + '/doc/id/' + id;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').delete(URL, { headers: { cid: 1 } }).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        service: 'docs',
                        method: 'getDoc',
                        args: {
                            json: name
                        },
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'docs.deleteDocById(' + id + ')');
        },

        getDoc: function getDoc(name) {
            return this.getDocXhr(name);
            // let ea = this.get("ea");
            // let that = this;
            //
            // let URL = `${ENV.backend.baseURL}/doc/${name}`;
            // let promise = new Ember.RSVP.Promise(
            //     function (resolve, reject) {
            //         that.get('ajax').request(URL, {headers: {cid: 1, 'Content-Type': 'image/jpeg'}, responseType: 'blob'}).then(data => {
            //             resolve(data);
            //         }).catch(error => {
            //             let reason = {
            //                 service: 'docs',
            //                 method: 'getDoc',
            //                 args: {
            //                    json: name
            //                 },
            //                 error: error
            //             };
            //             reject(reason);
            //         });
            //     }
            // );
            // return promiseObject(promise, `docs.getDoc(${name})`);
        },

        getDocXhr: function getDocXhr(name) {
            var ea = this.get("ea");
            var that = this;

            var URL = _environment.default.backend.baseURL + '/doc/' + name;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", URL, true);
                xhr.responseType = "blob";
                xhr.setRequestHeader("cid", "1");
                xhr.onreadystatechange = function () {
                    if (this.readyState == this.DONE) {
                        if (this.status == 200 && this.response != null) {
                            // success!
                            resolve(this.response);
                            return;
                        }
                        // something went wrong
                        reject();
                    }
                };
                xhr.send();
            });
            return (0, _promiseObject.promiseObject)(promise, 'docs.getDoc(' + name + ')');
        },

        getDocsForRecord: function getDocsForRecord(dataset, record) {
            var ea = this.get("ea");
            if (dataset === null || record === 0) {
                return null;
            }
            var that = this;

            var URL = _environment.default.backend.baseURL + '/doc/link/' + dataset + '/' + record;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, { headers: ea.getDefaultsHeaders() }).then(function (data) {

                    resolve(data.resource);
                }).catch(function (error) {
                    var reason = {
                        service: 'api',
                        method: 'storeSettings',
                        args: {
                            container: json
                        },
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, 'docs.getDocs()');
        },
        getDocsMeta: function getDocsMeta() {
            var ea = this.get("ea");

            var that = this;

            var URL = _environment.default.backend.baseURL + '/doc/meta';
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, { headers: ea.getDefaultsHeaders() }).then(function (data) {
                    var storedSettings = data.resource;
                    resolve(storedSettings);
                }).catch(function (error) {
                    var reason = {
                        service: 'api',
                        method: 'storeSettings',
                        args: {},
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'docs.getMetaForDataset()');
        },
        getDocMeta: function getDocMeta(id) {
            var ea = this.get("ea");

            var that = this;

            var URL = _environment.default.backend.baseURL + '/doc/meta/' + id;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, { headers: ea.getDefaultsHeaders() }).then(function (data) {
                    resolve(data);
                }).catch(function (error) {
                    var reason = {
                        service: 'api',
                        method: 'storeSettings',
                        args: {},
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'docs.getDocMeta(' + id + ')');
        },

        getMetaForDataset: function getMetaForDataset(dataset) {

            var ea = this.get("ea");

            var that = this;

            var URL = _environment.default.backend.baseURL + '/doc/links?filter=dataset_id=' + dataset;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, { headers: ea.getDefaultsHeaders() }).then(function (data) {
                    var storedSettings = data.resource;
                    resolve(storedSettings);
                }).catch(function (error) {
                    var reason = {
                        service: 'api',
                        method: 'storeSettings',
                        args: {},
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'docs.getMetaForDataset()');
        },

        updateDocMeta: function updateDocMeta(docMeta) {

            var ea = this.get("ea");
            var session = this.get("session");
            var that = this;

            var data = { resource: [docMeta] };
            data = JSON.stringify(data);
            var json = { headers: this.get("ea").getDefaultsHeaders(), data: data };

            var URL = _environment.default.backend.baseURL + '/doc/meta';
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').put(URL, json).then(function (data) {
                    resolve(data.resource);
                }).catch(function (error) {
                    var reason = {
                        service: 'docs',
                        method: 'updateDocMeta',
                        args: {},
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseArray)(promise, 'docs.updateDocMeta()');
        },

        getLinksForDoc: function getLinksForDoc(id) {

            var ea = this.get("ea");

            var that = this;

            var URL = _environment.default.backend.baseURL + '/doc/links?filter=document_id=' + id;
            var promise = new Ember.RSVP.Promise(function (resolve, reject) {
                that.get('ajax').request(URL, { headers: ea.getDefaultsHeaders() }).then(function (data) {
                    var storedSettings = data.resource[0];
                    resolve(storedSettings);
                }).catch(function (error) {
                    var reason = {
                        service: 'api',
                        method: 'storeSettings',
                        args: {},
                        error: error
                    };
                    reject(reason);
                });
            });
            return (0, _promiseObject.promiseObject)(promise, 'docs.getMetaForDoc()');
        }
    });
});