define("extraapps/components/staticdata-field/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      update: function update() {
        var string = this.get("string");
        var i = this.get("index");
        var d = this.get("d");
        var v = this.get("v");
        this.sendAction("update", d, i, v, string);
      }
    }
  });
});