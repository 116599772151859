define("extraapps/components/date-format/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            this._super();
            var format = this.get("format");
            var date = this.get("date");
            var formatedDate = moment(date).format(format);
            this.set("formatedDate", formatedDate);
        }
    });
});