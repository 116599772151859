define("extraapps/components/ds-viewer-map-search-city/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        gc: Ember.inject.service(),
        search: function search(name) {
            var _this = this;

            return this.get("gc").getByName(name).then(function (data) {
                //Imports Geolocations from osm
                var objects = [];
                for (var x = 0; x < data.length; x++) {
                    var json = data[x];
                    if (json.class === "boundary") {
                        objects.addObject(json);
                    }
                }
                _this.set("places", objects);
                _this.set("showPlace", false);
                _this.set("showPlaces", true);
            }).catch(function (error) {
                console.log(error);
            });
        },
        clear: function clear() {
            this.set("cityName", null);
            this.set("places", []);
            this.set("showPlaces", false);
            this.set("show", false);
            this.sendAction("selectPlace", null);
            this.sendAction("close", "showSearchCity");
        },

        actions: {
            open: function open() {
                this.sendAction("open", "showSearchCity");
            },

            importSelectedGeoData: function importSelectedGeoData() {
                this.sendAction("import");
                this.clear();
            },
            clear: function clear() {
                this.clear();
            },
            search: function search(event, name) {
                this.search(name);
            },
            enter: function enter(name, event) {
                if (event.key === "Enter") {
                    this.search(name);
                }
            },
            showPlace: function showPlace(place) {

                this.set("showPlace", true);
                this.set("selectedPlace", place);
                this.sendAction("selectPlace", place);
            }
        }
    });
});