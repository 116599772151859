define("extraapps/admin/upload/controller", ["exports", "extraapps/config/environment"], function (exports, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        ea: Ember.inject.service(),

        baseUrl: _environment.default.backend.baseURL + "/doc/",
        options: {
            uploadMultiple: true
        },

        init: function init() {
            this._super();
            var header = this.get("ea").getDefaultHeaderFiles();
            this.set("headers", header);
        },


        actions: {
            saveFiles: function saveFiles() {
                var dropzone = this.get("dropzone");
                dropzone.processQueue();
            },
            addedFile: function addedFile() {
                this._super.apply(this, arguments);
                this.set('dropzone', Dropzone.forElement('#assetUpload'));
            },
            successmultiple: function successmultiple(response) {
                console.log(response);
                var type = this.get("type");
                var cid = this.get("session").get("client_id");
                var aid = this.get("session").get("app_id");
                var docs = this.get("model").images;
                var icons = this.get("model").icons;
                response = JSON.parse(response.xhr.response);
                response = response.resource[0];
                var that = this;
                var meta = {};
                var record = this.get("record");
                if (record.title === undefined || record.title === null) {
                    record.title = "";
                }
                meta.title = record.title.replace(/\s/g, "_");
                meta.id = response.id;
                meta.cid = cid;
                meta.aid = aid;
                meta.description = record.description;
                if (type === "icons") {
                    meta.fileName = cid + "_" + aid + "_mapIcon_" + meta.title;
                }

                return this.get("docs").updateDocMeta(meta).then(function (newMeta) {
                    that.dropzone.removeAllFiles();
                    that.set("record", {});
                    that.set("new", false);
                    that.get('notifications').success("Datei gespeichert", {
                        autoClear: true
                    });
                    that.set("new", null);
                    if (type === "icons") {
                        icons.addObject(newMeta[0]);
                    } else {
                        docs.addObject(newMeta[0]);
                    }
                });
            }
        }
    });
});