define('extraapps/components/ea-datamap/component', ['exports', 'extraapps/util/util', 'extraapps/config/environment'], function (exports, _util, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        ea: Ember.inject.service(),
        api: Ember.inject.service(),
        docs: Ember.inject.service(),
        notifications: Ember.inject.service('notification-messages'),
        rerender: false,
        background: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
        bgs: "default, esri",
        hover: false,

        init: function init() {
            this._super();
            var that = this;
            var isNew = this.get("new");
            var click = this.get("click");

            if (click === undefined) {
                this.set("click", "noEffect");
            }
            var data = this.get("model.data"); //TODO: SORT META & DATA BY DATASETNAME
            if (data !== undefined) {
                data = data.slice();
            }
            var meta = this.get("model.meta");
            var geodatapaths = [];
            if (meta === undefined) {
                return;
            }
            for (var a = 0; a < meta.databases.length; a++) {
                var db = meta.databases[a];
                var datasets = db.datasets;
                for (var b = 0; b < datasets.length; b++) {
                    var ds = datasets[b];
                    var geodatafield = ds.fields.findBy("logical_type", "GEOMETRY");
                    if (geodatafield !== undefined) {
                        var geodatapath = { id: b, text: geodatafield.path };
                        geodatapaths.addObject(geodatapath);
                    }
                }
            }
            var styles = this.get("model.styles");console.log(styles);
            var style = this.get("style");

            if (styles !== undefined) {
                //since admine/dataset loads only a single style
                style = styles.findBy("name", 'map.layout.' + data[0].dataset);
                this.set("style", style);
            }
            if (style !== null && style !== undefined) {
                //style.popupAnchor[0] = parseInt(style.popupAnchor[0]);
                //style.popupAnchor[1] = parseInt(style.popupAnchor[1]);
                if (style.iconId !== undefined && style.iconId !== null && style.iconId !== "") {

                    var baseURL = _environment.default.backend.assetIdBaseURL;
                    var url = frontEnd + '/cassets/' + style.iconId;

                    Ember.set(style, "url", url);
                } else {

                    Ember.set(style, "defaultIcon", true);
                }
                var popup = styles.findBy("name", 'map.popup.' + name);
                if (popup !== undefined) {
                    Ember.set(dataset, "popUp", popup);
                    Ember.set(dataset, "hasPopUp", true);
                }
            }

            if (Array.isArray(data)) {

                for (var _a = 0; _a < data.length; _a++) {

                    var _dataset = data[_a];

                    Ember.set(_dataset, "isHidden", false);
                    var _name = _dataset.dataset;
                    var _meta = this.get("model").meta.databases[0].datasets.findBy("name", _name);
                    Ember.set(_dataset, "title", _meta.title);

                    var popUpSetting = null;
                    if (styles !== undefined) {
                        //since admine/dataset loads only a single style
                        popUpSetting = styles.findBy("name", 'map.popup.' + _name);

                        if (popUpSetting !== undefined) {
                            var cleanName = _name.replace(/_/g, "");
                            Ember.set(_dataset, "partial", cleanName);
                            Ember.set(_dataset, "hasPopUp", true);
                            var templateRaw = popUpSetting.setting;
                            if (templateRaw === undefined) {
                                templateRaw = popUpSetting.lob;
                            }
                            try {
                                var templateCompiled = Ember.HTMLBars.compile(templateRaw);
                                Ember.TEMPLATES[cleanName] = templateCompiled;
                            } catch (e) {
                                console.log('Could not compile partial template ' + _name + ':');
                                console.log(e.message);
                            }
                        }
                    }

                    if (styles !== undefined) {

                        var _style = styles.findBy("name", 'map.layout.' + _name); // TODO: IF UNDEFINED FALL BACK TO DEFAULT STYLE
                        if (_style === undefined) {
                            Ember.set(_dataset, "defaultIcon", true);
                        } else if (_style !== null) {
                            _style = JSON.parse(_style.setting);
                            _style.popupAnchor[0] = parseInt(_style.popupAnchor[0]);
                            _style.popupAnchor[1] = parseInt(_style.popupAnchor[1]);
                            if (_style.iconId !== undefined && _style.iconId !== null && _style.iconId !== "") {

                                var _baseURL = _environment.default.backend.assetIdBaseURL;
                                var _url = _baseURL + '/' + _style.iconId;

                                Ember.set(_style, "url", _url);
                            } else {
                                Ember.set(_style, "defaultIcon", true);
                            }

                            Ember.set(_dataset, "style", _style);
                        }
                    }

                    var maprecords = [];
                    for (var _b = 0; _b < _dataset.records.length; _b++) {

                        var record = _dataset.records[_b];
                        var geopath = geodatapaths[_a].text;
                        if (record['' + geopath] === undefined || record[geopath] === null) {} else {
                            Ember.set(record, "geodata", (0, _util.updateGeometry)(record, '' + geodatapaths[_a].text));
                            maprecords.addObject(record);
                        }
                        if (record.geodata !== null && record.geodata !== undefined) {
                            var type = record.geodata.type;
                            switch (type) {
                                case "Point":
                                    var g = record.geodata;
                                    Ember.set(g, "coordinates", [g.coordinates[1], g.coordinates[0]]);
                                    Ember.set(record, "point", true);
                                    break;
                                case "GeometryCollection":
                                    Ember.set(record, "collection", true);
                                    break;
                            }
                        }
                    }
                    Ember.set(_dataset, "maprecords", maprecords);
                }
            }

            if (isNew) {
                this.set("center", [50, 9]);
                this.set("zoom", 4);
            } else {
                if (Array.isArray(data) && data.length === 1) {
                    this.set("toggleLayers", false);

                    var _name2 = data[0].dataset;

                    var _popUpSetting = null;
                    if (styles !== undefined) {
                        //since admine/dataset loads only a single style
                        _popUpSetting = styles.findBy("name", 'map.popup.' + _name2);

                        if (_popUpSetting === undefined) {
                            _popUpSetting = styles.findBy("name", 'map.popup.' + _name2);
                            if (_popUpSetting === undefined) {
                                _popUpSetting = styles.findBy("name", 'map.popup.default');
                            }
                        }
                        var _templateRaw = _popUpSetting.setting;
                        try {
                            var _templateCompiled = Ember.HTMLBars.compile(_templateRaw);
                            //name = name.replace(/_/g, "");
                            Ember.set(data[0], "partial", _name2);
                            Ember.TEMPLATES[_name2] = _templateCompiled;
                        } catch (e) {
                            console.log('Could not compile partial template ' + _name2 + ':');
                            console.log(e.message);
                        }
                    }
                }
                if (Array.isArray(data) && data.length === 1 && data[0].records.length === 0) {
                    this.set("center", [50, 9]);
                    this.set("zoom", 4);
                } else if (Array.isArray(data) && data.length === 1 && data[0].records.length === 1) {

                    var first = data[0].records[0];
                    var key = null;
                    if ("geodata" in first) {
                        key = "geodata";
                    } else if ("geodaten" in first) {
                        key = "geodaten";
                    }
                    if (key !== null) {
                        var geodata = first[key];
                        var coordinates = geodata.coordinates;
                        var _type = geodata.type;
                        if (_type === "Point") {
                            that.set("center", coordinates);
                            that.set("zoom", 18);
                        } else {
                            var geojson = first.geodata;
                            var bbox = L.geoJson(geojson).getBounds();
                            that.set("bbox", bbox);
                        }
                    } else {
                        that.set("center", [50, 9]);
                        that.set("zoom", 4);
                    }
                } else {
                    var box = [];
                    if (Array.isArray(data)) {
                        for (var x = 0; x < data.length; x++) {
                            var _dataset2 = data[x];
                            for (var y = 0; y < _dataset2.records.length; y++) {
                                var _record = _dataset2.records[y];
                                var _geodata = _record.geodata;
                                if (_geodata !== null && _geodata !== undefined) {
                                    var _type2 = _geodata.type;
                                    if (_type2 === "Point") {
                                        var location = _geodata.coordinates;
                                        var correctLocation = [location[1], location[0]];
                                        _geodata = { "coordinates": correctLocation, "type": "Point" };
                                    }
                                    box.push(_geodata);
                                }
                            }
                        }
                    }
                    var geometries = { "geometries": box, "type": "GeometryCollection" };

                    var _bbox = L.geoJson(geometries).getBounds();
                    this.set("bbox", _bbox);
                }
            }
            this.set("rerender", true);
            Ember.run.later(function () {
                that.set("rerender", false);
            }, 0);
        },


        recalcChanged: function () {
            var model = this.get("model");
            if (model === undefined) {
                this.set("center", [50, 9]);
                this.set("zoom", 5);
            } else {
                var that = this;
                var originalData = this.get("model.data");
                var data = null;
                if (originalData !== undefined) {
                    data = originalData.slice();
                }
                if (Array.isArray(data) && data.length === 1) {
                    this.set("toggleLayers", false);
                }
                if (Array.isArray(data) && data.length === 1 && data[0].records.length === 0) {
                    this.set("center", [50, 9]);
                    this.set("zoom", 4);
                } else if (Array.isArray(data) && data.length === 1 && data[0].records.length === 1) {
                    var first = data[0].records[0];

                    var key = null;
                    if ("geodata" in first) {
                        key = "geodata";
                    } else if ("geodaten" in first) {
                        key = "geodaten";
                    }
                    if (key !== null) {
                        var geodata = first[key];
                        var coordinates = geodata.coordinates;
                        var type = geodata.type;
                        if (type === "Point") {
                            that.set("center", coordinates);
                            that.set("zoom", 18);
                        } else {
                            var bbox = L.geoJson(geodata).getBounds();
                            that.set("bbox", bbox);
                        }
                    } else {
                        console.warn("first does not have geodata");
                    }
                } else {
                    var box = [];
                    if (Array.isArray(data)) {
                        for (var a = 0; a < data.length; a++) {
                            var _dataset3 = data[a];
                            for (var b = 0; b < _dataset3.records.length; b++) {
                                var geometry = _dataset3.records[b].geodata;
                                if (geometry !== null && geometry !== undefined) {
                                    var _type3 = geometry.type;
                                    if (_type3 === "Point") {
                                        var location = geometry.coordinates;
                                        var correctLocation = [location[1], location[0]];
                                        geometry = { "coordinates": correctLocation, "type": "Point" };
                                    }
                                    box.push(geometry);
                                }
                            }
                        }
                    }
                    var geometries = { "geometries": box, "type": "GeometryCollection" };

                    var _bbox2 = L.geoJson(geometries).getBounds();
                    that.set("bbox", _bbox2);
                }
            }
        }.observes('recalc').on('init'),
        opacityChanged: function () {
            var that = this;
            this.set("rerender", true);
            Ember.run.later(function () {
                that.set("rerender", false);
            }, 0);
        }.observes('style.opacity').on('init'),

        weightChanged: function () {
            var that = this;
            this.set("rerender", true);

            Ember.run.later(function () {
                that.set("rerender", false);
            }, 0);
        }.observes('style.weight').on('init'),

        colorCompleteChanged: function () {
            var that = this;
            this.set("rerender", true);
            Ember.run.later(function () {
                that.set("rerender", false);
            }, 0);
        }.observes('style.colorComplete').on('init'),

        fillColorChanged: function () {
            var that = this;
            var style = this.get("style");
            if (style !== undefined && style !== null) {
                var fillColor = style.fillColor;
                Ember.set(style, "fillColorComplete", '#' + fillColor);
            }
            this.set("rerender", true);
            Ember.run.later(function () {
                that.set("rerender", false);
            }, 0);
        }.observes('style.fillColor').on('init'),

        fillOpacityChanged: function () {
            var that = this;
            this.set("rerender", true);
            Ember.run.later(function () {
                that.set("rerender", false);
            }, 0);
        }.observes('style.fillOpacity').on('init'),

        redrawChanged: function functionName() {
            var redraw = this.get("redraw");
            var that = this;
            if (redraw !== null) {
                this.set("rerender", true);
                if (this.get("admin")) {
                    var style = this.get("model").style;
                    if (style !== null && style !== undefined) {
                        //style.popupAnchor[0] = parseInt(style.popupAnchor[0]);
                        //style.popupAnchor[1] = parseInt(style.popupAnchor[1]);
                        if (style.iconId !== undefined && style.iconId !== null && style.iconId !== "") {
                            var baseURL = _environment.default.backend.assetIdBaseURL;
                            var url = baseURL + '/' + style.iconId;

                            Ember.set(style, "url", url);
                        }
                    }
                    Ember.run.later(function () {
                        that.set("rerender", false);
                    });
                }
            }
        }.observes("redraw").on("init"),

        actions: {
            setBg: function setBg(bg) {
                this.set("background", bg);
            },

            mouseEnter: function mouseEnter() {
                alert("eneter");
            },
            goTo: function goTo(dataset, id) {
                var path = this.get("ea").getController("r1").path;
                path = path.join("");
                var appCont = this.get("ea").getController("application");
                var settings = this.get("model").styles;
                var name = 'setting.' + path + '.' + dataset;
                this.get("api").getSettings(name).then(function (settings) {

                    var setting = settings[0];

                    var link = JSON.parse(setting.setting).link;
                    link = link + '?id=' + id;
                    appCont.transitionToRoute(link);
                });
            },
            noEffect: function noEffect() {},
            addMarker: function addMarker(e) {
                var location = [e.latlng.lat, e.latlng.lng];
                this.sendAction("reverseSearch", location);
            },
            toggleLayer: function toggleLayer(dataset) {
                if (dataset.isHidden === false) {
                    Ember.set(dataset, "isHidden", true);
                } else {
                    Ember.set(dataset, "isHidden", false);
                }
                this.toggleProperty("recalc");
            },
            reverseSearch: function reverseSearch(e) {
                var data = this.get("model.data");
                var geodata = data[0].records[0].geodata;
                Ember.set(geodata, "coordinates", e.latlng);
            }
        }
    });
});