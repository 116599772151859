define("extraapps/components/related-image/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            remove: function remove(doc) {
                this.sendAction("remove", doc);
            },
            setPrev: function setPrev(id) {
                this.sendAction("updatePrev", id);
            }
        }
    });
});