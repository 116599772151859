define("extraapps/components/record-map/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super();
      var record = this.get("record");
      var geodaten = JSON.parse(record.geodaten);
      var geodata = JSON.parse(record.geodaten);

      if (geodata.type === "Point") {
        this.set("pointData", true);
        geodata.coordinates = [geodata.coordinates[1], geodata.coordinates[0]];
      } else {
        this.set("pointData", false);
      }

      this.set("geodata", geodata);
      this.calcBounds(geodaten);
    },

    calcBounds: function calcBounds(obj) {

      var bbox = L.geoJson(obj).getBounds();
      this.set("bounds", bbox);
      this.set("showMap", true);
    }
  });
});