define("extraapps/components/record-docs/component", ["exports", "extraapps/util/util"], function (exports, _util) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        docs: Ember.inject.service(),
        addFiles: [],
        related: [],
        init: function init() {
            this._super();
            var model = this.get("model");
            var fields = model.meta.databases[0].datasets[0].fields;
            var imageField = fields.findBy("logical_type", "IMAGE");
            var record = model.data[0].records[0];
            var related = record.documentLinks;
            if (related === undefined) {
                related = [];
            }
            this.set("related", related);
            if (imageField !== undefined) {
                var path = imageField.path;
                var imgId = record[path];
                this.set("path", path);
                imgId = parseInt(imgId);
                this.set("imgId", imgId);
                this.set("view", "prev");
            } else {
                this.set("hidePrev", true);
                this.set("view", "rel");
            }
        },

        docsChanged: function () {
            var view = this.get("view");
            switch (view) {
                case "prev":
                    this.set("prev", true);this.set("rel", false);break;
                case "rel":
                    this.set("prev", false);this.set("rel", true);break;
            }
        }.observes("view").on("init"),
        archiveChanged: function () {
            var _this = this;

            var show = this.get("showArchive");
            if (show) {
                return this.get("docs").getDocsMeta().then(function (assets) {
                    var images = assets.filter(_util.filterAssets);
                    _this.set("archive", images);
                }).catch(function (error) {});
            }
        }.observes("showArchive").on("init"),
        actions: {
            toggleSave: function toggleSave() {
                this.toggleProperty("save");
            },
            addImage: function addImage(id) {
                var addFiles = this.get("addFiles");
                if (addFiles.indexOf(id) !== -1) {
                    addFiles.removeObject(id);
                } else {
                    addFiles.addObject(id);
                }
            },
            updatePrev: function updatePrev(id) {
                var path = this.get("path");
                this.set("imgId", id);
                this.sendAction("updatePrev", path, id);
            },
            removeRelated: function removeRelated(doc) {
                var related = this.get("related");
                var model = this.get("model");
                var record = model.data[0].records[0].id;
                var ds = model.meta.databases[0].datasets[0].id;
                return this.get("docs").deleteDocLink(doc.documentId, ds, record).then(function (removed) {
                    related.removeObject(doc);
                });
            },
            addDocuments: function addDocuments() {
                var _this2 = this;

                var addFiles = this.get("addFiles");
                var model = this.get("model");
                var related = this.get("related");
                if (related === undefined) {
                    related = [];
                }
                var record = model.data[0].records[0];

                addFiles.forEach(function (id) {
                    console.log(id);
                    return _this2.get("docs").insertDocLink(id, model.meta.databases[0].datasets[0].id, record.id).then(function (link) {
                        _this2.set("addFiles", []);
                        related.addObject({ "documentId": id });
                        _this2.set("showArchive", false);
                        _this2.set("upload", false);
                    });
                });
            }
        }
    });
});