define("extraapps/components/data-view/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        init: function init() {
            var _this = this;

            this._super();
            var defaultView = this.get("dView");
            var model = this.get("model");
            model.meta.databases[0].datasets.forEach(function (ds) {
                console.log(ds);
                if (ds.withGeoData === true && _this.get("hideMap") !== false) {
                    _this.set("withMap", true);
                }
                if (ds.withTemporalData === true && _this.get("hideCal") !== false) {
                    _this.set("withCal", true);
                }
            });
            switch (defaultView) {
                case "list":
                    this.set("list", true);break;
                case "cal":
                    this.set("cal", true);break;
                case "map":
                    this.set("map", true);break;
                case "cards":
                    this.set("cards", true);break;
            }
        },

        actions: {
            setView: function setView(view) {
                var that = this;

                switch (view) {
                    case "map":
                        this.set("cards", false);this.set("map", true);this.set("list", false);this.set("cal", false);break;
                    case "list":
                        this.set("cards", false);this.set("map", false);this.set("list", true);this.set("cal", false);break;
                    case "cal":
                        this.set("cards", false);this.set("map", false);this.set("list", false);this.set("cal", true);break;
                    case "cards":
                        this.set("cards", true);this.set("map", false);this.set("list", false);this.set("cal", false);break;
                }
            }
        }
    });
});