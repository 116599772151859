define("extraapps/components/list-view/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        dsChanged: function () {
            var dataset = this.get("dataset");
            if (dataset !== undefined) {
                var that = this;
                var fields = dataset.meta.fields;
                var columns = [{ key: "", header: "", width: "50px", component: "goto-button" }];

                for (var a = 0; a < fields.length; a++) {
                    var field = fields[a];
                    if (field.logical_type !== "IMAGE" && field.path !== "owner" && field.logical_type !== "GEOMETRY") {
                        var width = field.label.length * 20;
                        var filter = false;
                        if (field.logical_type === "TEXT") {
                            filter = {
                                type: 'search'
                            };
                        }
                        var column = { key: field.path, header: field.label, width: width, sortable: true };
                        columns.addObject(column);
                    }
                }
                this.set("columns", columns);
                this.set("records", dataset.records);
                this.set("show", false);
                Ember.run.later(function () {
                    that.set("show", true);
                }, 100);
            }
        }.observes("dataset").on("init"),
        actions: {
            goTo: function goTo(id) {
                var ds = this.get("dataset").meta.name;
                var url = window.location.href;
                url = url.split("/view");
                window.open(url[0] + "/record/" + ds + "/" + id, '_blank');
            }
        }

    });
});