define("extraapps/components/aktuelles-list/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        ps: Ember.inject.service(),
        offset: 0,
        init: function init() {
            var _this = this;

            this._super();
            var filter = { "limit": 4, "offset": 0, order: "datum DESC" };
            return this.get("ps").selectRecords("_external", "aktuelles", filter).then(function (data) {
                _this.set("items", data);
            });
        },

        actions: {
            load: function load() {
                var _this2 = this;

                var newItems = this.get("items");
                var offset = this.get("offset") + 4;
                var filter = { "limit": 4, "offset": offset, order: "datum DESC" };
                return this.get("ps").selectRecords("_external", "aktuelles", filter).then(function (data) {
                    if (data.length > 0) {
                        data.forEach(function (item) {
                            newItems.addObject(item);
                        });
                        _this2.set("offset", offset);
                    } else {
                        _this2.set("end", true);
                    }
                });
            }
        }
    });
});