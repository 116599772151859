define("extraapps/components/ea-form-map-draw/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            setShape: function setShape(shape) {
                this.sendAction("setShape", shape);
            },
            toggleDraw: function toggleDraw() {
                this.sendAction("toggleDraw");
            }
        }
    });
});