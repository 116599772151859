define('extraapps/admin/importer/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        notifications: Ember.inject.service('notification-messages'),
        ds: Ember.inject.service(),
        api: Ember.inject.service(),
        model: function model(transition) {
            var _this = this;

            var dataset = transition.ds;
            return this.get("ds").getDataset(dataset).then(function (data) {
                return _this.get("api").getSettings('ea.importFields.' + dataset).then(function (settings) {
                    data.fields.forEach(function (field) {
                        Ember.set(field, "text", field.label);
                        Ember.set(field, "id", field.path);
                    });

                    settings.forEach(function (setting) {
                        setting.setting = JSON.parse(setting.setting);
                        setting.text = setting.setting.name;
                    });

                    return { meta: data, settings: settings };
                }).catch(function (error) {
                    console.log(error);
                    _this.get('notifications').error('Fehler beim laden der Settings.', { // TODO: use i18n text...
                        autoClear: true
                    });
                });
            }).catch(function (error) {
                console.log(error);
                _this.get('notifications').error('Fehler beim laden der Metadaten.', { // TODO: use i18n text...
                    autoClear: true
                });
            });
        }
    });
});