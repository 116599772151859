define('extraapps/components/ea-form/component', ['exports', 'extraapps/util/util'], function (exports, _util) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notifications: Ember.inject.service('notification-messages'),
        ea: Ember.inject.service(),
        ps: Ember.inject.service(),
        cr: Ember.inject.service(),
        exvalidator: Ember.inject.service(),
        record: {},
        related: [],
        docs: [],
        geoCode: "address",
        docTab: false,
        recordPage: true,
        dates: {},

        init: function init() {
            this._super();
            var pages = 1;
            var dates = this.get("dates");
            this.set("currentPage", 1);
            var mapSettings = {};
            if (this.hideLine === true) {
                mapSettings.hideLine = true;
            }
            if (this.hidePoint === true) {
                mapSettings.hidePoint = true;
            }
            if (this.hidePolygon === true) {
                mapSettings.hidePolygone = true;
            }
            if (this.hideDraw === true) {
                mapSettings.hideDraw = true;
            }
            if (this.hideSearch === true) {
                mapSettings.hideSearch = true;
            }
            if (this.hideDocs === true) {
                mapSettings.hideDocs = true;
            }
            this.set("mapSettings", mapSettings);
            var relatedDatasets = this.get("model").meta.relatedDatasets;
            if (relatedDatasets !== undefined) {
                this.set("relatedData", true);
            }
            var fields = this.get("model").meta.databases[0].datasets[0].fields;
            fields = (0, _util.setFieldTypes)(fields);
            this.set("fields", fields);
            for (var a = 0; a < fields.length; a++) {
                var field = fields[a];
                if (field.confidential && field.label !== "owner" && field.logical_type !== "TIMESTAMP") {
                    this.set("withPrivateFields", true);
                    return;
                }
            }
            var dateField = fields.findBy("logical_type", "DATE");
            var startField = fields.findBy("logical_type", "BEGIN");
            var endField = fields.findBy("logical_type", "END");

            fields.removeObject(startField);
            fields.removeObject(endField);
            fields.removeObject(dateField);

            if (dateField !== undefined) {
                Ember.set(dates, "hasDate", true);
                Ember.set(dates, "dateField", dateField);
            }

            if (startField !== undefined) {
                Ember.set(dates, "start", startField);
                Ember.set(dates, "hasDate", true);
            }

            if (endField !== undefined) {
                Ember.set(dates, "end", endField);
            }

            var imageField = fields.findBy("logical_type", "IMAGE");
            if (this.get("model").meta.withGeoData === true) {
                pages = pages + 1;
                this.set("withGeoData", true);
            }
            if (imageField !== undefined) {
                imageField.isImage = true;
                this.set("withImage", true);
            }
            if (this.get("hideDocs") !== true) {
                pages = pages + 1;
                this.set("withDocs", true);
            }
            this.set("pages", pages);
            this.firstPage();
        },
        firstPage: function firstPage() {
            var ongPager = this.get("ongPager");
            if (ongPager === false) {
                this.set("currentPage", 1);
                this.set("geoPage", false);
                this.set("docPage", false);
                this.set("recordPage", true);
                this.set("last", false);
            } else {
                this.set("geoPage", true);
                this.set("docPage", true);
                this.set("recordPage", true);
            }
        },

        actions: {
            next: function next() {
                var pages = this.get("pages");
                var currentPage = this.get("currentPage");
                this.set("currentPage", currentPage + 1);
                if (pages === currentPage + 1) {
                    this.set("last", true);
                }
                var recordPage = this.get("recordPage");
                if (recordPage) {
                    this.set("recordPage", false);
                    if (this.get("withGeoData")) {
                        this.set("geoPage", true);
                    } else if (this.get("withImage")) {
                        this.set("docPage", true);
                    }
                } else if (this.geoPage) {
                    this.set("geoPage", false);
                    this.set("docPage", true);
                }
            },
            back: function back() {
                var pages = this.get("pages");
                var currentPage = this.get("currentPage");
                this.set("currentPage", currentPage - 1);
                if (this.get("geoPage")) {
                    this.set("geoPage", false);
                    this.set("recordPage", true);
                } else if (this.get("docPage")) {
                    if (this.get("withGeoData")) {
                        this.set("docPage", false);
                        this.set("geoPage", true);
                    } else {
                        this.set("docPage", false);
                        this.set("recordPage", true);
                    }
                }
                this.set("last", false);
            },


            setGeoData: function setGeoData(geodata) {
                var fields = this.get("model").meta.databases[0].datasets[0].fields;
                var geoField = fields.findBy("logical_type", "GEOMETRY");
                var record = this.get("record");
                Ember.set(record, geoField.path, geodata);
            },
            setPreview: function setPreview(path, id) {
                var record = this.get("record");
                Ember.set(record, path, id);
            },
            addRelation: function addRelation(relation) {
                var related = this.get("related");
                related.addObject(relation);
            },

            createRequest: function createRequest() {
                var _this = this;

                var meta = this.get("model").meta.databases[0].datasets[0];
                var datasetName = meta.name;
                var redirect = this.get("redirect");
                var docs = this.get("docs");
                var record = this.get("record");
                var changes = [];
                var related = this.get("related");
                var messages = this.get("exvalidator").validateRecord(record, meta.fields);
                if (messages.length === 0) {
                    var mainRequest = { "datasetName": datasetName, changeType: "INSERT", "changeData": JSON.stringify(record) };
                    changes.addObject(mainRequest);
                    for (var a = 0; a < docs.length; a++) {
                        var doc = docs[a];
                        var docLink = { "datasetName": "DocumentLink", "recordId": doc.id, changeType: "INSERT" };
                        changes.addObject(docLink);
                    }
                    changes = changes.concat(related);
                    this.get("cr").createChangeRequest(changes).then(function (data) {

                        _this.get('notifications').success('Datensatz gespeichert.', { // TODO: use i18n text...
                            autoClear: true
                        });
                        _this.set("record", {});
                        _this.set("docs", []);
                        if (redirect !== undefined) {
                            var appCont = _this.get("ea").getController("application");
                            appCont.transitionToRoute(redirect);
                        }
                        _this.firstPage();
                    }).catch(function (error) {
                        console.log(error);
                        _this.get('notifications').error('Beim speichern ist Fehler aufgetreten', { // TODO: use i18n text...
                            autoClear: true
                        });
                    });
                } else {
                    this.set("errors", messages);
                    this.set("showErrors", true);
                }
            },
            addDocument: function addDocument(image) {
                var docs = this.get("docs");
                console.log(image);
                if (docs.length === 0) {
                    var record = this.get("record");
                    var fields = this.get("model").meta.databases[0].datasets[0].fields;
                    var imgField = fields.findBy("logical_type", "IMAGE");
                    if (imgField !== undefined) {
                        Ember.set(record, imgField.path, image.id);
                        this.set("prevImage", image.id);
                    }
                }
                docs.addObject(image);
            },
            setPrev: function setPrev(id) {
                var record = this.get("record");
                var fields = this.get("model").meta.databases[0].datasets[0].fields;
                var imgField = fields.findBy("logical_type", "IMAGE");
                if (imgField !== undefined) {
                    Ember.set(record, imgField.path, image.id);
                    this.set("prevImage", image.id);
                }
            },

            save: function save() {
                var _this2 = this;

                var dataset = this.get("model").meta.databases[0].datasets[0];
                var fields = this.get("fields");
                var record = this.get("record");
                record.cid = dataset.cid;
                record.aid = dataset.aid;

                return this.get("ps").insertRecords(dataset.identifier, dataset.name, record).then(function (data) {
                    _this2.get('notifications').success('Datensatz gespeichert.', { // TODO: use i18n text...
                        autoClear: true
                    });
                    _this2.set("record", {});
                }).catch(function (error) {
                    _this2.get('notifications').error('Beim speichern ist Fehler aufgetreten', { // TODO: use i18n text...
                        autoClear: true
                    });
                });
            }
        }
    });
});